import React from 'react'
import PropTypes from 'prop-types'
import { Cell } from '../table/TableCellText.d' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'


const TableCellYesNo: React.FC<Cell> = (props) => {
    let value = props.value || []

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            {value === 'true' ? <FontAwesomeIcon className='text-success' icon={faCheck} /> : <FontAwesomeIcon className='text-danger' icon={faTimes} />}
        </>
    )
}

TableCellYesNo.propTypes = {
    value: PropTypes.any.isRequired
}

export default TableCellYesNo