import { observable } from 'mobx'
import { ConditionalOption } from './types.d'


class ConditionalOptionStore implements ConditionalOption {
    @observable public condition_id: string
    @observable public condition_name: string
    @observable public options_string: string

    constructor(conditionalOptions: ConditionalOption) {
        this.set(conditionalOptions)
    }

    set(conditionalOptions: ConditionalOption) {
        this.condition_id = conditionalOptions.condition_id
        this.condition_name = conditionalOptions.condition_name
        this.options_string = conditionalOptions.options_string
    }

    get(): ConditionalOption {
        return {
            condition_id: this.condition_id,
            condition_name: this.condition_name,
            options_string: this.options_string
        }
    }

}

export default ConditionalOptionStore