import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const addState = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/states/add`
    return axios.post(url, requestData)
}

export const getStates = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/states/`
    return axios.post(url, requestData)
}

export const getStateDetails = (stateId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/states/state/${stateId}`
    return axios.post(url)
}

export const updateOrDeleteState = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/states/update`
    return axios.post(url, requestData)
}

export const getAllStates = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/states/name/all/`
    return axios.post(url)
}