import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../../store/types.d'

export const addUpdateEstateProtectPlan = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/estate-protect-plans/add-update`
    return axios.post(url, requestData)
}

export const getEstateProtectPlans = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/estate-protect-plans/`
    return axios.post(url, requestData)
}

export const getSingleEstateProtectPlanDetails = (estateProtectPlanId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/estate-protect-plans/estate-protect-plan/${estateProtectPlanId}`
    return axios.post(url)
}
