import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const getAppUsers = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/app-users/`
    return axios.post(url, requestData)
}

export const addAppUser = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/app-users/add`
    return axios.post(url, requestData)
}

export const getAppUserDetails = (appUserId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/app-users/app-user/${appUserId}`
    return axios.post(url)
}

export const updateAppUser = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/app-users/update`
    return axios.post(url, requestData)
}

export const getAllAppUserForInternalUse = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/app-users/all`
    return axios.get(url)
}