import React, { useState } from 'react'
import { Cell } from '../table/TableCellText.d' 
import { Link } from 'react-router-dom'
import { isValueEquivalent } from '../commonComponent/commonFuction'
import Modal from '../commonComponent/Modal'
import uuid from 'react-uuid'
import ReactJson from 'react-json-view'


export const TableCellErrorDetails: React.FC<Cell> = (props) => {
    const value = props.value || undefined
    const data = props.data || {}
    const [showModal, setShow] = useState(false)
    const [selectedRow, setSelectedRow] = useState({ _id: '', action: '' })

    const openCloseModal = () => {
        setShow(!showModal)
    }

    const onTableAction = (action: string, value: any, data: any) => {
        setSelectedRow({ _id: data._id, action: action })
        openCloseModal()
    }

    return (
        <>
            {value !== undefined && <Link to='#' data-target='#myModal' className='mr-2' onClick={() => {
                if (onTableAction != null) {
                    onTableAction('viewEmailData', value, data)
                }
            }}>Error Data</Link>}

            {showModal && isValueEquivalent({ _id: data._id, action: 'viewEmailData' }, selectedRow) ? (
                <Modal show={showModal}
                    modalTitle={data.user_id[0].name}
                    openCloseModal={openCloseModal}
                    modalDialogClass='modal-lg'
                    cancelButtonName='Close'
                    isScrollable={true}
                    cancelButton={true}
                    isSaveButton={false}
                >
                    <>
                        {value !== undefined && <div key={uuid()}>
                            <ReactJson src={value} />
                        </div>}
                    </>
                </Modal>) : null
            }
        </>
    )
}