import React from 'react'
import RootStore from '../../store/Root'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { replaceSpaceWithHyphen } from '../commonComponent/commonFuction'
import { BulletList } from '../commonComponent/DotLoader'
import uuid from 'react-uuid'

interface Props {
    rootStore: RootStore
}

const UpdateTemplateSideBar: React.FC<Props> = ({ rootStore }) => {
    const { templateDetails, isLoading } = rootStore.templateStore
    return <>
        <nav className={classNames('col-md-3 col-lg-2 d-md-block bg-light update-template-sidebar collapse', { 'show': rootStore.navStore.collapse })}>
            <div className='sidebar-sticky'>
                {isLoading ? <BulletList /> : <ul className='nav flex-column'>
                    {templateDetails.content.map((item: any) => {
                        const bgColor = (item.edit_history !== undefined && item.edit_history.length > 0) ? 'lightyellow' : ''
                        return (<li key={uuid()} style={{backgroundColor: bgColor}} >
                            <a className="nav-link"  href={'#' + replaceSpaceWithHyphen(item.name)} >{item.name}</a>
                        </li>
                        )}
                    )}
                </ul>}
            </div>
        </nav>
    </>
}

export default observer(UpdateTemplateSideBar)
