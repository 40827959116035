import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const addField = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/fields/add`
    return axios.post(url, requestData)
}

export const getFields = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/fields/`
    return axios.post(url, requestData)
}

export const getFieldDetails = (fieldId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/fields/field/${fieldId}`
    return axios.post(url)
}

export const updateField = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/fields/update`
    return axios.post(url, requestData)
}

export const getAllFields = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/fields/name/all/`
    return axios.post(url)
}

export const getGroupFields = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/fields/name/group/`
    return axios.post(url, requestData)
}