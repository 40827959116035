import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const getEmailLogs = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/email-logs/`
    return axios.post(url, requestData)
}

export const getEmailLogsOfMatter = (matterId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/email-logs/email-log/${matterId}`
    return axios.get(url)
}