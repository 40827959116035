import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import InputField from '../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Props, Dictionary } from '../../store/types.d'

const AddUpdatePluralPage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors } = useForm()

    const { pluralStore, setTitle } = rootStore
    const { isApiError, isLoading, apiErrorMessage, pluralDetails, setPluralDetails, addUpdateSinglePlural, resetPluralDetails } = pluralStore

    const history = useHistory()
    const pageName = (history.location.pathname.includes('add')) ? 'Add Plural' : 'Update Plural'
    const pathArrayWithStateId = history.location.pathname.split('/')

    const pluralId = (pageName === 'Update Plural') ? pathArrayWithStateId[3] : ''

    useEffect(() => {
        if (pageName === 'Update Plural') {
            setPluralDetails(pluralId)
        } else if(pageName === 'Add Plural') {
            setTitle('Plural | Add')
            resetPluralDetails()
        }
    }, [setPluralDetails, pluralId, pageName, resetPluralDetails])

    const handleClick = () => {
        resetPluralDetails()
        history.push('/plurals/')
    }

    const onSubmit = async (data: Dictionary<string>) => {

        const requestData = {
            _id: pluralId,
            singular: data.singular,
            plural: data.plural
        }

        const response = await addUpdateSinglePlural(requestData)
        if (response === 'true') {
            history.push('/plurals/')
        }
    }

    useEffect(() => {
        if (pageName === 'Update Plural') {
            if(pluralId === pluralDetails._id){
                setTitle(`${pluralDetails.singular} | Manage Plural`)
            }
        }
    }, [pluralDetails])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Plurals', to: '/plurals/' },
            { name: (pageName === 'Update Plural') ? 'Update' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {pageName}
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        {(pluralId === pluralDetails._id)
                            ? <form onSubmit={handleSubmit(onSubmit)} >
                                <fieldset>

                                    <InputField
                                        label='Singular *'
                                        fieldName='singular'
                                        register={register}
                                        errors={errors.singular}
                                        placeholder='Enter Singular'
                                        defaultValue={pluralDetails.singular}
                                        required={true}
                                    />

                                    <InputField
                                        label='Plural *'
                                        fieldName='plural'
                                        register={register}
                                        errors={errors.plural}
                                        placeholder='Enter Plural'
                                        defaultValue={pluralDetails.plural}
                                        required={true}
                                    />

                                    <div className='form-group'>
                                        <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                            {isLoading ? 'Loading...' : 'Submit'}
                                        </button>&nbsp;&nbsp;
                                        <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                    </div>
                                </fieldset>
                            </form>
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddUpdatePluralPage)