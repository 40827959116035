import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const getDeedFiling = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deed-filing/`
    return axios.post(url, requestData)
}

export const editFilingCompleted = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deeds/deed/edit/is-filing-completed`
    return axios.post(url, requestData)
}

export const editFilingFlag = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deeds/deed/edit/filing`
    return axios.post(url, requestData)
}

export const editRejectedFlag = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deeds/deed/edit/is-rejected`
    return axios.post(url, requestData)
}

export const editFiledFlag = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deeds/deed/edit/filed`
    return axios.post(url, requestData)
}

export const editRefiledFlag = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deeds/deed/edit/is-refiled`
    return axios.post(url, requestData)
}

export const getUsersEmailForDeedFilling = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/app-users/email/deed-filling`
    return axios.get(url)
}