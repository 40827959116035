import React from 'react'
import { ValueLabel } from '../../store/types.d'
import FormModal from '../commonComponent/FormModal'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import classNames from 'classnames'
import SingleFieldStore from '../../store/SingleField'

interface Props {
	options: Array<ValueLabel>
	targetData?: any
	showForm: boolean
	modalTitle: string
	fieldStore?: SingleFieldStore
	openCloseForm?: () => void
}

const ConditionalOptionModel: React.FC<Props> = (props) => {

    const makeValueLabelList = (data: any, allOptions: Array<ValueLabel>) => {
        for (let j = 0; j < allOptions.length; j++) {
            if (data === allOptions[j].value) {
                return allOptions[j]
            }
        }
    }

    const selectedCondition = makeValueLabelList(props.targetData.condition_id, props.options)

    const conditionList = props.fieldStore.conditional_options
    let filteredConditionList = props.options
    if (props.fieldStore !== undefined) {
        filteredConditionList = props.options.filter((item) => !conditionList.filter((index) => {
            if (index.condition_id === item.value && props.targetData.condition_id !== item.value) {
                return true
            } else {
                return false
            }
        }).length)
    }

    const scrollableBodyStyle = 'modal-body resource'
    const { register, handleSubmit, errors, control } = useForm()

    const onSaveModal = (data: any) => {
        if (props.modalTitle.includes('Add')) {
            props.fieldStore.addNewConditionalOption(data.options_string, data.condition.value, data.condition.label)
        } else {
            props.targetData.condition_id = data.condition.value
            props.targetData.condition_name = data.condition.label
            props.targetData.options_string = data.options_string
        }
        props.openCloseForm()
    }

    return <>
        <FormModal show={props.showForm} modalTitle={props.modalTitle} openCloseModal={props.openCloseForm}>

            <form onSubmit={handleSubmit(onSaveModal)} >
                <fieldset>
                    <div className={scrollableBodyStyle} >
                        <div className='ml-2'>

                            <div className='form-group'>
                                <label>Select Condition *</label>
                                <Controller
                                    control={control}
                                    name="condition"
                                    defaultValue={selectedCondition}
                                    render={({ onChange, ref, value }) => (
                                        <Select
                                            inputRef={ref}
                                            value={value}
                                            options={filteredConditionList}
                                            className={(errors.condition) ? 'is-invalid' : ''}
                                            classNamePrefix="select"
                                            onChange={val => {
                                                onChange(val)
                                            }}
                                        />)}
                                    rules={{
                                        validate: (value) => (value === undefined) ? 'Please select condition' : true
                                    }}
                                />
                                {(errors.condition) && (<div className='invalid-feedback'>{errors.condition.message}</div>)}
                            </div>

                            <div className="form-group">
                                <label>Options *</label>
                                <textarea
                                    ref={register({
                                        required: 'This field is required',
                                        validate: (value: string) => (value.trim()) ? true : 'This field is required'
                                    })}
                                    name='options_string'
                                    defaultValue={props.targetData.options_string}
                                    className={classNames('form-control', { 'is-invalid': errors.options_string })}
                                    placeholder='Enter Options'
                                />
                                {errors.options_string && (<div className='invalid-feedback'>{errors.options_string.message}</div>)}
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={props.openCloseForm}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary">{props.modalTitle.includes('Add') ? 'Add' : 'Update'}</button>
                    </div>
                </fieldset>
            </form>
        </FormModal>
    </>
}

export default ConditionalOptionModel