import { observable } from 'mobx'
import { ExistPlan } from '../types.d'

class SinglePlanPricingTemplateStore implements ExistPlan {
    @observable public _id: string
    @observable public name: string
    @observable public display_order: number
    @observable public price_for_single: {
        price: string,
        level_1_price: string,
        level_2_price: string,
        level_3_price: string,
        restatement_price: string,
        restatement_level_1_price: string,
        restatement_level_2_price: string,
        restatement_level_3_price: string
    }
    @observable public price_for_married: {
        price: string,
        level_1_price: string,
        level_2_price: string,
        level_3_price: string,
        restatement_price: string,
        restatement_level_1_price: string,
        restatement_level_2_price: string,
        restatement_level_3_price: string,
    } 

    constructor(template: ExistPlan) {
        this.set(template)
    }

    set(template: ExistPlan) {
        this._id = template._id || ''
        this.display_order = template.display_order
        this.name = template.name
        this.price_for_single = template.price_for_single
        this.price_for_married = template.price_for_married
    }

    get(): ExistPlan {
        return {
            _id: this._id,
            name: this.name,
            display_order: this.display_order,
            price_for_married: this.price_for_married,
            price_for_single: this.price_for_single
        }
    }
}

export default SinglePlanPricingTemplateStore