import React from 'react'
import { Cell } from '../table/TableCellText.d' 
import _ from 'lodash'


const TableCellType: React.FC<Cell> = (props) => {
    let value = props.value || []

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
    // value.replace('_', ' ')
    return (
        <>
            {_.startCase(_.toLower(value))} {(props.data.is_automatic_email == true) ? '(Auto)' : ''}
        </>
    )
}


export default TableCellType