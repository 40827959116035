import React from 'react'
import { Cell } from '../../table/TableCellText.d'

export const TableCellAppAmount: React.FC<Cell> = (props) => {
    let amount = (props.value === '') ? '' : (props.value !== 'half_in_payment') ? props.data.agreement_amount.first : ''

    if(props.value === 'half_in_payment') {
        if(props.data.agreement_amount.first.includes('+')){
            const newFirst = parseInt(props.data.agreement_amount.first.replace('+', ''))
            const newSecond = parseInt(props.data.agreement_amount.second.replace('+', ''))
            amount = newFirst + newSecond
            amount = amount + '+'
        } else {
            amount = parseInt(props.data.agreement_amount.first) + parseInt(props.data.agreement_amount.second)
        }   
    }

    const price = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)

    return (
        <>
            {(amount && !isNaN(amount)) ? price.replace(/\.00$/, '') : (amount != '') ? '$' + amount : '' }
        </>
    )
}

export const TableCellYesNo: React.FC<Cell> = (props) => {

    return (
        <>
            {props.value ? 'Yes' : 'No' }
        </>
    )
}

export const NumberToPriceFormatter: React.FC<Cell> = (props) => {
    const price = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.value)

    return (
        <>
            {(props.value) ? price.replace(/\.00$/, '') : '' }
        </>
    )
}