import { observable } from 'mobx'
import RootStore from './Root'
import { NeedInfoProps } from './types.d'
import { getNeedInfo } from '../api/MatterAction'
import { initialTableDataWithPaginationDetails } from './InitialValues'

interface NeedInfoData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<NeedInfoProps>
}

class NeedInfoStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public needInfoData: NeedInfoData
    @observable public searchItem: string
    @observable public filterWithNote: boolean
    @observable public page: number
    @observable public tableRows: Array<NeedInfoProps>

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.searchItem = ''
        this.filterWithNote = true
        this.page = 1
        if (rootStore.authStore.isAdmin) {
            this.fetchNeedInfo(1, '', true)
        }
        this.needInfoData = initialTableDataWithPaginationDetails
        this.tableRows = []
    }

    fetchNeedInfo = (page: number, searchItem: string, filterWithNote: boolean): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: page, search_item: searchItem, filterWithNote }
        this.page = page
        this.searchItem = searchItem
        this.filterWithNote = filterWithNote

        getNeedInfo(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.needInfoData = response.data.data
            this.tableRows = response.data.data.page_items
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    
}

export default NeedInfoStore
