import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const updatePDFStyle = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/settings/update-pdf`
    return axios.post(url, requestData)
}

export const getSettings = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/settings/`
    return axios.get(url)
}

export const updateAppSetting = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/settings/update-app`
    return axios.post(url, requestData)
}