export const englishStrings = {
    day: 'a day',
    days: '%d days',
    hour: 'an hour',
    hours: '%d hours',
    minute: 'a minute',
    minutes: '%d minutes',
    month: 'a month',
    months: '%d months',
    prefixAgo: null,
    prefixFromNow: null,
    seconds: 'less than a minute',
    suffixAgo: 'ago',
    suffixFromNow: 'from now',
    wordSeparator: ' ',
    year: 'a year',
    years: '%d years'
} 