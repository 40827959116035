import React, { useEffect, useState } from 'react'
import { ValueLabel } from '../../store/types.d'
import FormModal from '../commonComponent/FormModal'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import CommonParagraphStore from '../../store/CommonParagraph'
import ReactEditor from '../template/ReactEditor'
import MonacoEditorStore from '../../store/MonacoEditorStore'

interface Props {
    options: Array<ValueLabel>
    targetData?: any
    showForm: boolean
    modalTitle: string
    monacoStore?:  MonacoEditorStore
    CommonParagraph: CommonParagraphStore
    openCloseForm: () => void
}

const StateSpecificValue: React.FC<Props> = (props) => {
    const { handleSubmit, errors, control } = useForm()
    const [stateContent, setStateContent] = useState('')
    // const [editorState, setEditorState] = useState<EditorState>()

    const selectedState = props.options.find((item: ValueLabel) => props.targetData.state === item.value)

    const stateList: any = props.CommonParagraph.commonParagraphDetails.state_specific_values
    let filteredStateList = props.options
    if (props.CommonParagraph !== undefined) {
        filteredStateList = props.options.filter((item) => !stateList.filter((index) => {
            if (index.state === item.value && props.targetData.state !== item.value) {
                return true
            } else {
                return false
            }
        }).length)
    }

    useEffect(() => {
        if (props.modalTitle.includes('Edit')) {
            setStateContent(props.targetData.default_value)
        }
    }, [props.modalTitle, props.targetData])


    const onEditorStateChange = (data:any) => {
        setStateContent(data)
    }

    const onSaveModal = (data: any) => {
        
        const value = (stateContent !== '') ? stateContent.replace(/pt;/g, 'px;').replace(/<pre style="word-break: break-word; white-space: pre-wrap; text-align: justify;">/g, '<pre>') : ''

        if (props.modalTitle.includes('Add')) {
            props.CommonParagraph.addNewStateSpecificValues(value, data.states.value, data.states.label)
        } else {
            props.targetData.state = data.states.value
            props.targetData.default_value = value
            props.targetData.label = data.states.label
        }
        props.openCloseForm()
    }



    return <>
        <FormModal show={props.showForm} modalDialogClass='modal-lg' modalTitle={props.modalTitle} openCloseModal={props.openCloseForm}>
            <form onSubmit={handleSubmit(onSaveModal)} className='modal-body' >
                <fieldset>
                    <div className='form-group'>
                        <label>Select State *</label>
                        <Controller
                            control={control}
                            name="states"
                            defaultValue={selectedState}
                            render={({ onChange, ref, value }) => (
                                <Select
                                    inputRef={ref}
                                    value={value}
                                    options={filteredStateList}
                                    className={(errors.states) ? 'is-invalid' : ''}
                                    classNamePrefix="select"
                                    onChange={(val: any) => {
                                        onChange(val)
                                    }}
                                />)}
                            rules={{
                                validate: (value) => (value === undefined) ? 'Please select state' : true
                            }}
                        />
                        {(errors.states) && (<div className='invalid-feedback'>{errors.states.message}</div>)}
                    </div>

                    <div className="form-group">
                        <div className='form-group'>
                            <ReactEditor content={stateContent} store={props.monacoStore} label='Value' onEditorChange={onEditorStateChange} />
                        </div>
                        {errors.default_for_state && (<div className='invalid-feedback'>{errors.default_for_state.message}</div>)}
                    </div>

                    <div className='row mt-4'>
                        <div className='ml-auto'>
                            <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={props.openCloseForm}>Cancel</button>
                            <button type="submit" className="btn btn-outline-primary mr-3">{(props.modalTitle.includes('Edit')) ? 'Update' : 'Add'}</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </FormModal>
    </>
}

export default StateSpecificValue