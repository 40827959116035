import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import { addTag } from '../../api/TagActions'
import InputField from '../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import classNames from 'classnames'

interface Props {
  rootStore: RootStore,
}

interface Dictionary<T> {
  // eslint-disable-next-line
  [Key: string]: T | any
}

const AddStatePage: React.FC<Props> = ({ rootStore }) => {
    const [apiErrorMessage, setApiErrorMessage] = useState('')
    const [isApiError, setIsApiError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { tagStore, allTagStore, setTitle } = rootStore
    const { fetchTag } = tagStore
    const { register, handleSubmit, errors } = useForm()
    const history = useHistory()

    const handleClick = () => {
        history.push('/tags/')
    }

    const onSubmit = (data: Dictionary<string>) => {

        setIsLoading(true)
        const requestData = {
            tag: data.tag,
            notes: data.notes,
            color: data.color
        }

        addTag(requestData).then((response) => {
            setIsLoading(false)

            if (response.data.success === 0) {
                setIsApiError(true)
                setApiErrorMessage(response.data.message)
            } else {
                allTagStore.getAllTags()
                history.push('/tags/')
                fetchTag(1, '')
            }

        }).catch((e) => {
            setIsLoading(false)
            setIsApiError(true)
            setApiErrorMessage(e.message)
        })
    }

    useEffect(() => {
        setTitle('Tag | Add')
    }, [setTitle])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Tags', to: '/tags/' },
            { name: 'Add', isActive: true }
        ]} />
    
        <div className="card">
            <div className="card-header">
        Add Tag
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        <form onSubmit={handleSubmit(onSubmit)} >
                            <fieldset>

                                <InputField
                                    label='Tag *'
                                    fieldName='tag'
                                    register={register}
                                    errors={errors.tag}
                                    placeholder='Enter Tag Name'
                                    required={true}
                                />

                                <div className="form-group">
                                    <label>Notes</label>
                                    <textarea
                                        ref={register}
                                        name='notes'
                                        className={classNames('form-control', { 'is-invalid': errors.notes })}
                                        placeholder='Enter notes'
                                    />
                                    {errors.notes && (<div className='invalid-feedback'>{errors.notes.message}</div>)}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="tagColor" className='form-label'>Select Color</label>&nbsp;
                                    <input 
                                        type="color" 
                                        id="tagColor" 
                                        className={classNames('form-control form-control-color tag-clr', { 'is-invalid': errors.color })}
                                        ref={register}
                                        name='color'
                                    />
                                    {errors.color && (<div className='invalid-feedback'>{errors.color.message}</div>)}
                                </div>
                                <div className='form-group'>
                                    <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                        {isLoading ? 'Loading...' : 'Submit'}
                                    </button>&nbsp;&nbsp;
                                    <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddStatePage)