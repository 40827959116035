import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const addUpdateStateSpecificHCTemp = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/state-with-hc-temps/add-update`
    return axios.post(url, requestData)
}

export const getStateSpecificHCTemps = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/state-with-hc-temps/`
    return axios.post(url, requestData)
}

export const getSingleStateSpecificHCTempDetails = (stateSpecificHCId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/state-with-hc-temps/state-with-hc-temp/${stateSpecificHCId}`
    return axios.post(url)
}

export const getAllTemplatesForEmail = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/templates/name/all/for-email`
    return axios.get(url)
}

export const deleteStateSpecificHCTempDetail = (stateSpecificHCId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/state-with-hc-temps/delete/${stateSpecificHCId}`
    return axios.post(url)
}