import React, { useEffect, useState } from 'react'
import RootStore from '../../../store/Root'
import Container from '../../layout/Container'
import { observer } from 'mobx-react'
import Table from '../../table/Table'
import Pagination from '../../table/Pagination'
import { Link } from 'react-router-dom'
import { TableCellAction } from '../../table/TableCellEditButton'
import { Cell } from '../../table/TableCellText.d'
import { debounceSearch } from '../../../utils/debounce'

interface Props {
  rootStore: RootStore,
}

const PriceListForSingle: React.FC<Cell> = (props) => {
    const data = props.data || {}

    const priceSingle = (data.price_for_single != undefined && data.price_for_single.price != undefined ? data.price_for_single.price : '')
    const level1PriceSingle = (data.price_for_single && data.price_for_single.level_1_price != undefined ? data.price_for_single.level_1_price : '')
    const level2PriceSingle = data.price_for_single && data.price_for_single.level_2_price != undefined ? data.price_for_single.level_2_price : ''
    const level3PriceSingle = data.price_for_single && data.price_for_single.level_3_price != undefined ? data.price_for_single.level_3_price : ''
    
    const restatementPriceSingle = (data.price_for_single != undefined && data.price_for_single.restatement_price != undefined ? data.price_for_single.restatement_price : '')
    const restatementLevel1PriceSingle = (data.price_for_single && data.price_for_single.restatement_level_1_price != undefined ? data.price_for_single.restatement_level_1_price : '')
    const restatementLevel2PriceSingle = data.price_for_single && data.price_for_single.restatement_level_2_price != undefined ? data.price_for_single.restatement_level_2_price : ''
    const restatementLevel3PriceSingle = data.price_for_single && data.price_for_single.restatement_level_3_price != undefined ? data.price_for_single.restatement_level_3_price : ''

    return (
        <>
            <div className='row'>
                <div className='col-8'><b>Initial: </b></div>
                <div className='col-4'>{priceSingle}</div>
            </div>
            <div className='row'>
                <div className='col-8'><b>Level - 1: </b></div> 
                <div className='col-4'>{level1PriceSingle}</div>
            </div>
            <div className='row'>
                <div className='col-8'><b>Level - 2: </b></div>
                <div className='col-4'>{level2PriceSingle}</div>
            </div>
            <div className='row'>
                <div className='col-8'><b>Level - 3: </b></div>
                <div className='col-4'>{level3PriceSingle}</div>
            </div>
            <br/>
            <div className='row'>
                <div className='col-8'><b>Restatement Initial: </b></div>
                <div className='col-4'>{restatementPriceSingle}</div>
            </div>
            <div className='row'>
                <div className='col-8'><b>Restatement Level - 1: </b></div>
                <div className='col-4'>{restatementLevel1PriceSingle}</div>
            </div>
            <div className='row'>
                <div className='col-8'><b>Restatement Level - 2: </b></div>
                <div className='col-4'>{restatementLevel2PriceSingle}</div>
            </div>
            <div className='row'>
                <div className='col-8'><b>Restatement Level - 3: </b></div>
                <div className='col-4'>{restatementLevel3PriceSingle}</div>
            </div>
        </>
    )
}

const PriceListForMarried: React.FC<Cell> = (props) => {
    const data = props.data || {}

    const priceMarried = (data.price_for_married != undefined && data.price_for_married.price != undefined ? data.price_for_married.price : '')
    const level1PriceMarried = (data.price_for_married && data.price_for_married.level_1_price != undefined ? data.price_for_married.level_1_price : '')
    const level2PriceMarried = data.price_for_married && data.price_for_married.level_2_price != undefined ? data.price_for_married.level_2_price : ''
    const level3PriceMarried = data.price_for_married && data.price_for_married.level_3_price != undefined ? data.price_for_married.level_3_price : ''

    const restatementPriceMarried = (data.price_for_married != undefined && data.price_for_married.restatement_price != undefined ? data.price_for_married.restatement_price : '')
    const restatementLevel1PriceMarried = (data.price_for_married && data.price_for_married.restatement_level_1_price != undefined ? data.price_for_married.restatement_level_1_price : '')
    const restatementLevel2PriceMarried = data.price_for_married && data.price_for_married.restatement_level_2_price != undefined ? data.price_for_married.restatement_level_2_price : ''
    const restatementLevel3PriceMarried = data.price_for_married && data.price_for_married.restatement_level_3_price != undefined ? data.price_for_married.restatement_level_3_price : ''
    
    return (
        <>
            <div className='row'>
                <div className='col-8'><b>Initial: </b></div>
                <div className='col-4'>{priceMarried}</div>
            </div>
            <div className='row'>
                <div className='col-8'><b>Level - 1: </b></div>
                <div className='col-4'>{level1PriceMarried}</div>
            </div>
            <div className='row'>
                <div className='col-8'><b>Level - 2: </b></div>
                <div className='col-4'>{level2PriceMarried}</div>
            </div>
            <div className='row'>
                <div className='col-8'><b>Level - 3: </b></div>
                <div className='col-4'>{level3PriceMarried}</div>
            </div>
            <br/>
            <div className='row'>
                <div className='col-8'><b>Restatement Initial: </b></div>
                <div className='col-4'>{restatementPriceMarried}</div>
            </div>
            <div className='row'>
                <div className='col-8'><b>Restatement Level - 1: </b></div>
                <div className='col-4'>{restatementLevel1PriceMarried}</div>
            </div>
            <div className='row'>
                <div className='col-8'><b>Restatement Level - 2: </b></div>
                <div className='col-4'>{restatementLevel2PriceMarried}</div>
            </div>
            <div className='row'>
                <div className='col-8'><b>Restatement Level - 3: </b></div>
                <div className='col-4'>{restatementLevel3PriceMarried}</div>
            </div>
        </>
    )
}

const PlansPage: React.FC<Props> = ({ rootStore }) => {
    const { planStore, authStore, setTitle } = rootStore
    const { isAdmin } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, planData, fetchPlans, isPageOpened } = planStore
    const [searchItemField, setSearchItemField] = useState(searchItem)

    const loadPage = (page: number) => {
        fetchPlans(page, searchItem, 'change_page')
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchPlans(1, e.target.value.trim(), 'search_item'))
    }

    useEffect(() => {
        setTitle('Vision Plan | OathZephyr')
        if(isAdmin && !isPageOpened){
            fetchPlans(1, '', '')
        }
    })

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Plans
                    <Link to='/plans/add' className='ml-auto px-3'>Add Plan</Link>
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'name', title: 'Name' },
                                    { name: 'display_order', title: 'Display Order' },
                                    { name: 'price_for_single', title: 'Price List for Single', component: PriceListForSingle, class:'fix-cell'  },
                                    { name: 'price_for_married', title: 'Price List for Married', component: PriceListForMarried, class:'fix-cell' },
                                    { name: '_id', title: 'Action', component: TableCellAction }
                                ]}
                                data={planData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={planData.currentPage}
                            totalPages={planData.totalPage}
                            totalItems={planData.totalItem}
                            itemsPerPage={planData.itemPerPage}
                            isFiltered={planData.isFiltered}
                            totalAllItems={planData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(PlansPage)
