import React, { useState, useEffect } from 'react'
import RootStore from '../../store/Root'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import { addPackage } from '../../api/PackageActions'
import InputField from '../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import classNames from 'classnames'

interface Props {
  rootStore: RootStore,
}

interface Dictionary<T> {
  // eslint-disable-next-line
  [Key: string]: T | any
}

const AddPackagePage: React.FC<Props> = ({ rootStore }) => {
    const [apiErrorMessage, setApiErrorMessage] = useState('')
    const [isApiError, setIsApiError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { allTemplatesStore, packageStore, setTitle } = rootStore
    const { allTemplatesName } = allTemplatesStore

    const [isCheckAllTemplate, setIsCheckAllTemplate] = useState(false)
    const [checkedTemplates, setCheckedTemplates] = useState<Array<string>>([])
    const { fetchPackage } = packageStore
    const { register, handleSubmit, errors } = useForm()
    const history = useHistory()

    const handleClick = () => {
        history.push('/packages/')
    }

    useEffect(() => {
        allTemplatesStore.getAllTemplatesName()
    }, [])

    const handleOnClickSelectAll = () => {
        setIsCheckAllTemplate(!isCheckAllTemplate)
        setCheckedTemplates(allTemplatesName.map(list => list._id))
        if (isCheckAllTemplate) {
            setCheckedTemplates([])
        }
    }

    const handleOnClickTemplate = (e: any) => {
        const { value, checked } = e.target
        setIsCheckAllTemplate(false)
        setCheckedTemplates([...checkedTemplates, value])
        if (!checked) {
            setCheckedTemplates(checkedTemplates.filter(item => item !== value))
        }
    }

    const onSubmit = (data: Dictionary<string>) => {

        setIsLoading(true)
        const requestData = {
            name: data.name,
            templates: checkedTemplates,
            display_order: data.display_order,
            slug: data.slug
        }

        addPackage(requestData).then((response) => {
            setIsLoading(false)

            if (response.data.success === 0) {
                setIsApiError(true)
                setApiErrorMessage(response.data.message)
            } else {
                history.push('/packages/')
                fetchPackage(1, '')
            }

        }).catch((e) => {
            setIsLoading(false)
            setIsApiError(true)
            setApiErrorMessage(e.message)
        })
    }

    useEffect(() => {
        setTitle('Package | Add')
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Packages', to: '/packages/' },
            { name: 'Add', isActive: true }
        ]} />
        <div className="card">
            <div className="card-header">
        Add Package
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        <form onSubmit={handleSubmit(onSubmit)} >
                            <fieldset>
                                <InputField
                                    label='Name *'
                                    fieldName='name'
                                    register={register}
                                    errors={errors.name}
                                    placeholder='Enter Name'
                                    required={true}
                                />
                                <div className="form-group">
                                    <label>Slug *</label>
                                    <input
                                        ref={register({
                                            required: 'This field is required',
                                            pattern: {
                                                value: /^[\w-.]*$/,
                                                message: 'This type of slug not allow'
                                            }
                                        })}
                                        name='slug'
                                        type='text'
                                        className={classNames('form-control', { 'is-invalid': errors.slug })}
                                        placeholder='Enter Slug'
                                    />
                                    {errors.slug ? (<div className='invalid-feedback'>{errors.slug.message}</div>) :
                                        <small className='form-text text-muted'>Unique snake case name to identify the field during interview. It is used to recognize the selected package of matter in the template and help to check conditions.</small>}
                                </div>
                                <div className="form-group">
                                    <label>Display Order</label>
                                    <input
                                        ref={register({
                                            required: 'This field is required'
                                        })}
                                        name='display_order'
                                        type='number'
                                        min={1}
                                        className={classNames('form-control', { 'is-invalid': errors.display_order })}
                                        defaultValue={0}
                                    />
                                    {errors.display_order && (<div className='invalid-feedback'>{errors.display_order.message}</div>)}
                                </div>
                                <div className='form-group'>
                                    <label>Templates</label>
                                    <div className='form-check'>
                                        <label><input name='templates' type='checkbox' ref={register} value='' checked={allTemplatesName.length === checkedTemplates.length} onClick={handleOnClickSelectAll} /> Select / Deselect All </label><br />

                                        {allTemplatesName.map((list) =>
                                            <><label><input name='templates' key={list._id} type='checkbox' ref={register} checked={checkedTemplates.includes(list._id)} value={list._id} onClick={handleOnClickTemplate} /> {list.name} </label><br /></>
                                        )}
                                    </div>
                                    {errors.templates && (<div className='invalid-feedback'>{errors.templates.message}</div>)}
                                </div>
                                <div className='form-group'>
                                    <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                        {isLoading ? 'Loading...' : 'Submit'}
                                    </button>&nbsp;&nbsp;
                                    <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddPackagePage)