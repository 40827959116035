import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'


export const getThirdPartyLogs = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/third-party-logs/`
    return axios.post(url, requestData)
}

export const getThirdPartyRequestData = (id: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/third-party-logs/request-data/${id}`
    return axios.post(url)
}

export const getThirdPartyResponseData = (id: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/third-party-logs/response-data/${id}`
    return axios.post(url)
}
