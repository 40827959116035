import React from 'react'
import { Link } from 'react-router-dom'
import { Cell } from '../table/TableCellText.d'

export const EditButtonCell: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link to='#' onClick={() => { onActionClicked('editButtonInField', data, false) }} title='Edit Conditional Options'>Update</Link>
        </>
    )
}

export const DeleteButtonCell: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link to='#' onClick={() => { onActionClicked('deleteButtonInField', data, false) }} title='Delete Conditional Options'>Delete</Link>
        </>
    )
}