import React from 'react'

interface Props {
	modalTitle: string
	show: boolean
	modalDialogClass?: string
	openCloseModal?: () => void
    includeHeader?: boolean
}

const FormModal: React.FC<Props> = (props) => {
    const defaultModalDialogClass = 'modal-dialog modal-dialog-scrollable '
    const showModal = props.show
    const modalTitle = props.modalTitle
    const includeHeader = props.includeHeader
    const openCloseModal = props.openCloseModal
    const modalDialogClass = (props.modalDialogClass === undefined) ? defaultModalDialogClass : defaultModalDialogClass + props.modalDialogClass
	
    const styles = showModal ? { display: 'block' } : { display: 'none' }
    const modalClass = showModal ? 'modal fade in show' : 'modal in fade'
    const backDropStyle = showModal ? { opacity: .3 } : { display: 'none' }
	
    return (
        <>
            {showModal && <div className='modal-backdrop' style={backDropStyle}></div>}
            <div id="myModal" className={modalClass} role="dialog" style={styles}>
                <div className={modalDialogClass}>
                    <div className="modal-content">
                        <div className="modal-header">
                            {includeHeader ? <h5 className='mb-0 text-wrap'>{modalTitle}</h5> : <div className='modal-title'> {modalTitle} </div> }
                            <button type="button" className="close" onClick={openCloseModal} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormModal