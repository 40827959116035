import React from 'react'
import NavBar from './NavBar'
import RootStore from '../../store/Root'
import { Redirect } from 'react-router-dom'

interface Props {
  rootStore: RootStore,
  redirectIfNotLoggedIn?: boolean
}

const DefaultLayout: React.FC<Props> = (props) => {
    const rootStore = props.rootStore
    if (props.redirectIfNotLoggedIn === true) {
        if (rootStore.authStore.isUserLoggedIn === false) {
            return <Redirect to="/login" />
        }
    }

    return <>
        <NavBar rootStore={props.rootStore} />
        <div className='container-fluid'>
            {props.children}
        </div>
    </>
}

export default DefaultLayout