import React, { useEffect, useState } from 'react'
import MonacoEditor, { useMonaco } from '@monaco-editor/react'
import RenderRawHtml from './RenderRawHtml'
import MonacoEditorStore from '../../store/MonacoEditorStore'
import { BtnBold, BtnItalic, BtnUndo, BtnRedo, Separator, BtnUnderline, BtnNumberedList, BtnBulletList, BtnClearFormatting, EditorProvider, Toolbar, Editor } from 'react-simple-wysiwyg'
import { Preview } from '../commonComponent/PreviewStyle'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'

interface Props {
    content?: string
    onEditorChange: (data: any) => void
    label?: string
    store?: MonacoEditorStore
    isSimpleEditor?: boolean
    enableSideBySideView?: boolean
}

const ReactEditor: React.FC<Props> = (props) => {
    const label = props.label || 'Body'
    const editor_content = props.content ? props.content : ''
    const enableSideBySideView = (props.enableSideBySideView !== undefined) ? props.enableSideBySideView : false
    const [content, setContent] = useState(editor_content)
    const [lastUpdated, setLastUpdated] = useState(new Date().toString())
    const [safeMode, setSafeMode] = useState(false)
    const { store } = props
    const isSimpleEditor = (props.isSimpleEditor !== undefined) ? props.isSimpleEditor : false
    const [isMonacoEditor, setIsMonacoEditor] = useState(!isSimpleEditor)
    const [simpleEditorClass, setSimpleEditorClass] = useState('btn btn-secondary')
    const [monacoEditorClass, setMonacoEditorClass] = useState('btn btn-outline-secondary')

    const monaco = useMonaco()
    const endOfSection = `<EndOfSection>${'⁤'}</EndOfSection>`
    useEffect(() => {
        if (monaco) {
            store?.setupMonacoEditor(monaco)
        }
    }, [isMonacoEditor, isSimpleEditor, monaco, store])

    function countEndOfSectionTags(str: string) {
        const matches = str.match(/<endofsection>⁤<\/endofsection>/gi)
        return matches ? matches.length : 0
    }

    const onContentChange = (text) => {
        const updatedText = (!isMonacoEditor) ? text.target.value : text
        if(isSimpleEditor && !safeMode) {
            const oldNoOfEndOfSectionTag = countEndOfSectionTags(content)
            const editedNoOfEndOfSectionTag = countEndOfSectionTags(updatedText)
            if(oldNoOfEndOfSectionTag !== editedNoOfEndOfSectionTag){
                setLastUpdated(new Date().toString())
                setContent(content)
                toast.error('Your changes are not applied.')
                return
            }
        }

        if (!isMonacoEditor) {
            setContent(updatedText)
            props.onEditorChange(updatedText)
        } else {
            setContent(updatedText)
            props.onEditorChange(updatedText)
        }
    }

    const handleOnClickWysiwygEditor = () => {
        setIsMonacoEditor(false)
        setSimpleEditorClass('btn btn-secondary')
        setMonacoEditorClass('btn btn-outline-secondary')
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur()
        }
    }

    const handleOnClickMonacoEditor = () => {
        setIsMonacoEditor(true)
        setSimpleEditorClass('btn btn-outline-secondary')
        setMonacoEditorClass('btn btn-secondary')
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur()
        }
    }

    const handleOnChangeSafeMode = (data) => {
        setSafeMode(data.target.checked)
    }

    return <>
        {enableSideBySideView ? <>
            <div className='row'>
                <div className='col'>
                    <label className='mb-0 mt-3 pb-1'>{label}</label>
                    {isSimpleEditor && <div className='row'>
                        <div className='col mt-auto pb-2'>
                            <Preview>
                                <small>
                                    <div className='text-muted '>
                                        <b>Note:</b> <br />
                                        1. Please do not remove dot like this. <span dangerouslySetInnerHTML={{ __html: endOfSection }} /><br />
                                        2. Please do not edit highlighted text.
                                    </div>
                                </small>
                            </Preview>
                        </div>
                        <div className='col mt-auto pb-2'>
                            <div className="btn-group btn-group-sm mt-4 float-right pr-2" role="group">
                                <button type="button" className={simpleEditorClass} onClick={handleOnClickWysiwygEditor}>Preview Editor</button>
                                <button type="button" className={monacoEditorClass} onClick={handleOnClickMonacoEditor}>HTML Editor</button>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className='col mt-auto pb-1'>
                    Preview
                    {isSimpleEditor && <div id='advancedMode' className="custom-control custom-switch float-right pr-1">
                        <input type="checkbox" defaultChecked={safeMode}
                            className="custom-control-input" name='safe_mode' onChange={handleOnChangeSafeMode} id="safeMode" />
                        <label className="custom-control-label" htmlFor='safeMode'>Advanced mode</label>
                    </div>}
                    {isSimpleEditor && <Tooltip place='top-start' anchorId='advancedMode'>
                        <span className='z-3'>Use to edit section header for the table of contents</span>
                    </Tooltip>}
                </div>
            </div>
            <div className='row'>
                {isMonacoEditor == true ? <div className='col' style={{ width:'50%', maxHeight: 'calc(100vh - 300px)', minHeight:'auto'}}>
                    <div className='card form-group' style={{ padding:'0', height:'auto'}}>
                        <MonacoEditor
                            height={'calc(100vh - 300px)'}
                            language='oath'
                            width={'auto'}
                            defaultValue={editor_content}
                            onChange={onContentChange}
                            theme="oathTheme"
                            options={{
                                minimap: {
                                    enabled: false
                                },
                                wordWrap: 'wordWrapColumn',
                                wordWrapColumn: 68,
                                wrappingIndent: 'same',
                                'bracketPairColorization.enabled': true,
                                'semanticHighlighting.enabled': true,
                                fontSize: '14px'
                            }}
                            key={lastUpdated}
                        />
                    </div>
                </div> : <div className='col' style={{ width:'50%', maxHeight:'calc(100vh - 300px)', overflowY:'auto', height:'calc(100vh - 300px)' }}>
                    <div className='card form-group' style={{ padding:'0' }}>
                        <EditorProvider>
                            <Editor value={content} onChange={onContentChange} style={{ padding: '60px 84px 0 84px', minHeight:'calc(100vh - 300px)' }} key={lastUpdated}>
                                <Toolbar>
                                    <BtnUndo />
                                    <BtnRedo />
                                    <Separator />
                                    <BtnBold />
                                    <BtnItalic />
                                    <BtnUnderline />
                                    <Separator />
                                    <BtnNumberedList />
                                    <BtnBulletList />
                                    <Separator />
                                    <BtnClearFormatting />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                    </div>
                </div>}
                <div className='col' style={{ width:'50%', height: 'calc(100vh - 300px)' }}>
                    <div className='card form-group' style={{ padding: '60px 84px 30px 84px', margin:'0', maxHeight: 'calc(100vh - 300px)', overflowY:'auto', height:'calc(100vh - 300px)'}}>
                        <RenderRawHtml>{content}</RenderRawHtml>
                    </div>
                </div>
            </div>
        </>: <>
            <label className='mb-0 mt-2'>{label}</label>
            {isSimpleEditor && <div className='row'>
                <div className='col'>
                    <Preview>
                        <small>
                            <div className='card-title text-muted '>
                                <b>Note:</b> <br />
                        1. Please do not remove dot like this. <span dangerouslySetInnerHTML={{ __html: endOfSection }} /><br />
                        2. Please do not edit highlighted text.
                            </div>
                        </small>
                    </Preview>
                </div>
                <div className='col'>
                    <div className="btn-group btn-group-sm float-right mr-1 mt-4" role="group">
                        <button type="button" className={simpleEditorClass} onClick={handleOnClickWysiwygEditor}>Preview Editor</button>
                        <button type="button" className={monacoEditorClass} onClick={handleOnClickMonacoEditor}>HTML Editor</button>
                    </div>
                </div>
            </div>}
            {isMonacoEditor == true ? <div className='card form-group'>
                <MonacoEditor
                    height="350px"
                    language='oath'
                    defaultValue={editor_content}
                    onChange={onContentChange}
                    theme="oathTheme"
                    options={{
                        minimap: {
                            enabled: false
                        },
                        wordWrap: 'wordWrapColumn',
                        wordWrapColumn: 116,
                        wrappingIndent: 'same',
                        'bracketPairColorization.enabled': true,
                        'semanticHighlighting.enabled': true,
                        fontSize: '14px'
                    }}
                /></div> : <div className='card form-group' style={{ width: 'auto', padding: '60px 84px 30px 84px', margin: '0px 148.8px' }}><EditorProvider>
                <Editor value={content} onChange={onContentChange}>
                    <Toolbar>
                        <BtnUndo />
                        <BtnRedo />
                        <Separator />
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <Separator />
                        <BtnNumberedList />
                        <BtnBulletList />
                        <Separator />
                        <BtnClearFormatting />
                    </Toolbar>
                </Editor>
            </EditorProvider>
            </div>}
            <label className='mb-0 mt-2'>Preview</label>
            <div className='card form-group' style={{ width: 'auto', padding: '60px 84px 30px 84px', margin: '0px 148.8px' }}>
                <RenderRawHtml>{content}</RenderRawHtml>
            </div>
        </>}
    </>
}

export default ReactEditor