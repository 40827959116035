import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const addUpdateAddress = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/addresses/add-update`
    return axios.post(url, requestData)
}

export const getAddresses = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/addresses/`
    return axios.post(url, requestData)
}

export const getSingleAddressDetails = (addressId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/addresses/address/${addressId}`
    return axios.get(url)
}

export const getAddressTitleId = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/addresses/all/title-id`
    return axios.get(url)
}
