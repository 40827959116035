import { observable } from 'mobx'
import { ButtonsInField } from './types.d'


class ButtonsInFieldStore implements ButtonsInField {
    @observable public label: string
    @observable public action_string: string
    @observable public is_all_packages: boolean
    @observable public packages: Array<string>

    constructor(ButtonsInFields: ButtonsInField) {
        this.label = ''
        this.action_string = ''
        this.is_all_packages = true
        this.packages = []
        this.set(ButtonsInFields)
    }

    set(ButtonsInFields: ButtonsInField): void {
        this.label = ButtonsInFields.label
        this.action_string = ButtonsInFields.action_string
        this.is_all_packages = ButtonsInFields.is_all_packages
        this.packages = ButtonsInFields.packages

    }

    get(): ButtonsInField {
        return {
            label: this.label,
            action_string: this.action_string,
            is_all_packages: this.is_all_packages,
            packages: this.packages
        }
    }
}

export default ButtonsInFieldStore