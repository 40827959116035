import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const addUpdateCondition = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/conditions/add-update`
    return axios.post(url, requestData)
}

export const getConditions = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/conditions/`
    return axios.post(url, requestData)
}

export const getConditionDetails = (conditionId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/conditions/condition/${conditionId}`
    return axios.post(url)
}

export const getConditionsNameId = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/conditions/name-id`
    return axios.get(url)
}