import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../../store/types.d'

export const getAudioInformation = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/audio-list/`
    return axios.post(url, requestData)
}

export const deleteRecordingInfo = (recording_id: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/audio-list/${recording_id}`
    return axios.delete(url)
}