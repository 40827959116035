import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../../store/types.d'

export const addUpdateEngagementTemplate = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/engagement-templates/add-update`
    return axios.post(url, requestData)
}

export const getEngagementTemplates = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/engagement-templates/`
    return axios.post(url, requestData)
}

export const getSingleEngagementTemplateDetails = (engagementTemplateId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/engagement-templates/engagement-template/${engagementTemplateId}`
    return axios.post(url)
}

export const getEngagementTemplateNameId = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/engagement-templates/all`
    return axios.post(url)
}

export const getDefaultEngagementTemplateDetails = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/engagement-templates/default`
    return axios.post(url)
}
