import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const getIpadLocalData = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/ipad-local-data/`
    return axios.post(url, requestData)
}

export const getIpadLocalDataDetails = (id: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/ipad-local-data/ipad-local-data/${id}`
    return axios.post(url)
}