import React from 'react'
import { Link } from 'react-router-dom'
import { Cell } from '../table/TableCellText.d'

export const EditStateSpecificDefaultValueButton: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link to='#' onClick={() => { onActionClicked('editStateSpecificDefault', data, false) }} title='Edit State Spacific Default'> Update</Link>
        </>
    )
}

export const EditStateSpecificDefaultValueButtonForDependable: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link to='#' onClick={() => { onActionClicked('editStateSpecificDefault', data, true) }} title='Edit State Spacific Default'> Update</Link>
        </>
    )
}

export const EditConditionalOptions: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link to='#' onClick={() => { onActionClicked('editConditionalOptions', data, true) }} title='Edit Conditional Options'> Update</Link>
        </>
    )
}