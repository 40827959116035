import React, { useState } from 'react'
import { ValueLabel, Dictionary, PackageSelectable } from '../../store/types.d'
import FormModal from './FormModal'
import Select from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import { makeValueLabelList } from './commonFuction'
import classNames from 'classnames'

interface Props {
	options: Array<ValueLabel>
	targetStore?: PackageSelectable
	selectorShow: boolean
	openClosePackageModal?: () => void
}

const PackagesSelector: React.FC<Props> = (props) => {

    props.targetStore.packages = props.targetStore.packages == undefined ? [] : props.targetStore.packages
    props.targetStore.is_all_packages = props.targetStore.is_all_packages == undefined ? [] : props.targetStore.is_all_packages

    const selected = makeValueLabelList(props.targetStore.packages, props.options)
    const [isAllPackages, setIsAllPackages] = useState(props.targetStore.is_all_packages)

    const scrollableBodyStyle = 'modal-body resource'
    const { register, handleSubmit, errors, control, setValue } = useForm()

    const onSaveModal = (data: Dictionary<string>) => {
        const packages = (data.packages) ? data.packages.map((item) => item.value) : []
        props.targetStore.is_all_packages = data.is_all_packages
        props.targetStore.packages = packages
        props.openClosePackageModal()
    }

    const handleAllPackagesSelect = (e: any) => {
        const value = e.target.checked
        setIsAllPackages(value)
        if (value) {
            setValue('packages', [])
        }
    }

    return <>
        <FormModal show={props.selectorShow} modalDialogClass='' modalTitle='Select Packages' openCloseModal={props.openClosePackageModal}>

            <form onSubmit={handleSubmit(onSaveModal)} >
                <fieldset>
                    <div className={scrollableBodyStyle} >
                        <div className='ml-2'>
                            <div className="custom-control form-group mt-1 custom-switch">
                                <input type="checkbox" defaultChecked={isAllPackages}
                                    ref={register}
                                    className="custom-control-input" name='is_all_packages' onClick={handleAllPackagesSelect} id="allPackages" />
                                <label className="custom-control-label" htmlFor='allPackages'>All Packages</label>
                                {errors.is_all_packages && (<div className='invalid-feedback'>{errors.is_all_packages.message}</div>)}
                            </div>
                            <div className='form-group'>
                                <div className={classNames({ 'd-none': isAllPackages })}>
                                    <Controller
                                        control={control}
                                        name="packages"
                                        defaultValue={selected}
                                        render={({ onChange, ref, value }) => (
                                            <Select
                                                isMulti
                                                inputRef={ref}
                                                value={value}
                                                options={props.options}
                                                className={(errors.packages) ? 'is-invalid' : ''}
                                                classNamePrefix="select"
                                                onChange={val => {
                                                    onChange(val)
                                                }}
                                            />)}
                                        rules={{
                                            validate: (value) => (value.length === 0 && isAllPackages === false) ? 'Please select packages' : true
                                        }}
                                    />
                                    {(errors.packages) && (<div className='invalid-feedback'>Please select packages</div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={props.openClosePackageModal}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary">Update</button>
                    </div>
                </fieldset>
            </form>
        </FormModal>
    </>
}

export default PackagesSelector