import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { Cell } from '../table/TableCellText.d'
import { debounceSearch } from '../../utils/debounce'

interface Props {
    rootStore: RootStore,
}

const TableCellValue: React.FC<Cell> = (props) => {

    return (
        <>
            {props.value === true ? 'Yes' : 'No' }
        </>
    )
}

const DeedResearchPage: React.FC<Props> = ({ rootStore }) => {
    const { trusteeInfoStore, setTitle, authStore } = rootStore
    const { userOrg } = authStore
    const { trusteeInformationData,  fetchTrusteeInfo, searchItem, isApiError, isLoading, apiErrorMessage, isPageOpened } = trusteeInfoStore
    const [searchItemField, setSearchItemField] = useState(searchItem)

    const loadPage = (page: number) => {
        fetchTrusteeInfo(page, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchTrusteeInfo(1, e.target.value.trim()))
    }

    useEffect(() => {
        setTitle('Trustee Information | OathZephyr')
        if(rootStore.authStore.isAdmin && !isPageOpened){
            fetchTrusteeInfo(1, '')
        }
    }, [userOrg])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Trustee Information
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {((isApiError) && <div className='responsive alert-danger p-3 mb-4 rounded' >{apiErrorMessage}</div>)}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'matter_name', title: 'Matter Name' },
                                    { name: 'client_name', title: 'Client Name' },
                                    { name: 'trustee_name', title: 'Trustee Name' },
                                    { name: 'trustee_email', title: 'Trustee Email' },
                                    { name: 'switch_remind_client', title: 'Has need to remind client', component: TableCellValue },
                                    { name: 'is_added_in_convert_kit', title: 'Convert kit Status' },
                                    { name: 'createdAt', title: 'Created At' },
                                    { name: 'updatedAt', title: 'Updated At' }
                                ]}
                                data={trusteeInformationData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={trusteeInformationData.currentPage}
                            totalPages={trusteeInformationData.totalPage}
                            totalItems={trusteeInformationData.totalItem}
                            itemsPerPage={trusteeInformationData.itemPerPage}
                            isFiltered={trusteeInformationData.isFiltered}
                            totalAllItems={trusteeInformationData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(DeedResearchPage)
