import { observable } from 'mobx'
import { ClientDraftAdjustment } from './types.d'


class ClientDraftAdjustmentStore implements ClientDraftAdjustment {
    @observable public _id: string
    @observable public note: string
    @observable public is_completed: boolean
    @observable public title: string

    constructor(client_draft_adjustment: ClientDraftAdjustment) {
        this._id = ''
        this.note = ''
        this.is_completed = false
        this.set(client_draft_adjustment)
    }

    set(client_draft_adjustment: ClientDraftAdjustment): void {
        this._id = client_draft_adjustment._id
        this.note = client_draft_adjustment.note
        this.title = client_draft_adjustment.title
    }

    get(): ClientDraftAdjustment {
        return {
            _id: this._id,
            note: this.note,
            title: this.title
        }
    }
}

export default ClientDraftAdjustmentStore