import React from 'react'
import { Cell } from './TableCellText.d'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

export const TableCellActiveInactive: React.FC<Cell> = (props) => {
    const value = props.value
    return (
        <>
            {value === 'ACTIVE' ? <FontAwesomeIcon className='text-success' icon={faCheck} /> : <FontAwesomeIcon className='text-danger' icon={faTimes} />}
        </>
    )
}