import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const generateLawmaticsToken = (token: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/lawmatics/connect`
    return axios.post(url, { token: token })
}

export const setEmptyLawmaticsToken = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/lawmatics/disconnect`
    return axios.post(url)
}

export const getLawmaticsToken = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/lawmatics/tokens/`
    return axios.get(url)
}

export const getLawmaticsCronData = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/lawmatics-cron/all`
    return axios.post(url, requestData)
}
