import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../../store/types.d'

export const addUpdateProblemQuestion = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/problem-questions/add-update`
    return axios.post(url, requestData)
}

export const getProblemQuestions = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/problem-questions/`
    return axios.post(url, requestData)
}

export const getSingleProblemQuestionDetails = (problemQuestionsId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/problem-questions/problem-question/${problemQuestionsId}`
    return axios.post(url)
}