import { observable } from 'mobx'
import { Paragraph, EditedAt } from './types.d'

class ParagraphStore implements Paragraph {
    @observable public name: string
    @observable public description: string
    @observable public keywords: Array<string>
    @observable public is_all_states: boolean
    @observable public type: string
    @observable public states: Array<string>
    @observable public body: string
    @observable public edit_history?: Array<EditedAt>

    constructor(paragraph: Paragraph) {
        this.set(paragraph)
    }

    set(paragraph: Paragraph) {
        this.name = paragraph.name
        this.description = paragraph.description
        this.keywords = paragraph.keywords || ['']
        this.is_all_states = paragraph.is_all_states
        this.type = paragraph.type
        this.states = paragraph.states
        this.body = paragraph.body || ''
        this.edit_history = paragraph.edit_history || undefined
    }

    get(): Paragraph {
        return {
            name: this.name,
            description: this.description,
            keywords: this.keywords,
            is_all_states: this.is_all_states,
            type: this.type,
            states: this.states,
            body: this.body,
            edit_history: this.edit_history
        }
    }

}

export default ParagraphStore