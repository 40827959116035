import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

interface Props {
	onClickHandle: (e:any) => void,
	buttonToolTip?: string,
	isDisable?: boolean
}

const EditButton: React.FC<Props> = (props) => {
    const isDisable = props.isDisable || false
    return (
        <>
            <button type='button' className='btn btn-sm' disabled={isDisable} title={props.buttonToolTip} onClick={props.onClickHandle}><FontAwesomeIcon className='font-weight-normal' icon={faPencilAlt} /></button>
        </>
    )
}

export default EditButton