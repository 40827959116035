import React from 'react'
import { ValueLabel } from '../../store/types.d'
import FormModal from '../commonComponent/FormModal'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import classNames from 'classnames'
import SingleFieldStore from '../../store/SingleField'

interface Props {
    conditionOptions: Array<ValueLabel>
	options: Array<ValueLabel>
	targetData?: any
	showForm: boolean
	modalTitle: string
	isDependAnswer: boolean
	fieldStore: SingleFieldStore
	openCloseForm: () => void
}

const StateSpecificDefaultForm: React.FC<Props> = (props) => {

    const isDependAnswer = props.isDependAnswer
    const makeValueLabelList = (data: any, allOptions: Array<ValueLabel>) => {
        for (let j = 0; j < allOptions.length; j++) {
            if (data === allOptions[j].value) {
                return allOptions[j]
            }
        }
    }

    const selectedState = makeValueLabelList(props.targetData.state, props.options)
    const selectedCondition = makeValueLabelList(props.targetData.condition_id, props.conditionOptions)

    let stateList: any
    if (isDependAnswer) {
        stateList = props.fieldStore.default_answer_for_dependable_state_wise
    } else {
        stateList = props.fieldStore.state_specific_default
    }

    let filteredStateList = props.options
    if (props.fieldStore !== undefined) {
        filteredStateList = props.options.filter((item) => !stateList.filter((index) => {
            if (index.state === item.value && props.targetData.state !== item.value) {
                return true
            } else {
                return false
            }
        }).length)
    }

    const scrollableBodyStyle = 'modal-body resource'
    const { register, handleSubmit, errors, control } = useForm()

    const onSaveModal = (data: any) => {
        if (props.modalTitle.includes('Add')) {

            if(!isDependAnswer) {
                props.fieldStore.addNewStateSpecific(isDependAnswer, data.default_for_state, data.states.value, data.states.label, data.condition.value, data.condition.label)
            } else {
                props.fieldStore.addNewStateSpecific(isDependAnswer, data.default_for_state, data.states.value, data.states.label)
            }

        } else {
            props.targetData.state = data.states.value
            props.targetData.default_value = data.default_for_state
            props.targetData.label = data.states.label

            if(!isDependAnswer) {
                props.targetData.condition_name = data.condition.label
                props.targetData.condition_id = data.condition.value
            }
        }
        props.openCloseForm()
    }

    return <>
        <FormModal show={props.showForm} modalTitle={props.modalTitle} openCloseModal={props.openCloseForm}>

            <form onSubmit={handleSubmit(onSaveModal)} >
                <fieldset>
                    <div className={scrollableBodyStyle} >
                        <div className='ml-2'>

                            <div className='form-group'>
                                <label>Select State *</label>
                                <Controller
                                    control={control}
                                    name="states"
                                    defaultValue={selectedState}
                                    render={({ onChange, ref, value }) => (
                                        <Select
                                            inputRef={ref}
                                            value={value}
                                            options={isDependAnswer? filteredStateList : props.options}
                                            className={(errors.states) ? 'is-invalid' : ''}
                                            classNamePrefix="select" 
                                            onChange={val => {
                                                onChange(val)
                                            }}
                                        />)}
                                    rules={{
                                        validate: (value) => (value === undefined) ? 'Please select state' : true
                                    }}
                                />
                                {(errors.states) && (<div className='invalid-feedback'>{errors.states.message}</div>)}
                            </div>
                            <div className={classNames({ 'd-none': isDependAnswer })}>
                                <div className='form-group'>
                                    <label>Select Condition *</label>
                                    <Controller
                                        control={control}
                                        name="condition"
                                        defaultValue={selectedCondition}
                                        render={({ onChange, ref, value }) => (
                                            <Select
                                                inputRef={ref}
                                                value={value}
                                                options={props.conditionOptions}
                                                className={(errors.condition) ? 'is-invalid' : ''}
                                                classNamePrefix="select" 
                                                onChange={val => {
                                                    onChange(val)
                                                }}
                                            />)}
                                        rules={{
                                            validate: (value) => (value === undefined) ? 'Please select condition' : true
                                        }}
                                    />
                                    {(errors.condition) && (<div className='invalid-feedback'>{errors.condition.message}</div>)}
                                </div> 
                            </div>

                            <div className="form-group">
                                <label>Default Value *</label>
                                <input
                                    ref={register({
                                        required: 'This field is required',
                                        validate: (value: string) => (value.trim()) ? true : 'This field is required'
                                    })}
                                    name='default_for_state'
                                    type='text'
                                    defaultValue={props.targetData.default_value}
                                    className={classNames('form-control', { 'is-invalid': errors.default_for_state })}
                                    placeholder='Enter Default Value'
                                />
                                {errors.default_for_state && (<div className='invalid-feedback'>{errors.default_for_state.message}</div>)}
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={props.openCloseForm}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary">{props.modalTitle.includes('Add') ? 'Add' : 'Update'}</button>
                    </div>
                </fieldset>
            </form>
        </FormModal>
    </>
}

export default StateSpecificDefaultForm