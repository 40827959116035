import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import { addField } from '../../api/FieldActions'
import InputField from '../commonComponent/InputFieldComponent'
import classNames from 'classnames'
import Select from 'react-select'
import { typeList, contactFormTypeList, contactListType, textTransformList, decorateLabelFor } from './TypeList'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import StateSpecificDefaultForm from './StateSpecificDefaultForm'
import StateSpecificDefaultStore from '../../store/StateSpecificDefault'
import { Props, Dictionary, ValueLabel } from '../../store/types.d'
import SingleFieldStore from '../../store/SingleField'
import { initialStateSpecificDefault, initialField, initialConditionalOptions, initialButtons, initialLabelDecoration } from '../../store/InitialValues'
import QueryBuilder, { RuleGroupType } from 'react-querybuilder'
import 'react-querybuilder/dist/query-builder.css'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import Table from '../table/Table'
import { EditConditionalOptions, EditStateSpecificDefaultValueButton, EditStateSpecificDefaultValueButtonForDependable } from '../commonComponent/StateSpecificEditButton'
import ConditionalOptionModel from './ConditionalOptionModel'
import ConditionalOptionStore from '../../store/ConditionalOption'
import ButtonsInFieldStore from '../../store/ButtonInField'
import LabelDecorationStore from '../../store/LabelDecoration'
import ButtonInFieldModel from './ButtonInFieldModel'
import { EditButtonCell, DeleteButtonCell } from '../commonComponent/EditDeleteButtonCellComponent'
import LabelDecorationModel from './LabelDecorationModel'
import { DeleteLabelDecorationCell, EditLabelDecorationCell } from '../commonComponent/EditDeleteLabelDecorationCellComponent'

const AddFieldPage: React.FC<Props> = ({ rootStore }) => {
    const [apiErrorMessage, setApiErrorMessage] = useState('')
    const [isApiError, setIsApiError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [isConditional, setIsConditional] = useState(false)
    const [selectedOptionOfFieldType, setSelectedOptionOfFieldType] = useState({ value: 'text', label: 'text' })
    const [selectedOptionOfTextTransform, setSelectedOptionOfTextTransform] = useState({ value: 'none', label: 'None' })
    const [showStateDefaultForm, setShowStateDefaultForm] = useState(false)

    const [selectedFieldsToResetDefaultAnswer, setSelectedFieldsToResetDefaultAnswer] = useState<Array<ValueLabel>>([])
    const [selectedOptionOfContactFormType, setSelectedOptionOfContactFormType] = useState({ value: 'mini_form', label: 'mini_form' })
    const [selectedContactListType, setSelectedContactListType] = useState({ value: 'ALL', label: 'All' })

    const [isSubField, setIsSubField] = useState(false)
    const [isGlobal, setIsGlobal] = useState(false)
    const [shouldReset, setShouldReset] = useState(false)
    const [isReadOnly, setIsReadOnly] = useState(false)
    const [isHidden, setIsHidden] = useState(false)
    const [isHideFromGroupPreview, setIsHideFromGroupPreview] = useState(false)
    const [isHideFromGroupPreviewIfUnanswered, setIsHideFromGroupPreviewIfUnanswered] = useState(false)

    const { setTitle, allStatesStore, allConditionsStore, allFieldsStore, allPackagesStore } = rootStore
    const { stateNamesAndCodesWithValueLabel, stateNamesAndCodesWithAllAsOption } = allStatesStore
    const [stateDefaultValue, setStateDefaultValue] = useState<StateSpecificDefaultStore>()
    const [selectParentField, setSelectParentField] = useState<Partial<{ value: string, label: string }>>()
    const [isDependForStateSpecific, setIsDependForStateSpecific] = useState(false)
    const [isDependableDefaultAnswer, setIsDependableDefaultAnswer] = useState(false)

    const [modalTitle, setModalTitle] = useState('')

    const { fetchField, fieldDetails } = rootStore.fieldStore
    const { fieldsNameLabel, fieldsWithVariableNameAndName, groupFieldsWithVariableNameAndName } = rootStore.allFieldsStore
    const { conditionNameId, conditionNameIdWithNoneAsOption } = rootStore.allConditionsStore
    const { packagesWithValueLabel } = rootStore.allPackagesStore
    const [isDependableAnswer, setIsDependableAnswer] = useState(false)

    const [showConditionalOptionModel, setShowConditionalOptionModel] = useState(false)
    const [isConditionalOption, setIsConditionalOption] = useState(false)
    const [conditionalOptions, setConditionalOptions] = useState<ConditionalOptionStore>()

    const [showInlineDescription, setShowInlineDescription] = useState(false)

    const [isAddButton, setIsAddButton] = useState(false)
    const [buttonsInPage, setButtonsInPage] = useState<ButtonsInFieldStore>()
    const [showButtonModel, setShowButtonModel] = useState(false)

    const [isDecorateLabel, setIsDecorateLabel] = useState(false)
    const [labelDecoration, setLabelDecoration] = useState<LabelDecorationStore>()
    const [showLabelDecorationModel, setShowLabelDecorationModel] = useState(false)

    const [needGrantorAsOption, setNeedGrantorAsOption] = useState(false)
    const [needSpouseAsOption, setNeedSpouseAsOption] = useState(true)
    const { register, handleSubmit, errors, control } = useForm()
    const history = useHistory()

    const [query, setQuery] = useState<RuleGroupType>()

    const handleClick = () => {
        history.push('/fields/')
    }

    useEffect(() => {
        fieldDetails.set(new SingleFieldStore(initialField))
    }, [fieldDetails])

    const openCloseAddStateDefault = () => {
        setShowStateDefaultForm(!showStateDefaultForm)
    }

    const openCloseAddConditionalOptionModel = () => {
        setShowConditionalOptionModel(!showConditionalOptionModel)
    }

    const openCloseButtonModal = () => {
        setShowButtonModel(!showButtonModel)
    }

    const openCloseLabelDecorationModal = () => {
        setShowLabelDecorationModel(!showLabelDecorationModel)
    }

    const onActionClicked = (action: string, data: any, isDepend: boolean) => {
        setIsDependForStateSpecific(isDepend)
        if (action === 'addStateSpecificDefault') {

            setStateDefaultValue(new StateSpecificDefaultStore(initialStateSpecificDefault))
            setModalTitle('Add Dependable Default Value')
            openCloseAddStateDefault()
        }
        if (action === 'editStateSpecificDefault') {
            setStateDefaultValue(data)
            setModalTitle('Edit Dependable Default Value')
            openCloseAddStateDefault()
        }
        if (action === 'addConditionalOption') {
            setConditionalOptions(new ConditionalOptionStore(initialConditionalOptions))
            setModalTitle('Add Options For Given Condition')
            openCloseAddConditionalOptionModel()
        }
        if (action === 'editConditionalOptions') {
            setConditionalOptions(data)
            setModalTitle('Edit Options For Given Condition')
            openCloseAddConditionalOptionModel()
        }
        if (action === 'addButton') {
            setButtonsInPage(new ButtonsInFieldStore(initialButtons))
            setModalTitle('Add button')
            openCloseButtonModal()
        }
        if (action === 'editButtonInField') {
            setButtonsInPage(data)
            setModalTitle('Edit button')
            openCloseButtonModal()
        }
        if (action === 'deleteButtonInField') {
            fieldDetails.deleteButton(data)
        }

        if (action === 'addLabelDecoration') {
            setLabelDecoration(new LabelDecorationStore(initialLabelDecoration))
            setModalTitle('Add Label Decoration')
            openCloseLabelDecorationModal()
        }
        if (action === 'editLabelDecoration') {
            setLabelDecoration(data)
            setModalTitle('Edit Label Decoration')
            openCloseLabelDecorationModal()
        }
        if (action === 'deleteLabelDecoration') {
            fieldDetails.deleteLabelDecoration(data)
        }
    }

    const option = typeList.map((list: any) => {
        const data = {
            value: list || '',
            label: list || ''
        }
        return data
    })

    const optionForContactFormType = contactFormTypeList.map((list: any) => {
        const data = {
            value: list || '',
            label: list || ''
        }
        return data
    })

    const handleOnFieldTypeChange = (data: any) => {
        if (data !== null) {
            setSelectedOptionOfFieldType(data)
        }
    }

    const handleOnTextTransformChange = (data: any) => {
        if (data !== null) {
            setSelectedOptionOfTextTransform(data)
        }
    }

    const handleOnContactFormTypeChange = (data: any) => {
        if (data !== null) {
            setSelectedOptionOfContactFormType(data)
        }
    }

    const handleOnContactListTypeChange = (data: any) => {
        if (data !== null) {
            setSelectedContactListType(data)
        }
    }

    const optionForDecorationScope = decorateLabelFor.map((list: any) => {
        const data = {
            value: list || '',
            label: list || ''
        }
        return data
    })

    const onSubmit = (data: Dictionary<string>) => {
        let optionString = data.options_string
        let isMultiple = data.is_multiple
        let useForNonTrustees = data.use_for_non_trustees
        let contactFormType = selectedOptionOfContactFormType.value
        let isHideFromGroupPreview = data.is_hide_from_group_preview
        let isHideFromGroupPreviewIfUnanswered = data.is_hide_from_group_preview_if_unanswered
        let needGrantorAsOption = data.need_grantor_as_option
        let needSpouseAsOption = data.need_spouse_as_option
        let textTransform = selectedOptionOfTextTransform.value
        let shouldResetFollowingFieldsAnswers = (shouldReset) ? selectedFieldsToResetDefaultAnswer.map((item) => item.value) : []

        if (selectedOptionOfFieldType.value !== 'drop_down' && selectedOptionOfFieldType.value !== 'radio' && selectedOptionOfFieldType.value !== 'group' && selectedOptionOfFieldType.value !== 'contact_list' && selectedOptionOfFieldType.value !== 'contact_list_with_trust_name' && selectedOptionOfFieldType.value !== 'children_list') {
            optionString = ''
            isMultiple = false
        }
        if (selectedOptionOfFieldType.value !== 'contact_connector') {
            useForNonTrustees = false
        }
        if (selectedOptionOfFieldType.value !== 'contact_list' && selectedOptionOfFieldType.value !== 'contact_list_with_trust_name' && selectedOptionOfFieldType.value !== 'children_list' && selectedOptionOfFieldType.value !== 'contact_connector' && selectedOptionOfFieldType.value !== 'and_then_connector') {
            contactFormType = ''
            needGrantorAsOption = false
            needSpouseAsOption = true
        }

        if (selectedOptionOfFieldType.value !== 'text' && selectedOptionOfFieldType.value !== 'textview') {
            textTransform = 'none'
        }

        if (selectedOptionOfFieldType.value !== 'drop_down') {
            setShouldReset(false)
            shouldResetFollowingFieldsAnswers = []
        }

        let parentField = ''
        if (data.is_sub_field === true) {
            parentField = selectParentField?.value || ''
        } else {
            isHideFromGroupPreview = false
        }

        if (isHideFromGroupPreview === true || data.is_sub_field === false) {
            isHideFromGroupPreviewIfUnanswered = false
        }

        if(fieldDetails.state_specific_default.length == 0) {
            setIsDependableDefaultAnswer(false)
        }
        setIsLoading(true)
        const requestData = {
            name: data.name,
            type: selectedOptionOfFieldType.value,
            need_grantor_as_option: needGrantorAsOption,
            need_spouse_as_option: needSpouseAsOption,
            variable_name: data.variable_name,
            placeholder: data.placeholder,
            default_value: data.default_value,
            is_conditional: isConditional,
            description: data.description,
            is_dependable_default_answer: isDependableDefaultAnswer,
            state_specific_default: fieldDetails.state_specific_default,
            conditions: query,
            options_string: optionString,
            is_multiple: isMultiple,
            is_sub_field: isSubField,
            parent_field: parentField,
            is_hide_from_group_preview: isHideFromGroupPreview,
            is_hide_from_group_preview_if_unanswered: isHideFromGroupPreviewIfUnanswered,
            is_global: isGlobal,
            is_read_only: isReadOnly,
            text_transform: textTransform,
            is_hidden: isHidden,
            is_dependable_answer: isDependableAnswer,
            dependable_answer_condition_id: (isDependableAnswer) ? data.dependable_answer_condition_id.value : undefined,
            default_answer_for_dependable: data.default_answer_for_dependable,
            default_answer_for_dependable_state_wise: fieldDetails.default_answer_for_dependable_state_wise,
            is_conditional_options: isConditionalOption,
            conditional_options: fieldDetails.conditional_options,
            show_inline_description: showInlineDescription,
            contact_form_type: contactFormType,
            contact_list_type: selectedContactListType.value,
            is_add_button: isAddButton,
            buttons: fieldDetails.buttons,
            is_decorate_label: isDecorateLabel,
            label_decorations: fieldDetails.label_decorations,
            use_for_non_trustees: useForNonTrustees,
            should_reset: shouldReset,
            should_reset_following_fields_answers: shouldResetFollowingFieldsAnswers,
            is_answer_required: data.is_answer_required
        }
        addField(requestData).then((response) => {
            setIsLoading(false)
            if (response.data.success === 0) {
                setIsApiError(true)
                setApiErrorMessage(response.data.message)
            } else {
                history.push('/fields/')
                fetchField(1, '', 'add', 'All', false, false, false, false, 'All', 'All')
            }
        }).catch((e) => {
            if (e.request.status === 401) {
                rootStore.authStore.setAuthToken('')
            }
            setIsLoading(false)
            setIsApiError(true)
            setApiErrorMessage(e.message)
        })
    }

    const buildClasses = () => {
        return {
            combinators: 'form-select form-select-sm', // <select> control for combinators
            addRule: 'btn btn-primary btn-sm', // <button> to add a Rule
            fields: 'form-select form-select-sm', // <select> control for fields
            operators: 'form-select form-select-sm', // <select> control for operators
            value: 'form-control form-control-sm', // <input> for the field value
            removeRule: 'btn btn-danger btn-sm', // <button> to remove a Rule
            addGroup: 'btn btn-primary btn-sm',
            removeGroup: 'ml-auto btn btn-danger btn-sm'
        }
    }

    const handleAllowAddConditional = (e: any) => {
        const value = e.target.checked
        setIsConditional(value)
    }

    const handleIsSubField = (e: any) => {
        const value = e.target.checked
        setIsSubField(value)
    }

    const handleIsAnsDependable = (e: any) => {
        const value = e.target.checked
        setIsDependableAnswer(value)
    }

    const handleIsDependableDefaultAnswer = (e: any) => {
        const value = e.target.checked
        setIsDependableDefaultAnswer(value)
    }

    const handleIsConditionalOption = (e: any) => {
        const value = e.target.checked
        setIsConditionalOption(value)
    }

    const handleChangeParentField = (data: any) => {
        if (data !== null) {
            setSelectParentField(data)
        }
    }

    const handleIsGlobal = (e: any) => {
        const value = e.target.checked
        setIsGlobal(value)
    }

    const handleIsReadOnly = (e: any) => {
        const value = e.target.checked
        setIsReadOnly(value)
    }

    const handleIsHidden = (e: any) => {
        const value = e.target.checked
        setIsHidden(value)
    }

    const handleIsHideFromGroupPreview = (e: any) => {
        const value = e.target.checked
        setIsHideFromGroupPreview(value)
    }

    const handleNeedGrantorAsOption = (e: any) => {
        const value = e.target.checked
        setNeedGrantorAsOption(value)
    }

    const handleNeedSpouseAsOption = (e: any) => {
        const value = e.target.checked
        setNeedSpouseAsOption(value)
    }
    const handleIsHideFromGroupPreviewIfUnanswered = (e: any) => {
        const value = e.target.checked
        setIsHideFromGroupPreviewIfUnanswered(value)
    }

    const handleShowInlineDescription = (e: any) => {
        const value = e.target.checked
        setShowInlineDescription(value)
    }

    const handleIsAddButton = (e: any) => {
        const value = e.target.checked
        setIsAddButton(value)
    }

    const handleIsDecorateLabel = (e: any) => {
        const value = e.target.checked
        setIsDecorateLabel(value)
    }

    const handleShouldReset = (e: any) => {
        const value = e.target.checked
        setShouldReset(value)
    }

    const handleOnShouldResetFieldsChange = (data: any) => {
        if (data !== null) {
            setSelectedFieldsToResetDefaultAnswer(data)
        }
    }

    useEffect(() => {
        setTitle('Field | Add')
        allFieldsStore.getAllFieldsName()
        allStatesStore.getAllStates()
        allConditionsStore.getAllConditionsNameId()
        allPackagesStore.getAllPackageNames()
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Fields', to: '/fields/' },
            { name: 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                Add Field
            </div>
            <div className="card-body">

                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        <form onSubmit={handleSubmit(onSubmit)} >
                            <fieldset>

                                <div className='form-group'>
                                    <label>Interview Answer Field Type *</label>
                                    <ReactSelectComponent
                                        options={option}
                                        value={selectedOptionOfFieldType}
                                        handleOnChangeReact={handleOnFieldTypeChange}
                                        helpText='Type will be used to show the field form to get answer from the iPad app.'
                                    />
                                </div>
                                <div>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={false}
                                            ref={register}
                                            className="custom-control-input" name='is_answer_required' id="isAnswerRequired" />
                                        <label className="custom-control-label" htmlFor='isAnswerRequired'>Is Answer Required</label>
                                        {errors.is_answer_required ? (<div className='invalid-feedback'>{errors.is_answer_required.message}</div>) : <small className='form-text text-muted switch-help-text'>iPad and CMS will highlight the interview question and docs if answer is not entered.</small>}
                                    </div>
                                </div>
                                <div className={classNames({ 'd-none': (selectedOptionOfFieldType.value !== 'contact_connector') })}>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={false}
                                            ref={register}
                                            className="custom-control-input" name='use_for_non_trustees' id="useForNonTrustees" />
                                        <label className="custom-control-label" htmlFor='useForNonTrustees'>Use for Non Trustees</label>
                                        {errors.use_for_non_trustees ? (<div className='invalid-feedback'>{errors.use_for_non_trustees.message}</div>) : <small className='form-text text-muted mb-3 switch-help-text'>It is used to change the options of connector as trustees or non trustees.</small>}
                                    </div>
                                </div>
                                <div className={classNames({ 'd-none': (selectedOptionOfFieldType.value !== 'contact_list' && selectedOptionOfFieldType.value !== 'contact_list_with_trust_name' && selectedOptionOfFieldType.value !== 'contact_connector' && selectedOptionOfFieldType.value !== 'and_then_connector' && selectedOptionOfFieldType.value !== 'children_list') })}>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={needGrantorAsOption}
                                            ref={register}
                                            className="custom-control-input" name='need_grantor_as_option' id="needGrantorAsOption" onClick={handleNeedGrantorAsOption} />
                                        <label className="custom-control-label" htmlFor='needGrantorAsOption'>Need Grantor as Option</label>
                                        {errors.need_grantor_as_option ? (<div className='invalid-feedback'>{errors.need_grantor_as_option.message}</div>) : <small className='form-text text-muted switch-help-text'>It is used to hide/show the Grantor from the list of related.</small>}
                                    </div>

                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={needSpouseAsOption}
                                            ref={register}
                                            className="custom-control-input" name='need_spouse_as_option' id="needSpouseAsOption" onClick={handleNeedSpouseAsOption} />
                                        <label className="custom-control-label" htmlFor='needSpouseAsOption'>Need Spouse as Option</label>
                                        {errors.need_spouse_as_option ? (<div className='invalid-feedback'>{errors.need_spouse_as_option.message}</div>) : <small className='form-text text-muted switch-help-text'>It is used to hide/show the Spouse from the list of related.</small>}
                                    </div>

                                    <div className='form-group'>
                                        <label>Contact Form Type</label>
                                        <ReactSelectComponent
                                            options={optionForContactFormType}
                                            value={selectedOptionOfContactFormType}
                                            handleOnChangeReact={handleOnContactFormTypeChange}
                                            helpText='Contact Form Type will be used to show the contact form in the iPad app.'
                                        />
                                    </div>
                                </div>

                                <div className={classNames({ 'd-none': (selectedOptionOfFieldType.value !== 'contact_list' && selectedOptionOfFieldType.value !== 'contact_list_with_trust_name' && selectedOptionOfFieldType.value !== 'contact_connector' && selectedOptionOfFieldType.value !== 'and_then_connector') })}>
                                    <div className='form-group'>
                                        <label>Contact List Type</label>
                                        <ReactSelectComponent
                                            options={contactListType}
                                            value={selectedContactListType}
                                            handleOnChangeReact={handleOnContactListTypeChange}
                                            helpText='Contact List Type will be used to decide the type of contact which is newly added direct from interview in the iPad app.'
                                        />
                                    </div>
                                </div>

                                <div className={classNames({ 'd-none': (selectedOptionOfFieldType.value !== 'drop_down' && selectedOptionOfFieldType.value !== 'group' && selectedOptionOfFieldType.value !== 'contact_list' && selectedOptionOfFieldType.value !== 'contact_list_with_trust_name' && selectedOptionOfFieldType.value !== 'children_list') })}>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={false}
                                            ref={register}
                                            className="custom-control-input" name='is_multiple' id="isMultiple" />
                                        <label className="custom-control-label" htmlFor='isMultiple'>Multiple selection</label>
                                        {errors.is_multiple ? (<div className='invalid-feedback'>{errors.is_multiple.message}</div>) : <small className='form-text text-muted mb-3 switch-help-text'>It is used to get multiple values.</small>}
                                    </div>
                                </div>

                                <div className={classNames({ 'd-none': (selectedOptionOfFieldType.value !== 'drop_down') })}>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={false}
                                            ref={register}
                                            className="custom-control-input" name='should_reset' id="shouldReset" onChange={handleShouldReset} />
                                        <label className="custom-control-label" htmlFor='shouldReset'>Should Reset fields</label>
                                        {errors.should_reset ? (<div className='invalid-feedback'>{errors.should_reset.message}</div>) : <small className='form-text text-muted mb-3 switch-help-text'>Changing the option of this field will reset the answer in the following fields.</small>}
                                    </div>
                                </div>

                                <div className={classNames({ 'd-none': !shouldReset })}>
                                    <div className='form-group'>
                                        <ReactSelectComponent
                                            isMulti={true}
                                            isClearable={true}
                                            placeholder='Select...'
                                            options={fieldsWithVariableNameAndName}
                                            value={selectedFieldsToResetDefaultAnswer}
                                            handleOnChangeReact={handleOnShouldResetFieldsChange}
                                            helpText=''

                                        />
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <div className={classNames({ 'd-none': (selectedOptionOfFieldType.value !== 'drop_down' && selectedOptionOfFieldType.value !== 'radio') })}>
                                        <label>Add Options*</label>
                                        <textarea
                                            ref={register({
                                                validate: (value) => (value === '' && (selectedOptionOfFieldType.value === 'drop_down' || selectedOptionOfFieldType.value === 'radio')) ? 'This field is required' : true
                                            })}
                                            name='options_string'
                                            className={classNames('form-control', { 'is-invalid': errors.options_string })}
                                            placeholder='Add Options'
                                        />
                                        {errors.options_string ? (<div className='invalid-feedback'>{errors.options_string.message}</div>) : <small className='form-text text-muted'>Add Options is used to drop down and radio type. Format should be &apos;Female | female&apos;. &apos;Female&apos; can be considered as label and &apos;female&apos; can be considered as value.</small>}
                                    </div>
                                </div>

                                <div className={classNames({ 'd-none': (selectedOptionOfFieldType.value !== 'drop_down' && selectedOptionOfFieldType.value !== 'radio') })}>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={false}
                                            ref={register}
                                            className="custom-control-input" name='is_conditional_options' id="isConditionalOption" onClick={handleIsConditionalOption} />
                                        <label className="custom-control-label" htmlFor='isConditionalOption'>Is Conditional Option</label>
                                        {errors.is_conditional_options ? (<div className='invalid-feedback'>{errors.is_conditional_options.message}</div>) : <small className='form-text text-muted switch-help-text'>It is used to add options based on condition.</small>}
                                    </div>
                                </div>

                                <div className={classNames({ 'd-none': !isConditionalOption })}>
                                    <div className='form-group'>
                                        <div>
                                            Conditional Option
                                            <div className='card mt-2'>
                                                <div className='card-body'>
                                                    <div className='float-right mb-4'>
                                                        <button type='button' className='btn btn-sm btn-outline-primary' onClick={() => {
                                                            onActionClicked('addConditionalOption', conditionalOptions, true)
                                                        }}> + Add</button>
                                                    </div>
                                                    <Table
                                                        unique_key='label'
                                                        columns={[
                                                            { name: 'condition_name', title: 'Condition' },
                                                            { name: '', title: 'Action', component: EditConditionalOptions }
                                                        ]}
                                                        onTableAction={onActionClicked}
                                                        data={fieldDetails.conditional_options}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <InputField
                                    label='Interview Field Label *'
                                    placeholder='Enter Interview Field Label'
                                    fieldName='name'
                                    register={register}
                                    errors={errors.name}
                                    required={true}
                                />
                                <hr />
                                <div>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={isDecorateLabel}
                                            ref={register}
                                            className="custom-control-input" name='is_decorate_label' id="isDecorateLabel" onClick={handleIsDecorateLabel} />
                                        <label className="custom-control-label" htmlFor='isDecorateLabel'>Is Decorate Label</label>
                                        {errors.is_decorate_label ? (<div className='invalid-feedback'>{errors.is_decorate_label.message}</div>) : <small className='form-text text-muted switch-help-text'></small>}
                                    </div>
                                </div>
                                <div className={classNames({ 'd-none': !isDecorateLabel })}>
                                    <div className='form-group'>
                                        <div>
                                            Label Decorations
                                            <div className='card mt-2'>
                                                <div className='card-body'>
                                                    <div className='float-right mb-4'>
                                                        <button type='button' className='btn btn-sm btn-outline-primary' onClick={() => {
                                                            onActionClicked('addLabelDecoration', labelDecoration, true)
                                                        }}> + Add</button>
                                                    </div>
                                                    <Table
                                                        unique_key=''
                                                        columns={[
                                                            { name: 'scope', title: 'Scope' },
                                                            { name: 'find', title: 'Find' },
                                                            { name: 'replace', title: 'Replace' },
                                                            { name: '', title: '', component: EditLabelDecorationCell },
                                                            { name: '', title: '', component: DeleteLabelDecorationCell }
                                                        ]}
                                                        onTableAction={onActionClicked}
                                                        data={fieldDetails.label_decorations}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="form-group">
                                    <label>Variable Name *</label>
                                    <input
                                        ref={register({
                                            required: 'This field is required',
                                            pattern: {
                                                value: /^[\w_.]*$/,
                                                message: 'This type of variable name not allow'
                                            }
                                        })}
                                        name='variable_name'
                                        type='text'
                                        className={classNames('form-control', { 'is-invalid': errors.variable_name })}
                                        placeholder='Enter Variable Name'
                                    />
                                    {errors.variable_name ? (<div className='invalid-feedback'>{errors.variable_name.message}</div>) :
                                        <small className='form-text text-muted'>Unique snake case name to identify the field during interview. It is used to recognize the dynamic data in the template and help to set the real values as per answers in the document.</small>}
                                </div>

                                <hr />
                                <InputField
                                    label='Placeholder text for Interview answer'
                                    fieldName='placeholder'
                                    register={register}
                                    errors={errors.placeholder}
                                    helpText='It will be used to show placeholder in the interview screen of iPad app.'
                                />

                                <hr />
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea
                                        ref={register}
                                        name='description'
                                        className={classNames('form-control', { 'is-invalid': errors.description })}
                                        placeholder='Enter Description'
                                    />
                                    {errors.description ? (<div className='invalid-feedback'>{errors.description.message}</div>) : <small className='form-text text-muted'>Optional description / hints / notes of the interview item.</small>}
                                </div>

                                <div>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={showInlineDescription}
                                            ref={register}
                                            className="custom-control-input" name='show_inline_description' id="showInlineDescription" onClick={handleShowInlineDescription} />
                                        <label className="custom-control-label" htmlFor='showInlineDescription'>Show Inline Description</label>
                                        {errors.show_inline_description ? (<div className='invalid-feedback'>{errors.show_inline_description.message}</div>) : <small className='form-text text-muted switch-help-text'>It is used to set the description shows inline in interview for disabled value shown</small>}
                                    </div>
                                </div>

                                <hr />
                                <div>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={isGlobal}
                                            ref={register}
                                            className="custom-control-input" name='is_global' id="isGlobal" onClick={handleIsGlobal} />
                                        <label className="custom-control-label" htmlFor='isGlobal'>Is Global</label>
                                        {errors.is_global ? (<div className='invalid-feedback'>{errors.is_global.message}</div>) : <small className='form-text text-muted switch-help-text'>To indicate common interview item among multiple Documents. It is used to set same answer at all template of matter and this field is added in only one template field list.</small>}
                                    </div>
                                </div>

                                <hr />
                                <div>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={isReadOnly}
                                            ref={register}
                                            className="custom-control-input" name='is_read_only' id="isReadOnly" onClick={handleIsReadOnly} />
                                        <label className="custom-control-label" htmlFor='isReadOnly'>Is Read Only</label>
                                        {errors.is_read_only ? (<div className='invalid-feedback'>{errors.is_read_only.message}</div>) : <small className='form-text text-muted switch-help-text'>It is used to just show the answer.</small>}
                                    </div>
                                </div>

                                <hr />
                                <div>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={isHidden}
                                            ref={register}
                                            className="custom-control-input" name='is_hidden' id="isHidden" onClick={handleIsHidden} />
                                        <label className="custom-control-label" htmlFor='isHidden'>Is Hidden</label>
                                        {errors.is_hidden ? (<div className='invalid-feedback'>{errors.is_hidden.message}</div>) : <small className='form-text text-muted switch-help-text'>To hide the interview item from iPad app users. If it&apos;s set to true ensure to set default answer. If employee wants to change answer then contact admin.</small>}
                                    </div>
                                </div>

                                <hr />
                                <div>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={isSubField}
                                            ref={register}
                                            className="custom-control-input" name='is_sub_field' id="isSubField" onClick={handleIsSubField} />
                                        <label className="custom-control-label" htmlFor='isSubField'>Is Sub Field</label>
                                        {errors.is_sub_field ? (<div className='invalid-feedback'>{errors.is_sub_field.message}</div>) : <small className='form-text text-muted switch-help-text'>It is used to display in it&apos;s parent field.</small>}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className={classNames({ 'd-none': !isSubField })}>
                                        <label>Add Parent Field *</label>
                                        <ReactSelectComponent
                                            options={groupFieldsWithVariableNameAndName}
                                            value={selectParentField}
                                            placeholder='Select...'
                                            handleOnChangeReact={handleChangeParentField}
                                            helpText='It contains group of subfields. Its type must be a group.'
                                        />
                                    </div>
                                </div>

                                <div className={classNames({ 'd-none': (isSubField === false) })}>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={isHideFromGroupPreview}
                                            ref={register}
                                            className="custom-control-input" name='is_hide_from_group_preview' id="isHideFromGroupPreview" onClick={handleIsHideFromGroupPreview} />
                                        <label className="custom-control-label" htmlFor='isHideFromGroupPreview'>Is Hide from Group Preview</label>
                                        {errors.is_hide_from_group_preview ? (<div className='invalid-feedback'>{errors.is_hide_from_group_preview.message}</div>) : <small className='form-text text-muted switch-help-text'>It is used to hide/show the field from the group preview.</small>}
                                    </div>
                                </div>

                                <div className={classNames({ 'd-none': (!isSubField || isHideFromGroupPreview) })}>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={isHideFromGroupPreviewIfUnanswered}
                                            ref={register}
                                            className="custom-control-input" name='is_hide_from_group_preview_if_unanswered' id="isHideFromGroupPreviewIfUnanswered" onClick={handleIsHideFromGroupPreviewIfUnanswered} />
                                        <label className="custom-control-label" htmlFor='isHideFromGroupPreviewIfUnanswered'>Is Hide from Group Preview If Unanswered</label>
                                        {errors.is_hide_from_group_preview_if_unanswered ? (<div className='invalid-feedback'>{errors.is_hide_from_group_preview_if_unanswered.message}</div>) : <small className='form-text text-muted switch-help-text'>It is used to hide/show the field  from the group preview when field is unanswered or answer is no.</small>}
                                    </div>
                                </div>

                                <hr />
                                <div>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={isAddButton}
                                            ref={register}
                                            className="custom-control-input" name='is_add_button' id="isAddButton" onClick={handleIsAddButton} />
                                        <label className="custom-control-label" htmlFor='isAddButton'>Is Add Button</label>
                                        {errors.is_add_button ? (<div className='invalid-feedback'>{errors.is_add_button.message}</div>) : <small className='form-text text-muted switch-help-text'>It is used to display in it&apos;s parent field.</small>}
                                    </div>
                                </div>

                                <div className={classNames({ 'd-none': !isAddButton })}>
                                    <div className='form-group'>
                                        <div>
                                            Buttons
                                            <div className='card mt-2'>

                                                <div className='card-body'>
                                                    <div className='float-right mb-4'>
                                                        <button type='button' className='btn btn-sm btn-outline-primary' onClick={() => {
                                                            onActionClicked('addButton', buttonsInPage, true)
                                                        }}> + Add</button>
                                                    </div>
                                                    <Table
                                                        unique_key='label'
                                                        columns={[
                                                            { name: 'label', title: 'Label' },
                                                            { name: 'action_string', title: 'Action String' },
                                                            { name: '', title: '', component: EditButtonCell },
                                                            { name: '', title: '', component: DeleteButtonCell }
                                                        ]}
                                                        onTableAction={onActionClicked}
                                                        data={fieldDetails.buttons}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className={classNames({ 'd-none': (selectedOptionOfFieldType.value !== 'text' && selectedOptionOfFieldType.value !== 'textview') })}>
                                    <div className='form-group'>
                                        <label>Text Transform *</label>
                                        <ReactSelectComponent
                                            placeholder='Select...'
                                            options={textTransformList}
                                            value={selectedOptionOfTextTransform}
                                            handleOnChangeReact={handleOnTextTransformChange}
                                            helpText='Transform text capitalization'
                                        />
                                    </div>
                                </div>
                                <hr />
                                <InputField
                                    label='Default Answer'
                                    fieldName='default_value'
                                    register={register}
                                    errors={errors.default_value}
                                    helpText='It will be used to show as default answer in the interview screen of iPad app.'
                                />

                                <div className="custom-control form-group mt-1 custom-switch">
                                    <input type="checkbox" defaultChecked={false}
                                        ref={register}
                                        className="custom-control-input" name='is_dependable_default_answer' id="isDependableDefaultAnswer" onClick={handleIsDependableDefaultAnswer} />
                                    <label className="custom-control-label" htmlFor='isDependableDefaultAnswer'>Is Dependable Default Answer</label>
                                    {errors.is_dependable_default_answer ? (<div className='invalid-feedback'>{errors.is_dependable_default_answer.message}</div>) : <small className='form-text text-muted switch-help-text'>It is used to add default value based on condition and state.</small>}
                                </div>

                                <div className={classNames({ 'd-none': !isDependableDefaultAnswer })}>
                                    <div className='form-group'>
                                        <div>
                                            Dependable Default Answer
                                            <div className='card mt-2'>

                                                <div className='card-body'>
                                                    <div className='float-right mb-4'>
                                                        <button type='button' className='btn btn-sm btn-outline-primary' onClick={() => {
                                                            onActionClicked('addStateSpecificDefault', stateDefaultValue, false)
                                                        }}> + Add</button>
                                                    </div>
                                                    <Table
                                                        unique_key=''
                                                        columns={[
                                                            { name: 'label', title: 'State' },
                                                            { name: 'condition_name', title: 'Condition Name' },
                                                            { name: 'default_value', title: 'Default Answer' },
                                                            { name: '', title: 'Action', component: EditStateSpecificDefaultValueButton }
                                                        ]}
                                                        onTableAction={onActionClicked}
                                                        data={fieldDetails.state_specific_default}
                                                    />
                                                </div>
                                            </div>
                                            <small className='form-text text-muted'>It indicates the default answer for the specific state or condition.</small>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={false}
                                            ref={register}
                                            className="custom-control-input" name='is_dependable_answer' id="isDependableAns" onClick={handleIsAnsDependable} />
                                        <label className="custom-control-label" htmlFor='isDependableAns'>Is Dependable Answer</label>
                                        {errors.is_dependable_answer && (<div className='invalid-feedback'>{errors.is_dependable_answer.message}</div>)}
                                    </div>
                                </div>


                                <div className={classNames({ 'd-none': !isDependableAnswer })}>
                                    <div className='form-group'>
                                        <label>Condition for Dependable Answer *</label>
                                        <Controller
                                            control={control}
                                            name="dependable_answer_condition_id"
                                            render={({ onChange, ref, value }) => (
                                                <Select
                                                    isSearchable
                                                    inputRef={ref}
                                                    value={value}
                                                    options={conditionNameId}
                                                    className={(errors.dependable_answer_condition_id) ? 'is-invalid' : ''}
                                                    classNamePrefix="select"
                                                    menuPortalTarget={document.body}
                                                    onChange={val => {
                                                        onChange(val)
                                                    }}
                                                />)}
                                            rules={{
                                                validate: (value) => (value === undefined && isDependableAnswer) ? 'Please select condition' : true
                                            }}
                                        />
                                        {(errors.dependable_answer_condition_id) && (<div className='invalid-feedback'>{errors.dependable_answer_condition_id.message}</div>)}
                                    </div>
                                </div>

                                <div className={classNames({ 'd-none': !isDependableAnswer })}>
                                    <InputField
                                        label='Dependable Answer *'
                                        placeholder='Enter Dependable Answer'
                                        fieldName='default_answer_for_dependable'
                                        register={register}
                                        required={isDependableAnswer}
                                        errors={errors.default_answer_for_dependable}
                                    />
                                </div>

                                <div className={classNames({ 'd-none': !isDependableAnswer })}>
                                    <div className='form-group'>
                                        <div>
                                            State Specific Default Answer For Dependable answers
                                            <div className='card mt-2'>

                                                <div className='card-body'>
                                                    <div className='float-right mb-4'>
                                                        <button type='button' className='btn btn-sm btn-outline-primary' onClick={() => {
                                                            onActionClicked('addStateSpecificDefault', stateDefaultValue, true)
                                                        }}> + Add</button>
                                                    </div>
                                                    <Table
                                                        unique_key='label'
                                                        columns={[
                                                            { name: 'label', title: 'State' },
                                                            { name: 'default_value', title: 'Default Answer' },
                                                            { name: '', title: 'Action', component: EditStateSpecificDefaultValueButtonForDependable }
                                                        ]}
                                                        onTableAction={onActionClicked}
                                                        data={fieldDetails.default_answer_for_dependable_state_wise}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={isConditional}
                                            ref={register}
                                            className="custom-control-input" name='is_conditional' id="isConditional" onClick={handleAllowAddConditional} />
                                        <label className="custom-control-label" htmlFor='isConditional'>Conditions</label>
                                        {errors.is_conditional ? (<div className='invalid-feedback'>{errors.is_conditional.message}</div>) : <small className='form-text text-muted switch-help-text'>It is used to show-hide field based on condition in interview screen.</small>}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className={classNames({ 'd-none': !isConditional })}>
                                        <div className='with-bootstrap'>
                                            <QueryBuilder
                                                fields={fieldsNameLabel}
                                                query={query}
                                                onQueryChange={(q) => setQuery(q)}
                                                controlClassnames={buildClasses()}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group'>
                                    <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                        {isLoading ? 'Loading...' : 'Submit'}
                                    </button>&nbsp;&nbsp;
                                    <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                </div>
                            </fieldset>
                        </form>

                        {showLabelDecorationModel ? <LabelDecorationModel options={optionForDecorationScope} modalTitle={modalTitle} showModel={showLabelDecorationModel} targetData={labelDecoration} fieldStore={fieldDetails} openCloseForm={openCloseLabelDecorationModal} /> : null}

                        {showStateDefaultForm ? <StateSpecificDefaultForm conditionOptions={conditionNameIdWithNoneAsOption} isDependAnswer={isDependForStateSpecific} showForm={showStateDefaultForm} targetData={stateDefaultValue} modalTitle={modalTitle} fieldStore={fieldDetails} openCloseForm={openCloseAddStateDefault} options={isDependForStateSpecific ? stateNamesAndCodesWithValueLabel : stateNamesAndCodesWithAllAsOption} /> : null}

                        {showButtonModel ? <ButtonInFieldModel modalTitle={modalTitle} showModel={showButtonModel} targetData={buttonsInPage} fieldStore={fieldDetails} openCloseForm={openCloseButtonModal} packagesWithValueLabel={packagesWithValueLabel} /> : null}

                        {showConditionalOptionModel ? <ConditionalOptionModel showForm={true} fieldStore={fieldDetails} targetData={conditionalOptions} modalTitle={modalTitle} openCloseForm={openCloseAddConditionalOptionModel} options={conditionNameId} /> : null}

                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddFieldPage)
