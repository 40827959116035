import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const generateToken = (token: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/daylite/connect`
    return axios.post(url, { token: token })
}

export const setEmptyToken = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/daylite/disconnect`
    return axios.post(url)
}

export const getToken = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/tokens/`
    return axios.get(url)
}

export const getDayliteCronData = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/daylite-cron/all`
    return axios.post(url, requestData)
}