import React, { useEffect } from 'react'

interface IsLoading {
  isLoading: boolean
}

export const TableLoader: React.FC<IsLoading> = (isLoading) => {

    useEffect(() => {
        document.body.classList.add('no-scroll')

        return () => {
            document.body.classList.remove('no-scroll')
        }
    }, [])

    return <>{isLoading &&
    <div className='table-loader' >
        <div className="custom-loader">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
            <div className="bar4"></div>
            <div className="bar5"></div>
            <div className="bar6"></div>
            <div className="bar7"></div>
            <div className="bar8"></div>
            <div className="bar9"></div>
            <div className="bar10"></div>
            <div className="bar11"></div>
            <div className="bar12"></div>
        </div>
    </div>
    }</>
}