import Select from 'react-select'
import React from 'react'
import classNames from 'classnames'

interface Props {
  options?: any
  value: any
  validationError?: string
  isMulti?: boolean
  isSearchable?: boolean
  helpText?: any
  isClearable?: boolean
  handleOnChangeReact?: (data: any) => void
  placeholder?: string
  isTag?: boolean
  isOptionDisabled?: (option: any) => boolean
}

const ReactSelectComponent: React.FC<Props> = (props) => {
    const validationError = props.validationError || ''
    const placeholder = props.placeholder || ''
    const searchable = (props.isSearchable !== undefined ? props.isSearchable : true)
    const isClearable = (props.isClearable !== undefined ? props.isClearable : false)
    const isMulti = (props.isMulti !== undefined ? props.isMulti : false)
    const isTag = (props.isTag !== undefined ? props.isTag : false)
    const inputWidth = (isTag ? '160px' : '')
    
    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            borderColor: state.isFocused ?
                '#ddd' : (validationError !== '') ? '#dc3545' : '#ced4da',
            paddingLeft: 2,
            zIndex: '0'
        }),
        container: (base: any) => ({
            ...base,
            width: inputWidth
        })
    }

    return (
        <>
            <div className={classNames({ 'mr-2': isTag }, 'react-select-border')}>
                <Select
                    isMulti={isMulti}
                    isClearable={isClearable}
                    isSearchable={searchable}
                    options={props.options}
                    styles={customStyles}
                    value={props.value}
                    onChange={props.handleOnChangeReact}
                    menuPortalTarget={document.body} 
                    placeholder={placeholder}
                    isOptionDisabled={props.isOptionDisabled}
                />
            </div>
            {validationError !== '' ? <div className='error-message pt-1 text-wrap'>
                {validationError}
            </div> : <small className='form-text text-muted'>{props.helpText}</small>}
        </>
    )
}

export default ReactSelectComponent