import React, { useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { Cell } from '../table/TableCellText.d'
import { TableCellMatterName, TableCellNote, TableCellNoteAddedBy } from './TableCellForMatterModule'
import uuid from 'react-uuid'
interface Props {
    rootStore: RootStore,
}

const TableCellCheckbox: React.FC<Cell> = (props) => {
    const value = props.value
    const defaultValue = !props.data.should_we_need_additional_info
    const onChange = (e) => {
        e.preventDefault()
        if (confirm('Are you sure you collect all the information from client ?')) {
            props.data.needInfoStore.markNeedInfo(value, !props.data.should_we_need_additional_info)
        }
    }

    return (
        <>
            <input type='checkbox' key={uuid()} checked={defaultValue} onChange={onChange} />
        </>
    )
}

const NeedInfoPage: React.FC<Props> = ({ rootStore }) => {
    const { needInfoStore } = rootStore

    const { isApiError, isLoading, apiErrorMessage, needInfoData, fetchNeedInfo, searchItem, tableRows, filterWithNote, page } = needInfoStore
    const [searchItemField, setSearchItemField] = useState(searchItem)
    const [filterWithNoteValue, setFilterWithNoteValue] = useState(filterWithNote)


    const loadPage = (page: number) => {
        fetchNeedInfo(page, searchItem, filterWithNote)
    }

    const handleFilterWithNote = (e: any) => {
        const value = e.target.checked
        setFilterWithNoteValue(value)
        fetchNeedInfo(page, searchItem, value)
    }

    const onChangeSearchKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchItemField(e.target.value)
        fetchNeedInfo(1, e.target.value, filterWithNote)
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Needed Info
                </div>
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-auto ml-3 pt-2 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='filterWithNote' id="noteFilter" onClick={handleFilterWithNote} defaultChecked={filterWithNoteValue} />
                                <label className="custom-control-label pr-2" htmlFor='noteFilter'>Only Matters With Notes</label>
                            </div>
                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={onChangeSearchKeyword} aria-label="Search" />
                            </div>
                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}
                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key={uuid()}
                                columns={[
                                    { name: '_id', title: 'Collect the Information from Client', component: observer(TableCellCheckbox) },
                                    { name: 'modifier_name', title: 'Modifier' },
                                    { name: 'name', title: 'Matter Name', component: TableCellMatterName },
                                    { name: 'signing_date', title: 'Signing Date' },
                                    { name: 'note', title: 'Note', class:'set-note', component: TableCellNote },
                                    { name: 'updatedAt', title: 'Updated At' },
                                    { name: 'note_added_by', title: 'Note Added By', component: TableCellNoteAddedBy }
                                ]}
                                data={tableRows.map((item) => {
                                    return { ...item, needInfoStore }
                                })}
                            ></Table>
                        </div>
                        <Pagination
                            currentPage={needInfoData.currentPage}
                            totalPages={needInfoData.totalPage}
                            totalItems={needInfoData.totalItem}
                            itemsPerPage={needInfoData.itemPerPage}
                            isFiltered={needInfoData.isFiltered}
                            totalAllItems={needInfoData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(NeedInfoPage)
