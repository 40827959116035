import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../../layout/Container'
import { useHistory } from 'react-router-dom'
import InputField from '../../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../../commonComponent/BreadcrumbComponent'
import { Props, Dictionary } from '../../../store/types.d'
import Table from '../../table/Table'
import TextInfoStore from '../../../store/vision/TexInfo'
import { initialSingleMarriedText } from '../../../store/InitialValues'
import { EditLeftTextInfo, EditRightTextInfo } from '../../commonComponent/EditDeleteLabelDecorationCellComponent'
import TextInfoModel from './TextInfoModal'
import ReactEditor from '../../template/ReactEditor'

const AddUpdateEngagementTemplatePage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors } = useForm()

    const { engagementTemplateStore, monacoEditorStore, setTitle } = rootStore
    const { isApiError, isLoading, apiErrorMessage, engagementTemplateDetails, addUpdateEngagementTemplate, resetEngagementTemplateDetails, setEngagementTemplateDetails } = engagementTemplateStore
    const [leftTextInfo, setLeftTextInfo] = useState<TextInfoStore>()
    const [rightTextInfo, setRightTextInfo] = useState<TextInfoStore>()
    const [modalTitle, setModalTitle] = useState('')
    const [showTextInfoModel, setShowTextInfoModel] = useState(false)

    const history = useHistory()
    const pageName = (history.location.pathname.includes('add')) ? 'Add Engagement Template' : 'Update Engagement Template'
    const pathArrayWithEngagementTemplateId = history.location.pathname.split('/')

    const engagementTemplateId = (pageName === 'Update Engagement Template') ? pathArrayWithEngagementTemplateId[3] : ''
    const isUpdatePage = (pageName === 'Update Engagement Template') ? true : false

    useEffect(() => {
        if (pageName === 'Update Engagement Template' && engagementTemplateId !== engagementTemplateStore.engagementTemplateDetails._id) {
            setEngagementTemplateDetails(engagementTemplateId)
        } else if(pageName === 'Add Engagement Template') {
            engagementTemplateStore.resetEngagementTemplateDetails()
            engagementTemplateStore.fetchDefaultEngagementDetails()
        }
        
    }, [engagementTemplateId, engagementTemplateStore, pageName, setEngagementTemplateDetails])

    const openCloseTextInfoModal = () => {
        setShowTextInfoModel(!showTextInfoModel)
    }
    const handleClick = () => {
        resetEngagementTemplateDetails()
        history.push('/engagement-templates/')
    }

    const onSubmit = async (data: Dictionary<string>) => {

        const emailText = {
            single_text: engagementTemplateDetails.email_text.single_text,
            married_text: engagementTemplateDetails.email_text.married_text
        }

        const requestData = {
            _id: engagementTemplateId,
            name: data.name,
            left_text: engagementTemplateDetails.left_text,
            right_text: engagementTemplateDetails.right_text,
            email_text: emailText
        }

        const response = await addUpdateEngagementTemplate(requestData)
        if (response === 'true') {
            history.push('/engagement-templates/')
        }
    }

    const onActionClicked = (action: string, data: any) => {
        if (action === 'addLeftTextInfo') {
            setLeftTextInfo(new TextInfoStore(initialSingleMarriedText))
            setModalTitle('Add Left Text')
            openCloseTextInfoModal()
        }
        if (action === 'editLeftTextInfo') {
            setLeftTextInfo(data)
            setModalTitle('Edit Left Text')
            openCloseTextInfoModal()
        }
        if (action === 'addRightTextInfo') {
            setRightTextInfo(new TextInfoStore(initialSingleMarriedText))
            setModalTitle('Add Right Text')
            openCloseTextInfoModal()
        }
        if (action === 'editRightTextInfo') {
            setRightTextInfo(data)
            setModalTitle('Edit Right Text')
            openCloseTextInfoModal()
        }
    }

    const onEditorSingleStateChange = (data: any) => {
        engagementTemplateDetails.email_text.single_text = data
    }

    const onEditorMarriedStateChange = (data: any) => {
        engagementTemplateDetails.email_text.married_text = data
    }

    useEffect(() => {
        if(pageName === 'Update Engagement Template'){
            if(engagementTemplateDetails._id === engagementTemplateId){
                setTitle(`${engagementTemplateDetails.name} | Vision - Engagement Template`)
            }
        }
        else{
            setTitle('Vision - Engagement Template | Add')
        }
    }, [pageName, engagementTemplateDetails, engagementTemplateId, setTitle])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Engagement Templates', to: '/engagement-templates/' },
            { name: (pageName === 'Update Engagement Template') ? 'Update' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {pageName}
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}
                        {isLoading === false && <form onSubmit={handleSubmit(onSubmit)} >
                            <fieldset>
                                <InputField
                                    label='Name *'
                                    fieldName='name'
                                    register={register}
                                    errors={errors.name}
                                    placeholder='Enter Name'
                                    defaultValue={engagementTemplateDetails.name}
                                    required={true}
                                    disabled={isUpdatePage}
                                />
                                <div className='form-group'>
                                    <div>
                                        Left Text
                                        <div className='card mt-2'>
                                            <div className='card-body'>
                                                <div className='float-right mb-4'>
                                                    <button type='button' className='btn btn-sm btn-outline-primary' onClick={() => {
                                                        onActionClicked('addLeftTextInfo', leftTextInfo)
                                                    }}> + Add</button>
                                                </div>
                                                <div className='table-responsive'>
                                                    <Table
                                                        unique_key='_id'
                                                        columns={[
                                                            { name: 'single_text', title: 'Single Text', class: 'pre-wrap' },
                                                            { name: 'married_text', title: 'Married Text', class: 'pre-wrap' },
                                                            { name: '_id', title: 'Action', component: EditLeftTextInfo },
                                                        ]}
                                                        onTableAction={onActionClicked}
                                                        data={engagementTemplateDetails.left_text}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group'>
                                    <div>
                                        Right Text
                                        <div className='card mt-2'>
                                            <div className='card-body'>
                                                <div className='float-right mb-4'>
                                                    <button type='button' className='btn btn-sm btn-outline-primary' onClick={() => {
                                                        onActionClicked('addRightTextInfo', rightTextInfo)
                                                    }}> + Add</button>
                                                </div>
                                                <div className='table-responsive'>
                                                    <Table
                                                        unique_key='_id'
                                                        columns={[
                                                            { name: 'single_text', title: 'Single Text', class: 'pre-wrap' },
                                                            { name: 'married_text', title: 'Married Text', class: 'pre-wrap' },
                                                            { name: '_id', title: 'Action', component: EditRightTextInfo },
                                                        ]}
                                                        onTableAction={onActionClicked}
                                                        data={engagementTemplateDetails.right_text}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label>Email Text</label>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <ReactEditor store={monacoEditorStore} content={engagementTemplateStore.engagementTemplateDetails.email_text.single_text} label='Single Text' onEditorChange={onEditorSingleStateChange} />
                                        </div>
                                        <div className='card-body'>
                                            <ReactEditor store={monacoEditorStore} content={engagementTemplateStore.engagementTemplateDetails.email_text.married_text} label='Married Text' onEditorChange={onEditorMarriedStateChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>

                                </div>
                                <div className='form-group'>
                                    <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>
                                        {isLoading ? 'Loading...' : engagementTemplateId === '' ? 'Submit' : 'Update'}
                                    </button>&nbsp;&nbsp;
                                    <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                </div>
                            </fieldset>
                        </form>}
                        {showTextInfoModel ? <TextInfoModel modalTitle={modalTitle} showModel={showTextInfoModel} targetData={modalTitle.includes('Left') ? leftTextInfo : rightTextInfo} engagementTemplateStore={engagementTemplateStore} openCloseForm={openCloseTextInfoModal} /> : null}
                    </div>
                </div>
            </div>
        </div>
    </Container >
}

export default observer(AddUpdateEngagementTemplatePage)