import { observable } from 'mobx'
import { DescriptionOptionInfo } from '../types.d'


class DescriptionInfoStore implements DescriptionOptionInfo {
    @observable public type: string
    @observable public text: string
    @observable public image: string

    constructor(description_info: DescriptionOptionInfo) {
        this.type = ''
        this.text = ''
        this.image = ''
        this.set(description_info)
    }

    set(description_info: DescriptionOptionInfo): void {
        this.type = description_info.type
        this.text = description_info.text
        this.image = description_info.image
    }

    get(): DescriptionOptionInfo {
        return {
            type: this.type,
            text: this.text,
            image: this.image
        }
    }
}

export default DescriptionInfoStore