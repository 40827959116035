import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Props, ValueLabel } from '../../store/types.d'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import { makeValueLabelList } from '../commonComponent/commonFuction'

const AddUpdatePluralPage: React.FC<Props> = ({ rootStore }) => {
    const { handleSubmit } = useForm()

    const { stateSpecificHCTempStore, allStatesStore, setTitle } = rootStore
    const { statesWithValueLabel } = allStatesStore
    const { isApiError, isLoading, apiErrorMessage, stateSpecificHCTempDetails, setStateSpecificHCTempDetails, addUpdateSingleStateSpecificHCTemp, resetStateSpecificHCTempDetails, setError, templateListForEmail } = stateSpecificHCTempStore

    const templateValueLabel = templateListForEmail.map((template) => {
        return {
            value: template._id,
            label: template.name
        }
    })

    const [selectedState, setSelectState] = useState<ValueLabel>()
    
    const [selectedTemplate, setSelectTemplate] = useState<Array<ValueLabel>>()

    const history = useHistory()
    const pageName = (history.location.pathname.includes('add')) ? 'Add State with HC Template' : 'Update State with HC Template'
    const pathArrayWithStateId = history.location.pathname.split('/')
 
    

    const stateSpecificHCTempInfoId = (pageName === 'Update State with HC Template') ? pathArrayWithStateId[3] : ''

    useEffect(() => {
        if(pageName === 'Update State with HC Template'){
            if(stateSpecificHCTempDetails._id === stateSpecificHCTempInfoId){
                setTitle('State Specific HealthCare Template | Update')
            }
        }
        else{
            setTitle('State Specific HealthCare Template | Add')
        }
        if (pageName === 'Update State with HC Template' && (stateSpecificHCTempDetails._id === '' || stateSpecificHCTempInfoId !== stateSpecificHCTempDetails._id)) {
            setStateSpecificHCTempDetails(stateSpecificHCTempInfoId)
        }
        if(stateSpecificHCTempDetails._id !== '' && pageName === 'Update State with HC Template') {
            const defaultValueState = statesWithValueLabel.find(item => item.value === stateSpecificHCTempDetails.state_id)
            if (defaultValueState !== undefined) {
                setSelectState(defaultValueState)
            }
            const selected = makeValueLabelList(stateSpecificHCTempDetails.template_id, templateValueLabel)
            // const defaultValueTemplate = templateValueLabel.find(item => item.value === stateSpecificHCTempDetails.template_id)
            if (selected !== undefined && selected.length !== 0) {

                setSelectTemplate(selected)
            }
        }
    }, [stateSpecificHCTempDetails])

    const handleClick = () => {
        resetStateSpecificHCTempDetails()
        history.push('/state-specific-hc-info/')
    }


    const onSubmit = async () => {

        if(selectedState === null || selectedState === undefined) {
            setError('Please Select State')
            return 
        }

        if(selectedTemplate === undefined || selectedTemplate.length === 0) {
            setError('Please Select Template')
            return 
        }

        const tempIds = selectedTemplate.map((item) => item.value)
        const requestData = {
            _id: stateSpecificHCTempInfoId,
            state_id: selectedState.value,
            template_id: tempIds
        }

        const response = await addUpdateSingleStateSpecificHCTemp(requestData)
        if (response === 'true') {
            history.push('/state-specific-hc-info/')
        }
    }

    const handleOnState = (data: ValueLabel) => {
        setSelectState(data)
    }

    const handleOnTemplate = (data: ValueLabel) => {
        setSelectTemplate(data)
    }

    useEffect(() => {
        allStatesStore.getAllStates()
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'State Specific HC Info', to: '/state-specific-hc-info/' },
            { name: (pageName === 'Update State with HC Template') ? 'Update' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {pageName}
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        {(stateSpecificHCTempInfoId === stateSpecificHCTempDetails._id || pageName === 'Add State with HC Template')
                            ? <form onSubmit={handleSubmit(onSubmit)} >
                                <fieldset>
                                    <div className='form-group'>
                                        <label>Select State *</label>
                                        <ReactSelectComponent
                                            options={statesWithValueLabel}
                                            value={selectedState}
                                            handleOnChangeReact={handleOnState}
                                            helpText='State which is contain healthcare document'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>Select Template *</label>
                                        <ReactSelectComponent
                                            isMulti={true}
                                            options={templateValueLabel}
                                            value={selectedTemplate}
                                            handleOnChangeReact={handleOnTemplate}
                                            helpText='Select template which you need to send with N&F of above state'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                            {isLoading ? 'Loading...' : 'Submit'}
                                        </button>&nbsp;&nbsp;
                                        <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                    </div>
                                </fieldset>
                            </form>
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddUpdatePluralPage)