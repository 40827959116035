import React, { useEffect, useState } from 'react'
import RootStore from '../../../store/Root'
import Container from '../../layout/Container'
import { observer } from 'mobx-react'
import Table from '../../table/Table'
import Pagination from '../../table/Pagination'
import { Link } from 'react-router-dom'
import { TableCellAction } from '../../table/TableCellEditButton'
import NavBarWithTags from '../NavbarWithTags'
import { debounceSearch } from '../../../utils/debounce'

interface Props {
  rootStore: RootStore,
}

const EstateProtectPlansPage: React.FC<Props> = ({ rootStore }) => {
    const { estateProtectPlanStore, authStore, setTitle } = rootStore
    const { isAdmin } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, estateProtectPlanData, fetchEstateProtectPlans, isPageOpened } = estateProtectPlanStore
    const [searchItemField, setSearchItemField] = useState(searchItem)

    const loadPage = (page: number) => {
        fetchEstateProtectPlans(page, searchItem, 'change_page')
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchEstateProtectPlans(1, e.target.value.trim(), 'search_item'))
    }

    useEffect(() => {
        setTitle('Vision - Estate Protect Plan | OathZephyr')
        if(isAdmin && !isPageOpened){
            fetchEstateProtectPlans(1, '', '')
        }
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
          Estate Protect Plans
                    <Link to='/estate-protect-plans/add' className='ml-auto px-3'>Add Estate Protect Plan</Link>
                </div>
            </div>
            
            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'plan_name', title: 'Plan Name' },
                                    { name: 'display_order', title: 'Display Order' },
                                    { name: '_id', title: 'Action', component: TableCellAction }
                                ]}
                                data={estateProtectPlanData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={estateProtectPlanData.currentPage}
                            totalPages={estateProtectPlanData.totalPage}
                            totalItems={estateProtectPlanData.totalItem}
                            itemsPerPage={estateProtectPlanData.itemPerPage}
                            isFiltered={estateProtectPlanData.isFiltered}
                            totalAllItems={estateProtectPlanData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(EstateProtectPlansPage)
