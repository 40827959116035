import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import InputField from '../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Props, Dictionary } from '../../store/types.d'
import QueryBuilder, { RuleGroupType } from 'react-querybuilder'
import 'react-querybuilder/dist/query-builder.css'
import classNames from 'classnames'


const AddUpdateConditionPage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors } = useForm()

    const { conditionStore, allFieldsStore, setTitle } = rootStore
    const { isApiError, isLoading, apiErrorMessage, addUpdateSingleCondition, setConditionDetails, conditionDetails, resetConditionDetails } = conditionStore

    const { fieldsNameLabel } = allFieldsStore

    const [query, setQuery] = useState<RuleGroupType>()

    const history = useHistory()
    const pageName = (history.location.pathname.includes('add')) ? 'Add Condition' : 'Update Condition'
    const pathArrayWithConditionId = history.location.pathname.split('/')

    const conditionId = (pageName === 'Update Condition') ? pathArrayWithConditionId[3] : ''

    const buildClasses = () => {
        return {
            combinators: 'form-select form-select-sm', // <select> control for combinators
            addRule: 'btn btn-primary btn-sm', // <button> to add a Rule
            fields: 'form-select form-select-sm', // <select> control for fields
            operators: 'form-select form-select-sm', // <select> control for operators
            value: 'form-control form-control-sm', // <input> for the field value
            removeRule: 'btn btn-danger btn-sm', // <button> to remove a Rule
            addGroup: 'btn btn-primary btn-sm',
            removeGroup: 'ml-auto btn btn-danger btn-sm'
        }
    }

    useEffect(() => {
        if( conditionDetails._id === conditionId){
            if(pageName === 'Update Condition'){
                setTitle(`${conditionDetails.name} | Condition`)
            }
            else{
                setTitle('Condition | Add')
            }
        }
        if (pageName === 'Update Condition' && conditionDetails._id !== conditionId ) {
            setConditionDetails(conditionId)            
        }
        if(conditionDetails._id !== '') {
            setQuery(conditionDetails.conditions)
        }
    }, [setConditionDetails, conditionId, conditionDetails, pageName])

    const handleClick = () => {
        resetConditionDetails()
        history.push('/conditions/')
    }

    const onSubmit = async (data: Dictionary<string>) => {

        const requestData = {
            _id: conditionId,
            name: data.name,
            conditions: query,
            description: data.description
        }

        const response = await addUpdateSingleCondition(requestData)
    
        if (response === 'true') {
            history.push('/conditions/')
        }
    }

    useEffect(() => {
        allFieldsStore.getAllFieldsName()
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Conditions', to: '/conditions/' },
            { name: (pageName === 'Update Condition') ? 'Update' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {pageName}
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        {(conditionId === conditionDetails._id) ?
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <fieldset>

                                    <InputField
                                        label='Name *'
                                        fieldName='name'
                                        register={register}
                                        errors={errors.name}
                                        placeholder='Enter Name'
                                        defaultValue={conditionDetails.name}
                                        required={true}
                                    />

                                    <div className="form-group">

                                        <div className='with-bootstrap'>
                                            <QueryBuilder
                                                fields={fieldsNameLabel}
                                                query={query}
                                                onQueryChange={(q) => setQuery(q)}
                                                controlClassnames={buildClasses()}
                                            />
                                        </div>
                                    </div>


                                    <div className='form-group'>

                                        <label>Description</label>
                                        <textarea
                                            ref={register}
                                            name='description'
                                            defaultValue={conditionDetails.description}
                                            className={classNames('form-control', { 'is-invalid': errors.description })}
                                            placeholder='Add Description'
                                        />
                                        {errors.description && (<div className='invalid-feedback'>{errors.description.message}</div>)}
                                    </div>

                                    <div className='form-group'>
                                        <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                            {isLoading ? 'Loading...' : 'Submit'}
                                        </button>&nbsp;&nbsp;
                                        <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                    </div>
                                </fieldset>
                            </form>
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddUpdateConditionPage)