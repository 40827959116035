import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const getProbables = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/probables/`
    return axios.post(url, requestData)
}

export const getProbableDetail = (probableId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/probables/detail/${probableId}`
    return axios.post(url)
}