import { action, computed, observable, runInAction } from 'mobx'
import RootStore from './Root'
import { getAddressTitleId } from '../api/AddressActions'
import { AddressInformation } from './types.d'

class AllAddressStore {
    public rootStore: RootStore
    @observable public apiErrorMessage = ''
    @observable public isApiError = false
    @observable public isLoading = false
    @observable public isLoaded = false
    @observable public addressTitleId: Array<AddressInformation> = []

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @computed get addressWithValueLabel() {
        const options =  this.addressTitleId.map((list: AddressInformation) => {
            const data = {
                value: list._id || '',
                label: list.title
            }
            return data
        })
        options.unshift({
            value: '',
            label: 'None'
        })
        return options
    }

    @action
    public getAllAddressNames() {
        if(this.isLoaded === false) {
            this.reload()
        }
    }

    @action
    public forceReload() {
        this.reload()
    }

    @action
    public reload() {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getAddressTitleId().then((response) => {
            runInAction(() => {
                this.isLoading = false
                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.addressTitleId = response.data.data
                this.isLoaded = true
            })
        }).catch((error) => {
            runInAction(() => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }
                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
            })
        })
    }
}

export default AllAddressStore
