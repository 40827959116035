export const paymentTypePlan = [
    {
        value: 'one_time_in_full',
        label: 'One time in full'
    }, {
        value: 'half_in_payment',
        label: '50/50'
    }, {
        value: 'six_month_payment',
        label: '6-month payments'
    }, {
        value: 'twelve_month_payment',
        label: '12-month payments'
    }, {
        value: 'twenty_four_month_payment',
        label: '24-month payments'
    }
]

export default paymentTypePlan