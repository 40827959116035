import React from 'react'
import { Cell } from '../table/TableCellText.d'
import {templateTypes} from '../template/TemplateTypes'

export const JointIndividualTypesCell: React.FC<Cell> = (props) => {
    let value = props.value || ''
  
    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
  
    value = templateTypes.find((item) => item.value === value)

    return (
        <>
            {value.label}
        </>
    )
}