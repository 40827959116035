export const typeList = [
    'and_then_connector',
    'children_list',
    'client',
    'contact_connector',
    'contact_list',
    'contact_list_with_trust_name',
    'date',
    'document',
    'drop_down',
    'group',
    'header',
    'matter',
    'number',
    'radio',
    'state',
    'switch',
    'text',
    'textview'
]

export const noteScreenType = [
    {
        value: 'graph_note_screen',
        label: 'Graph Note'
    }, {
        value: 'note_screen',
        label: 'Note'
    }
]

export const contactFormTypeList = [
    'mini_form',
    'full_form'
]

export const textTransformList = [
    {
        value:'none',
        label:'None'
    },
    {
        value:'uppercase',
        label:'UPPERCASE'
    },
    {
        value:'sentence_case',
        label:'Sentence case'
    },
    {
        value:'first_letter',
        label:'First Letter'
    }
]

export const decorateLabelFor = [
    'label',
    'options'
]

export const descriptionInfoType = [
    {
        value: 'TEXT',
        label: 'Text'
    }, {
        value: 'IMAGE',
        label: 'Image'
    }
]

export const imageTypeText = [
    {
        value: 'all_control_diagram',
        label: 'All Control Diagram'
    }, {
        value: 'beneficiary_trusts_diagram',
        label: 'Beneficiary Trusts Diagram'
    }
]

export const questionOptionType = [
    {
        value: 'AMOUNT',
        label: 'Amount'
    }, {
        value: 'CHECKBOX',
        label: 'Checkbox'
    }, {
        value: 'TEXT',
        label: 'Text'
    }
]

export const contactListType = [
    {
        value:'ALL',
        label:'All'
    },
    {
        value:'INDIVIDUAL',
        label:'Individual'
    },
    {
        value:'CHARITY',
        label:'Charity'
    },
    {
        value:'ENTITY',
        label:'Entity'
    }
]

export const optionFiling = [
    {
        value: '',
        label: 'ㅤ'
    }, {
        value: 'e-mail',
        label: 'E-File'
    }, {
        value: 'mail',
        label: 'Mail'
    }, {
        value: 'client_file',
        label: 'Client File'
    }
]

export const optionCompleted = [
    {
        value: 'is_completed',
        label: 'Completed'
    }, {
        value: 'is_not_completed',
        label: 'Not Completed'
    }
]

export const optionSource = [
    {
        value: 'DAYLITE',
        label: 'Daylite'
    }, {
        value: 'LAWMATICS',
        label: 'Lawmatics'
    }, {
        value: 'OATHZEPHYR',
        label: 'Oath Zephyr'
    }
]

export const optionFiled = [
    {
        value: '',
        label: 'ㅤ'
    }, {
        value: 'e-mail',
        label: 'E-File'
    }, {
        value: 'mail',
        label: 'Mail'
    }
]

export default typeList