import styled from 'styled-components'

export const Preview = styled.div`  
  @font-face { font-family: "Open Sans"; font-style: normal; font-weight: 700; src: url('../../fonts/OpenSans-Bold.ttf'); }

  @font-face { font-family: "Lire Baskerville"; font-style: normal; font-weight: 400; src: url('../../fonts/LibreBaskerville-Bold.ttf'), url('../../fonts/LibreBaskerville-Italic.ttf'), url('../../fonts/LibreBaskerville-Regular.ttf'); }

  body { margin: 0; padding: 0; font-size: 10.5pt; font-family: "Libre Baskerville"; }

  html { margin-top: 0; margin-left: 0.875in; margin-right: 0.875in; margin-bottom: 0.3125in; padding: 0; }

  /* header styles */
  h1 { font-size: 20pt; font-family: "Open Sans", sans-serif; text-align: center; margin-top: 0; page-break-inside: avoid;}
  h2 { font-size: 18pt; font-family: "Open Sans", sans-serif; text-align: center; margin: 0.473in 0 0.178in 0; page-break-inside: avoid; }
  h3 { font-size: 12pt; font-family: "Open Sans", sans-serif; margin: .0in 0 .15625in 0; page-break-inside: avoid;}
  h4 { font-size: 10.5pt; font-family: "Open Sans", sans-serif; margin: .0in 0 .15625in 0; page-break-inside: avoid;}

  /* Paragraph styles */
  p, ol, ul { font-size: 10.5pt; font-family: "Libre Baskerville"; text-align: justify; margin-bottom: .15625in; }

  ins { text-decoration: underline; }

  /* Table styles */
  tbody { font-family: "Libre Baskerville"; font-size: 10.5pt; }

  /* Text alignments styles */
  .left { text-align: left; }
  .right { text-align: right; }
  .center { text-align: center; }

  /* Font size styles */
  .small-font { font-size: 8pt; }
  .small-font-tab1 { font-size: 8pt; margin-left: 0.5in; }

  .font-10pt { font-size: 10pt; }
  .font-7pt { font-size: 7pt; }
  .font-6pt { font-size: 6pt; }
  .font-9pt { font-size: 9pt; }
  .font-10-5 { font-size: 10.5pt; }
  .top-right-8px { font-size: 8pt; vertical-align: top; text-align: right; }

  /* Font size with alignment styles */
  .font-10pt-center { text-align: center; font-size: 10pt; }
  .font-9pt-center { text-align: center; font-size: 9pt; }
  .font-9pt-left { text-align: left; font-size: 9pt; }
  .font-9pt-right { text-align: right; font-size: 9pt; }
  .font-8pt-left { text-align: left; font-size: 8pt; }
  .font-7pt-left { text-align: left; font-size: 7pt; }
  .font-7pt-center { text-align: center; font-size: 7pt; }

  .footer-p { text-align: left; font-size: 8pt; }

  .small-font-center { text-align: center; font-size: 8pt; }
  .small-font-right { text-align: right; font-size: 8pt; }

  /* Indention styles */
  .tab1 { margin-left: 0.5in; margin-right: 0.5in; }
  .tab2 { margin-left: 1.0in; margin-right: 1.0in; }
  .tab3 { margin-left: 1.5in; margin-right: 1.5in; }
  .tab4 { margin-left: 2.0in; }

  /* Indention with alignments styles */
  .tab1-left { margin-left: 0.5in; text-align: left; }
  .tab2-left { margin-left: 1.0in; text-align: left; }
  .tab3-left { margin-left: 1.5in; text-align: left; }
  .tab4-left { margin-left: 2.0in; text-align: left; }

  .left-page-break-before { text-align: left; page-break-before: always; }

  /* Page break styles */
  .page-break-after { page-break-after: always; }
  .page-break-before { page-break-before: always; }
  .page-break-inside-avoid { page-break-inside: avoid; }
  .right-page-break-inside-avoid { text-align: right; page-break-inside: avoid; }
  .left-page-break-inside-avoid { text-align: left; page-break-inside: avoid; }
  .center-page-break-before { text-align: center; page-break-before: always; }
  .right-page-break-before { text-align: right; page-break-before: always; }

  .small-font-right-page-break-inside-avoid { text-align: right; font-size: 7pt; page-break-inside: avoid; }
  .small-font-page-break-inside-avoid { font-size: 7pt; page-break-inside: avoid; }
  .tab1-page-break-inside-avoid { page-break-inside: avoid; margin-left: 0.5in; margin-right: 0.5in; }

  .hr-black { border-top: 1pt solid black; }
  .hr-black-tab1 { border-top: 1pt solid black; margin-left: 0.5in; }

  .overview-paragraph { margin: 0.625in 0;}

  .color-yellow {background-color: lightyellow;}

  .text-upper {
      text-transform: uppercase;
  }

  .unanswered-text {
    color: red;
  }

  MatterCounty {
    background-color: #f1cbab;
  }

  SigningDate {
    background-color: #f1cbab;
  }

  .WAA-table-font {
    font-size: 8pt;
    text-align: justify;
    margin: 0.15625in  0;
  }

  .WAA-table-font td {
    padding-bottom: 12px;
  }

  .WAA-text-align-top {
    vertical-align: top;
    font-weight: bold;
  }

  .text-center-top {
    text-align: center;
    vertical-align: top;
    
  }

  .WAA-center-bottom {
    text-align: center;
    vertical-align: top;
    font-size: 9pt;
  }

  .fees-table {
    font-size: 8pt;
    border: black solid 1px;
  }

  .fees-table th {
    border: black solid 1px;
    margin: 0;
    padding: 0;
    background-color: #d5d8db;
    padding-top:2px;
  }

  .fees-table td {
    border: black solid 1px;
    margin: 0;
    padding: 0;
    padding-top:2px;
  }

  .fees-tables {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }

  .rights-font {
    font-size: 8pt;
  }

  .rights-li {
    margin-bottom: 12px;
  }

  .square {
    border: black solid 1px;
    padding: 4px;
    font-size: 3pt;
    vertical-align: baseline;
    display:inline-block;
    position: relative;
  }

  .square.checked:after {
    content: '✔';
    display: inline-block;
    position: absolute;
    font-size: 10pt;
    top: -5px;
    left: 0;
  }

  /* title page css */
  .main-title {
      font-size: 40px;
      font-family: "Open Sans", sans-serif;
      text-align: left;
      margin-top: 0;
      page-break-inside: avoid;
      font-weight: bold;
  }
  .main-page-footer {
      color: #C9C9C9 !important;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: bold;
  }
  .img-title {
      width: 70px;
      height: 18px;
  }

  .title-page-height {
    height: 674px; 
    min-height:  674px;
    max-height:  674px;
    box-sizing: border-box;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: justify;
    flex-direction:column; 
    -webkit-flex-direction: column;
    justify-content:space-between;
    -webkit-justify-content: space-between;
    page-break-inside: avoid;
  }
  
  EndOfSection {
    border-radius: 50%;
    background-color: #ad7240;
    position: absolute;
    padding: 6px;
    margin-left: 9px;
    margin-top: 2px;
    height: 1px;
  }

`
