import React from 'react'
import PropTypes from 'prop-types'
import { Cell } from '../table/TableCellText.d'


const TableCellTo: React.FC<Cell> = (props) => {
    let value = props.value || []

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
    return (
        <>
            <div>
                To: {value}
            </div>
            {props.data.bcc !== undefined && props.data.bcc.length !== 0 && <div>
                Bcc: {props.data.bcc}
            </div>}
            {props.data.cc !== undefined && props.data.cc.length !== 0 && <div>
                Cc: {props.data.cc}
            </div>}
        </>
    )
}

TableCellTo.propTypes = {
    value: PropTypes.any.isRequired
}

export default TableCellTo