import { action, observable } from 'mobx'
import RootStore from '../Root'
import { ValueLabel } from '../types.d'
import { getEngagementTemplateNameId } from '../../api/vision/EngagementTemplateActions'

class AllEngagementTemplateStore {
    public rootStore: RootStore
    @observable public isLoading = false
    @observable public isLoaded = false
    @observable public engagementTemplatesWithValueLabel: Array<ValueLabel> = []

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action
    public getAllEngagementTemplateNameIDs() {
        if (this.isLoaded === false) {
            this.reload()
        }
    }

    @action
    public forceReload() {
        this.reload()
    }

    @action
    public reload() {
        this.isLoading = true

        getEngagementTemplateNameId().then((response) => {
            this.engagementTemplatesWithValueLabel = response.data.data.map((list: any) => {
                const data = {
                    value: list._id,
                    label: list.name
                }
                return data
            })
            this.isLoaded = true
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
        }).finally(() => {
            this.isLoading = false
        })

    }
}

export default AllEngagementTemplateStore
