import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../../store/types.d'

export const addUpdatePlan = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/plans/add-update`
    return axios.post(url, requestData)
}

export const getPlans = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/plans/`
    return axios.post(url, requestData)
}

export const getSinglePlanDetails = (planId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/plans/plan/${planId}`
    return axios.post(url)
}

export const getPlanNameId = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/plans/all`
    return axios.post(url)
}

export const getPlanPricingForDefault = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/plans/name-with-prices`
    return axios.post(url)
}

export const addUpdatePlanPricingTemplate = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/plan-pricing-template/create-or-update`
    return axios.post(url, requestData)
}

export const getPlanPricingTemplates = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/plans-pricing-templates/`
    return axios.post(url, requestData)
}

export const getPlanPricingTemplateNameId = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/plans-pricing-templates/all`
    return axios.post(url)
}
export const getSinglePlanPricingTemplateDetails = (planId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/plans-pricing-templates/plans-pricing-template/${planId}`
    return axios.post(url)
}
