import React, { useEffect, useState } from 'react'
import FormModal from '../../commonComponent/FormModal'
import { Controller, useForm } from 'react-hook-form'
import classNames from 'classnames'
import Select from 'react-select'
import ProblemQuestionStore from '../../../store/vision/ProblemQuestion'
import { ValueLabel } from '../../../store/types.d'
import { makeValueLabelList } from '../../commonComponent/commonFuction'

interface Props {
	targetData?: any
	showModel: boolean
	modalTitle: string
	problemQuestionStore: ProblemQuestionStore
    typeOptions: Array<ValueLabel>
    planOptions: Array<ValueLabel>
	openCloseForm: () => void
}

const OptionInfoModel: React.FC<Props> = (props) => {
    const [ type, setType ] = useState({ value: 'CHECKBOX', label: 'Checkbox'})
    const [ plan, setPlan ] = useState<Array<ValueLabel>>([])
    const [ isActive, setIsActive ] = useState('true')
    useEffect(() => {
        const selectedType = props.typeOptions.find((item: ValueLabel) => props.targetData.type === item.value)
        const selectedPlan = makeValueLabelList(props.targetData.plan, props.planOptions)
        
        if (props.modalTitle.includes('Edit') && selectedType != undefined) {
            const activeStatus = (props.targetData.is_active ? 'true' : 'false')
            setType(selectedType)
            setPlan(selectedPlan)
            setIsActive(activeStatus)
        }
    
    }, [props.targetData, props.typeOptions, props.planOptions, props.modalTitle ])

    const scrollableBodyStyle = 'modal-body resource'
    const { register, handleSubmit, errors, control } = useForm()
    
    const typeOptions = props.typeOptions

    const onSaveModal = (data: any) => {
        const activeStatus = (data.is_active === 'true' ? true : false)

        const planValue = plan.map((item) => item.value)
        if (props.modalTitle.includes('Add')) {
            props.problemQuestionStore.addNewOptionInfo(data.label.trim(), type.value, data.placeholder.trim(), data.display_order, planValue, activeStatus)
        } else {

            props.targetData.label = data.label
            props.targetData.type = type.value
            props.targetData.placeholder = data.placeholder
            props.targetData.display_order = data.display_order
            props.targetData.plan = planValue
            props.targetData.is_active = activeStatus
        }
        props.openCloseForm()
    }

    const handleOnChange = (val: ValueLabel) => {
        setType(val)
    }

    const handleOnChangePlan = (val: Array<ValueLabel>) => {
        setPlan(val)
    }

    return <>
        <FormModal show={props.showModel} modalTitle={props.modalTitle} openCloseModal={props.openCloseForm}>

            <form onSubmit={handleSubmit(onSaveModal)} >
                <fieldset>
                    <div className={scrollableBodyStyle} >
                        <div className='ml-2'>
                            <div className='form-group'>
                                <label>Label *</label>
                                <input
                                    ref={register({
                                        required:'This field is required'
                                    })}
                                    name='label'
                                    type='text'
                                    defaultValue={props.targetData.label}
                                    className={classNames('form-control', { 'is-invalid': errors.label })}
                                    placeholder='Enter Label'
                                />
                                {errors.label && (<div className='invalid-feedback'>{errors.label.message}</div>)}
                            </div>
                            <div className='form-group'>
                                <label>Select Option Type</label>
                                <Controller
                                    control={control}
                                    name="type"
                                    defaultValue={type}
                                    render={({ ref }) => (
                                        <Select
                                            inputRef={ref}
                                            value={type}
                                            options={typeOptions}
                                            className={(errors.type) ? 'is-invalid' : ''}
                                            classNamePrefix="type"
                                            onChange={(val: any) => {
                                                handleOnChange(val)
                                            }}
                                        />)}
                                    rules={{
                                        validate: (value) => (value === undefined) ? 'Please select type' : true
                                    }}
                                />
                                {(errors.option) && (<div className='invalid-feedback'>{errors.option.message}</div>)}
                            </div>
                            <div className='form-group'>
                                <label>Placeholder</label>
                                <input
                                    ref={register}
                                    name='placeholder'
                                    type='text'
                                    defaultValue={props.targetData.placeholder}
                                    className={classNames('form-control', { 'is-invalid': errors.placeholder })}
                                    placeholder='Enter Placeholder'
                                />
                                {errors.placeholder && (<div className='invalid-feedback'>{errors.placeholder.message}</div>)}
                            </div>

                            <div className="form-group">
                                <label>Display Order</label>
                                <input
                                    ref={register}
                                    name='display_order'
                                    type='number'
                                    min={1}
                                    className={classNames('form-control', { 'is-invalid': errors.display_order })}
                                    defaultValue={props.targetData.display_order}
                                />
                                {errors.display_order && (<div className='invalid-feedback'>{errors.display_order.message}</div>)}
                            </div>

                            <div className='form-group'>
                                <label>Select Plan</label>
                                <Controller
                                    control={control}
                                    name="plan"
                                    defaultValue={plan}
                                    render={({ ref }) => (
                                        <Select
                                            isMulti
                                            inputRef={ref}
                                            value={plan}
                                            options={props.planOptions}
                                            className={(errors.plan) ? 'is-invalid' : ''}
                                            classNamePrefix="plan"
                                            onChange={(val: any) => {
                                                handleOnChangePlan(val)
                                            }}
                                        />)}
                                />
                                {(errors.plan) && (<div className='invalid-feedback'>{errors.plan.message}</div>)}
                            </div>
                            <div className='form-group'>
                                <label>Active Status</label>
                                <div className='radio' >
                                    <label htmlFor='true'> <input name='is_active' type='radio' id='true' checked={isActive === 'true'} value='true' ref={register} onChange={() => setIsActive('true')} /> Active</label> &nbsp;&nbsp;
                                    <label htmlFor='false'> <input name='is_active' type='radio' id='false' checked={isActive === 'false'} value='false' ref={register} onChange={() => setIsActive('false')}/> Inactive</label>
                                </div>
                                {errors.is_active && (<div className='invalid-feedback'>{errors.is_active.message}</div>)}
                            </div>
                            
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={props.openCloseForm}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary">{props.modalTitle.includes('Add') ? 'Add' : 'Update'}</button>
                    </div>
                </fieldset>
            </form>
        </FormModal>
    </>
}

export default OptionInfoModel