import { observable } from 'mobx'
import ArticleStore from './Article'
import ParagraphStore from './Paragraph'
import { Article, Dictionary, EditedAt, Paragraph, SingleTemplateFrom, SingleTemplateInformation } from './types.d'
import { updateTemplate, updateHTMLDocument, deleteHTMLDocument } from '../api/TemplateActions'
class SingleTemplateStore implements SingleTemplateFrom {
    @observable public _id: string
    @observable public name: string
    @observable public slug: string
    @observable public is_all_states: boolean
    @observable public states: string[]
    @observable public content: Array<ArticleStore | ParagraphStore>
    @observable public isLoading: boolean
    @observable public isApiError: boolean
    @observable public apiErrorMessage: any
    @observable public should_create_document_for_both: boolean
    @observable public enable_multiple_document_mode: boolean
    @observable public footer_text: string
    @observable public isImagesFetched = false

    constructor(template: SingleTemplateInformation) {
        this.set(template)
        this.apiErrorMessage = ''
        this.isLoading = false
        this.isApiError = false
    }

    set(template: SingleTemplateInformation) {
        this._id = template._id
        this.name = template.name
        this.slug = template.slug
        this.is_all_states = template.is_all_states
        this.states = template.states
        this.should_create_document_for_both = template.should_create_document_for_both
        this.enable_multiple_document_mode = template.enable_multiple_document_mode
        this.footer_text = template.footer_text
        const content: Array<ArticleStore | ParagraphStore> = []
        for (let index = 0; index < template.content.length; index++) {
            const data = template.content[index]

            if (data.type === 'article') {
                const article = new ArticleStore(data)
                content.push(article)
            } else if (data.type === 'paragraph') {
                const paragraph = new ParagraphStore(data)
                content.push(paragraph)
            }

        }
        this.content = content
    }

    get(): SingleTemplateInformation {
        const content: Array<Article | Paragraph> = []

        for (let index = 0; index < this.content.length; index++) {
            const data = this.content[index]
            content.push(data.get())
        }

        return {
            _id: this._id,
            name: this.name,
            slug: this.slug,
            is_all_states: this.is_all_states,
            states: this.states,
            content: content,
            should_create_document_for_both: this.should_create_document_for_both,
            enable_multiple_document_mode: this.enable_multiple_document_mode,
            footer_text: this.footer_text
        }
    }

    saveHTML(matterId: string, isHTMLDocument: string, forSpouse: string, UUID: string, cb: (htmlId: string) => void) {
        // return new Promise((resolve, reject) => {
        this.isLoading = true
        let requestData: Dictionary<string> = {}
        requestData = this.get()
        requestData.matter_id = matterId
        delete requestData.should_create_document_for_both
        delete requestData.enable_multiple_document_mode
        delete requestData.footer_text
        requestData.document_id = requestData._id
        requestData._id = isHTMLDocument
        requestData.UUID = UUID
        requestData.for_spouse = forSpouse === 'true' ? true : false

        updateHTMLDocument(requestData).then((response => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                // reject('false')
                cb('false')
            }
            cb(response.data.data._id)
        })).catch((error) => {
            this.isLoading = false
            if (error.request.status === 401) {
                this.apiErrorMessage = '401'
            } else {
                this.apiErrorMessage = error.message
            }
            this.isApiError = true
            console.log(error)
            // reject('false')
            cb('false')
        })
        // })
    }

    deleteHTML(htmlId:string, cb: (flag: string) => void){
        this.isLoading = true
        const requestData = {
            _id: htmlId
        }
        deleteHTMLDocument(requestData).then((response => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                // reject('false')
                cb('false')
            }
            cb(response.data.message)
        })).catch((error) => {
            this.isLoading = false
            if (error.request.status === 401) {
                this.apiErrorMessage = '401'
            } else {
                this.apiErrorMessage = error.message
            }
            this.isApiError = true
            console.log(error)
            // reject('false')
            cb('false')
        })
    }

    async save(isDocument: boolean, matterId: string): Promise<void> {
        this.isLoading = true

        let requestData: Dictionary<string> = {}
        requestData = this.get()
        requestData.matter_id = matterId
        requestData.is_updated_document = isDocument
        await updateTemplate(requestData).then((response => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
            }
        })).catch((error) => {
            this.isLoading = false
            if (error.request.status === 401) {
                this.apiErrorMessage = '401'
            } else {
                this.apiErrorMessage = error.message
            }
            this.isApiError = true
            console.log(error)
        })

    }

    addNewArticle(title: string, description: string): void {
        const data = {
            name: title,
            description: description,
            is_all_states: true,
            states: [],
            type: 'article'
        }
        const article = new ArticleStore(data)
        this.content.push(article)
    }

    addNewParagraph(title: string, description: string, body: string, edit_history: Array<EditedAt> = []): void {
        const data = {
            name: title,
            description: description,
            is_all_states: true,
            states: [],
            body: body,
            type: 'paragraph',
            edit_history
        }
        const paragraph = new ParagraphStore(data)
        this.content.push(paragraph)
    }

    remove(removeItem: ParagraphStore | ArticleStore) {
        const filteredData = this.content.filter(item => item !== removeItem)
        this.content = filteredData
    }
}

export default SingleTemplateStore