import React from 'react'
import _ from 'lodash'
import TableCellText from './TableCellText'
import { SortConfig } from '../../store/types.d'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { TableLoader } from '../commonComponent/Loader'

interface Cell {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTableAction?: (action: string, value: any, data: any) => void
}

interface Column {
  name: string,
  title: string,
  class?: string,
  component?: React.FC<Cell>,
  sort?: boolean
  ref?: string
}


interface Props {
  unique_key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<any>
  columns: Array<Column>
  isLoading?: boolean
  shouldSort?: (sortConfig: SortConfig) => void,
  dragDrop?: (dragIndex:number, dropIndex: number) => void
  draggable?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTableAction?: (action: string, value: any, data: any) => void
}

const Table: React.FC<Props> = (props) => {
    const shouldSort = props.shouldSort
    const dragDrop = props.dragDrop
    const draggable = props.draggable
    const onTableAction = props.onTableAction
    const [sortConfig, setSortConfig] = React.useState({ key: '_id', direction: 'asc' })

    const isDragDropSupported = (dragDrop != undefined)

    const requestSort = (key: string) => {
        let direction = 'asc'
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc'
        }
        setSortConfig({ key, direction })
        if(shouldSort) {
            shouldSort({ key, direction })
        }
    }

    const firstUpdate = React.useRef(true)
    React.useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false
            return
        }
    }, [])

    const columns = props.columns || []
    const data = props.data || []
    const unique_key = props.unique_key || '_id'

    const handleDragEnd = results => {
        if(!results.destination) { return }
        if(results.source.index === results.destination.index) { return }
        if(dragDrop) {
            dragDrop(results.source.index, results.destination.index)
        }
    }

    if (isDragDropSupported) {
        return (
            <div>
                {props.isLoading && <TableLoader isLoading={props.isLoading} /> }
                <>
                    <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
                        <table className="table table-bordered table-hover text-nowrap">
                            <thead>
                                <tr>
                                    {columns.map((col) => {
                                        const columnName = col.name
                                        return <th key={columnName} onClick={() => {
                                            const isSortable = _.get(col, 'sort', false)
                                            if (isSortable === false) {
                                                return
                                            }
                                            requestSort(columnName)
                                        }}>{col.title}{sortConfig.key === columnName ? <>{(sortConfig.direction === 'asc') ? (_.get(col, 'sort', false)) ? <>↑</> : <></> : <>↓</>}</> : null}</th>
                                    })}
                                </tr>
                            </thead>
               
                            <Droppable droppableId='tbody'>
    
                                {(provided) => (
                                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                        {data.map((row, index) => {
    
                                            return (<Draggable index={index} draggableId={row.name} key={row._id}>
                                                {(provided) => {
                                                    return <tr key={row[unique_key]} ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        {columns.map((col) => {
                                                            const className = col.class ? col.class : ''
                                                            const columnName = col.name
                                                            const component = col.component
                                                            const value = _.get(row, col.name, '') || ''
                                                            const addRef = draggable === true ? {...provided.dragHandleProps} : ''
                                                            const Component = component || TableCellText
                                                            const RenderComponent = <Component value={value} data={row} onTableAction={onTableAction}></Component>
      
                                                            return  <td key={columnName} className={className} {...addRef} >
                                                                {RenderComponent}
                                                            </td>
                                                        })}
                                                    </tr>
                                                }}
                                            </Draggable>  )
    
                  
                                        })}
                                        {provided.placeholder}
                                    </tbody>)
                                }
                            </Droppable>
                        </table>
                    </DragDropContext>
                    {(data.length === 0) ? <div className='responsive d-flex justify-content-center mb-3'>No records found</div> : null}
                </>
            </div>
        )
    }

    return (
        <div>
            {props.isLoading && <TableLoader isLoading={props.isLoading} /> }
            <>
                <table className="table table-bordered table-hover text-nowrap">
                    <thead>
                        <tr>
                            {columns.map((col) => {
                                const columnName = col.name
                                return <th key={columnName} onClick={() => {
                                    const isSortable = _.get(col, 'sort', false)
                                    if (isSortable === false) {
                                        return
                                    }
                                    requestSort(columnName)
                                }}>{col.title}{sortConfig.key === columnName ? <>{(sortConfig.direction === 'asc') ? (_.get(col, 'sort', false)) ? <>↓</> : <></> : <>↑</>}</> : null}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return <tr key={row[unique_key]} >
                                {columns.map((col) => {
                                    const className = col.class ? col.class : ''
                                    const columnName = col.name
                                    const component = col.component
                                    const value = _.get(row, col.name, '') || ''
                                    const Component = component || TableCellText
                                    const RenderComponent = <Component value={value} data={row} onTableAction={onTableAction}></Component>

                                    return  <td key={columnName} className={className}>
                                        {RenderComponent}
                                    </td>
                                })}
                            </tr>
                        })}
                    </tbody>
                </table>
                {(data.length === 0) ? <div className='responsive d-flex justify-content-center mb-3'>No records found</div> : null}
            </>
        </div>
    )
}

export default Table