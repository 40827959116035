import { action, observable } from 'mobx'
import RootStore from './Root'
import { ClientDraftAdjustmentInfo, ValueLabel } from './types.d'
import { initialTableDataWithPaginationDetails } from './InitialValues'
import { editMarkCompleted, editMarkDrafted, editMarkPending, editMarkReview, editMarkWorking, getClientDraftAdjustments } from '../api/ClientDraftAdjustmentActions'

interface ClientDraftAdjustmentData {
    isFiltered: boolean
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    totalAllItems: number,
    page_items: Array<ClientDraftAdjustmentInfo>
}

class ClientDraftAdjustmentStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public clientDraftAdjustment: ClientDraftAdjustmentData
    @observable public searchItem: string
    @observable public page: number
    @observable public filterByStatus: Array<ValueLabel>
    @observable public isLoadingInfo: boolean
    @observable public isPageOpened: boolean
    @observable public isMatterPage: boolean
    @observable public filterWithFutureSigning: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.filterByStatus = [{ value: 'is_not_completed', label: 'Not Completed' }]
        this.isLoadingInfo = false
        this.isMatterPage = false
        this.clientDraftAdjustment = initialTableDataWithPaginationDetails
        this.filterWithFutureSigning = true
    }

    @action setIsOpen = (isOpened: boolean): void => {
        this.isPageOpened = isOpened
    }


    //get the list of client draft adjustment with details
    fetchClientDraftAdjustmentData = (page: number, searchItem: string, searchByStatus: Array<ValueLabel>, filterWithFutureSigning: boolean): void => {
        this.isPageOpened = true
        this.searchItem = searchItem
        this.page = page
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        this.filterByStatus = searchByStatus
        this.filterWithFutureSigning = filterWithFutureSigning
        const requestData = { page: this.page, search_item: searchItem, is_future_signing: filterWithFutureSigning }

        for(let i = 0; i< searchByStatus.length; i++) {
            if(searchByStatus[i].value.includes('not')) {
                requestData[searchByStatus[i].value.replace('_not', '')] = false
            } else {
                requestData[searchByStatus[i].value] = true
            }
        }

        getClientDraftAdjustments(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.clientDraftAdjustment = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markWorking = (client_draft_adjustment_id: string, is_working: boolean, matterId: string, workingNotes: string, isNoteChanged: boolean): void => {
        const data = { client_draft_adjustment_id, is_working, matter_id: matterId, working_notes: workingNotes, is_note_changed: isNoteChanged }
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        this.isMatterPage =  (document.title.includes('Matter') ? true : false )
        editMarkWorking(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            if (this.isMatterPage) {
                this.rootStore.clientStore.fetchClientDraftAdjustment(matterId)
            } else {
                this.fetchClientDraftAdjustmentData(this.page, this.searchItem, this.filterByStatus, this.filterWithFutureSigning)
            }
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markPending = (client_draft_adjustment_id: string, is_pending: boolean, matterId: string, pendingNotes: string, isNoteChanged: boolean): void => {
        const data = { client_draft_adjustment_id, is_pending, matter_id: matterId, pending_notes: pendingNotes, is_note_changed: isNoteChanged }

        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        this.isMatterPage =  (document.title.includes('Matter') ? true : false )

        editMarkPending(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            if (this.isMatterPage) {
                this.rootStore.clientStore.fetchClientDraftAdjustment(matterId)
            } else {
                this.fetchClientDraftAdjustmentData(this.page, this.searchItem, this.filterByStatus, this.filterWithFutureSigning)
            }

        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markDrafted = (client_draft_adjustment_id: string, is_drafted: boolean, matterId: string, draftedNotes: string, isNoteChanged: boolean): void => {
        const data = { client_draft_adjustment_id, is_drafted, matter_id: matterId, drafted_notes: draftedNotes, is_note_changed: isNoteChanged }

        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        this.isMatterPage =  (document.title.includes('Matter') ? true : false )

        editMarkDrafted(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            if (this.isMatterPage) {
                this.rootStore.clientStore.fetchClientDraftAdjustment(matterId)
            } else {
                this.fetchClientDraftAdjustmentData(this.page, this.searchItem, this.filterByStatus, this.filterWithFutureSigning)
            }

        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markReview = (client_draft_adjustment_id: string, is_review: boolean, matterId: string, reviewNotes: string, isNoteChanged: boolean): void => {
        const data = { client_draft_adjustment_id, is_review, matter_id: matterId, review_notes: reviewNotes, is_note_changed: isNoteChanged }
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        this.isMatterPage =  (document.title.includes('Matter') ? true : false )

        editMarkReview(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            if (this.isMatterPage) {
                this.rootStore.clientStore.fetchClientDraftAdjustment(matterId)
            } else {
                this.fetchClientDraftAdjustmentData(this.page, this.searchItem, this.filterByStatus, this.filterWithFutureSigning)
            }
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markCompleted = (client_draft_adjustment_id: string, is_completed: boolean, matterId: string): void => {
        const data = { client_draft_adjustment_id, is_completed, matter_id: matterId }
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        this.isMatterPage =  (document.title.includes('Matter') ? true : false )

        editMarkCompleted(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            if (this.isMatterPage) {
                this.rootStore.clientStore.fetchClientDraftAdjustment(matterId)
            } else {
                this.fetchClientDraftAdjustmentData(this.page, this.searchItem, this.filterByStatus, this.filterWithFutureSigning)
            }
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

}

export default ClientDraftAdjustmentStore
