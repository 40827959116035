import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const deleteDeedImages = (requestData: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deed/deed-images/delete/${requestData}`
    return axios.get(url)
}

export const addDeedImage = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deed/deed-images/add`
    return axios.post(url, requestData)
}

export const getOneDocDeedImages = (requestData: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deed/deed-images/${requestData}`
    return axios.get(url)
}

export const upperTextOfDeedImage = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deed/deed-images/upper-text`
    return axios.post(url, requestData)
}

export const lowerTextOfDeedImage = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deed/deed-images/lower-text`
    return axios.post(url, requestData)
}

export const rearrangeDisplayOrderOfDeedImages = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deed/deed-images/rearrange`
    return axios.post(url, requestData)
}

export const shouldUseRealDeedImage = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deed/deed-images/should-use-real`
    return axios.post(url, requestData)
}

export const getOneDeedFiles = (requestData: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deed/deed-research-images/${requestData}`
    return axios.get(url)
}

export const addDeedFile = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deed/deed-research-images/add`
    return axios.post(url, requestData)
}

export const updateDeedFileStatus = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deed/deed-research-images/update-status`
    return axios.post(url, requestData)
}

export const deleteDeedFile = (requestData: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deed/deed-research-images/delete/${requestData}`
    return axios.get(url)
}