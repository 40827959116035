import React, { useEffect } from 'react'
import { Props } from '../../store/types.d'
import Container from '../layout/Container'
import parse from 'html-react-parser'
import { useParams } from 'react-router-dom'
import { Article } from '../commonComponent/DotLoader'
import { replaceSpaceWithHyphen } from '../commonComponent/commonFuction'
import { observer } from 'mobx-react'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Preview } from '../commonComponent/PreviewStyle'

const UpdateTemplatePreview: React.FC<Props> = ({ rootStore }) => {

    const { templateStore, setTitle } = rootStore
    const { templateDetails, getOneTemplateDetails, isLoading } = templateStore

    const { documentId } = useParams<{
        documentId: string,
    }>()

    useEffect(() => {
        if(templateDetails._id === documentId){
            setTitle(`${templateDetails.name} | Preview`)
        }
        if ((templateDetails !== undefined && templateDetails.name === '') || (templateDetails !== undefined && documentId !== templateDetails._id)) {
            getOneTemplateDetails(documentId, '')
        }
    }, [getOneTemplateDetails, templateDetails, documentId])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>

        <Breadcrumb links={[
            { name: 'Templates', to: '/templates/' },
            { name: 'Preview', isActive: true }
        ]} />
        <Preview>
            <div className='card' style={{ width: '794px', padding: '60px 84px 60px 84px' }}>
                <div className=''>
                    {isLoading ? <Article /> : (templateDetails.content) ? templateDetails.content.map((item: any, templateIndex: number) => {
                        if (item.type === 'article') {
                            return <>
                                {(item.content) ? item.content.map((article: any, articleIndex: number) => {
                                    if (article.type === 'paragraph') {
                                        return (article.body) ? <div key={articleIndex} id={replaceSpaceWithHyphen(article.name)}> {parse(article.body)} </div> : null
                                    } else if (article.type === 'section') {
                                        return <>
                                            {(article.content) ? article.content.map((paraInSection: any, paraIndex: number) => {
                                                return (paraInSection.body) ? <div key={paraIndex} id={replaceSpaceWithHyphen(paraInSection.name)}> {parse(paraInSection.body)} </div> : null
                                            }) : null}
                                        </>
                                    } else {
                                        return null
                                    }
                                }) : null}
                            </>
                        } else if (item.type === 'paragraph') {
                            return <div key={templateIndex} id={replaceSpaceWithHyphen(item.name)}> {parse(item.body)} </div>
                        } else {
                            return null
                        }
                    }) : null}
                </div>
            </div>
        </Preview>

    </Container>
}

export default observer(UpdateTemplatePreview)

