import { action, observable } from 'mobx'
import RootStore from '../Root'
import { getSingleVisionDetails, getVisions } from '../../api/vision/VisionActions'
import { VisionInformation } from '../types.d'
import { initialTableDataWithPaginationDetails, initialSingleVision } from '../InitialValues'
import SingleVisionStore from './SingleVisionInfo'

interface VisionData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<VisionInformation>
}

class VisionStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public visionDetails: VisionInformation
    @observable public visionData: VisionData
    @observable public searchItem: string
    @observable public excludeTestVisions: boolean
    @observable public page: number
    @observable public visionDataHistory: Array<VisionData>
    @observable public singleVisionDetails: SingleVisionStore
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.visionDataHistory = []
        this.excludeTestVisions = true
        this.visionData = initialTableDataWithPaginationDetails
        this.singleVisionDetails = new SingleVisionStore(initialSingleVision)
    }

    @action setIsOpen = (isOpened: boolean): void => {
        this.isPageOpened = isOpened
    }

    resetVisionDetails = (): void => {
        this.singleVisionDetails = new SingleVisionStore(initialSingleVision)
    }
    //get the list of plans with details
    fetchVisions = (page: number, searchItem: string, action: string, excludeTestVisions: boolean): void => {

        if (action === 'change_page') {
            this.page = page
            for (let i = 0; i < this.visionDataHistory.length; i++) {
                if (this.visionDataHistory[i].currentPage === page) {
                    this.visionDataHistory.push(this.visionData)
                    this.visionData = this.visionDataHistory[i]
                    return
                }
            }
            this.visionDataHistory.push(this.visionData)

        } else if (action === 'search_item') {
            this.visionDataHistory = []
            this.searchItem = searchItem
            this.page = 1
        } else if (action === 'change_filter') {
            this.visionDataHistory = []
            this.excludeTestVisions = excludeTestVisions
            this.page = 1
        }
        this.isLoading = true
        this.isPageOpened = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem, exclude_test_visions: this.excludeTestVisions }

        getVisions(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.visionData = response.data.data
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    setVisionDetails = async (data: string): Promise<void> => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        await getSingleVisionDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.singleVisionDetails = new SingleVisionStore(response.data.data)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

}

export default VisionStore
