import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { TableCellSettings } from './TableCellSettings'
import { Link } from 'react-router-dom'
import { debounceSearch } from '../../utils/debounce'

interface Props {
  rootStore: RootStore,
}

const OrganizationPage: React.FC<Props> = ({ rootStore }) => {
    const { organizationStore, setTitle } = rootStore

    const { searchItem, isApiError, isLoading, apiErrorMessage, organizationData, fetchOrganizations, isPageOpened, resetError } = organizationStore
    const [searchItemField, setSearchItemField] = useState(searchItem)

    const loadPage = (page: number) => {
        fetchOrganizations(page, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchOrganizations(1, e.target.value.trim()))
    }

    useEffect(() => {
        setTitle('Organizations | OathZephyr')
        resetError()
        if(rootStore.authStore.isAdmin && !isPageOpened){
            fetchOrganizations(1, '')
        }
    })

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                Organizations
                    <Link to='/organizations/add' className='ml-auto px-3'>Add Organization</Link>
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'name', title: 'Name' },
                                    { name: 'owner_name', title: 'Owner Name' },
                                    { name: 'about', title: 'About' },
                                    { name: '_id', title: 'Action', component: TableCellSettings }
                                ]}
                                data={organizationData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={organizationData.currentPage}
                            totalPages={organizationData.totalPage}
                            totalItems={organizationData.totalItem}
                            itemsPerPage={organizationData.itemPerPage}
                            isFiltered={organizationData.isFiltered}
                            totalAllItems={organizationData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(OrganizationPage)
