import { observable } from 'mobx'
import { OptionInfo } from '../types.d'


class OptionInfoStore implements OptionInfo {
    @observable public label: string
    @observable public type: string
    @observable public placeholder: string
    @observable public display_order: number
    @observable public plan: Array<string>
    @observable public is_active: boolean

    constructor(option_info: OptionInfo) {
        this.label = ''
        this.type = 'CHECKBOX'
        this.placeholder = ''
        this.display_order = 1
        this.plan = []
        this.is_active = true
        this.set(option_info)
    }

    set(option_info: OptionInfo): void {
        this.label = option_info.label
        this.type = option_info.type
        this.placeholder = option_info.placeholder
        this.display_order = option_info.display_order
        this.plan = option_info.plan
        this.is_active = option_info.is_active
    }

    get(): OptionInfo {
        return {
            label: this.label,
            type: this.type,
            placeholder: this.placeholder,
            display_order: this.display_order,
            plan: this.plan,
            is_active: this.is_active
        }
    }
}

export default OptionInfoStore