import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { TableCellAction } from '../table/TableCellEditButton'
import { Link } from 'react-router-dom'
import { debounceSearch } from '../../utils/debounce'

interface Props {
  rootStore: RootStore,
}

const ConditionsPage: React.FC<Props> = ({ rootStore }) => {
    const { conditionStore, setTitle } = rootStore

    const { searchItem, isApiError, isLoading, apiErrorMessage, conditionData, fetchCondition, resetConditionDetails, isPageOpened } = conditionStore

    const [searchItemField, setSearchItemField] = useState(searchItem)

    const loadPage = (page: number) => {
        fetchCondition(page, searchItem, 'change_page')
    }
  
    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchCondition(1, e.target.value.trim(), 'search_item'))
    }

    useEffect(() => {
        setTitle('Condition | OathZephyr')
        if(rootStore.authStore.isAdmin && !isPageOpened){
            resetConditionDetails()
            fetchCondition(1, '', '')
        }
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
          Conditions
                    <Link to='/conditions/add' className='ml-auto px-3'>Add Condition</Link>
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>
                        </div>

                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'name', title: 'Name' },
                                    { name: 'description', title: 'Description' },
                                    { name: '_id', title: 'Action', component: TableCellAction }
                                ]}
                                data={conditionData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={conditionData.currentPage}
                            totalPages={conditionData.totalPage}
                            totalItems={conditionData.totalItem}
                            itemsPerPage={conditionData.itemPerPage}
                            isFiltered={conditionData.isFiltered}
                            totalAllItems={conditionData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(ConditionsPage)
