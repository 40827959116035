import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const addUpdateCommonParagraph = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/common-paragraphs/add-update`
    return axios.post(url, requestData)
}

export const getCommonParagraphs = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/common-paragraphs/`
    return axios.post(url, requestData)
}

export const getSingleCommonParagraphDetails = (commonParagraphId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/common-paragraphs/common-paragraph/${commonParagraphId}`
    return axios.post(url)
}