import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const getMatterClientDraftAdjustments = (matterId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/matter/client-draft-adjustments/${matterId}`
    return axios.get(url)
}

export const addUpdateClientDraftAdjustmentForMatter = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/matter/client-draft-adjustment/add-update`
    return axios.post(url, requestData)
}

export const editMarkWorking = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/client-draft-adjustments/client-draft-adjustments/edit/is-working`
    return axios.post(url, requestData)
}

export const editMarkCompleted = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/client-draft-adjustments/client-draft-adjustments/edit/is-completed`
    return axios.post(url, requestData)
}

export const editMarkReview = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/client-draft-adjustments/client-draft-adjustments/edit/is-review`
    return axios.post(url, requestData)
}


export const getClientDraftAdjustments = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/client-draft-adjustments/`
    return axios.post(url, requestData)
}

export const editMarkPending = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/client-draft-adjustments/client-draft-adjustments/edit/is-pending`
    return axios.post(url, requestData)
}

export const editMarkDrafted = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/client-draft-adjustments/client-draft-adjustments/edit/is-drafted`
    return axios.post(url, requestData)
}