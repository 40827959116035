import React, { useEffect, useState } from 'react'
import { Cell } from '../table/TableCellText.d'
import { ValueLabel } from '../../store/types.d'
import { makeValueLabelList } from '../commonComponent/commonFuction'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import uuid from 'react-uuid'
import _ from 'lodash'

export const TableCellTags: React.FC<Cell> = (props) => {
    const { value = [], data = {} } = props

    const { updateTag } = props.data.tagStore
    const { tagList } = props.data.allTagStore
    const [openEditor, setOpenEditor] = useState(false)
    const [ tag, setTag ] = useState(value)
    const [selectedOptionOfTag, setSelectedOptionOfTag] = useState<Array<ValueLabel>>()

    const option = tagList.map((list: any) => {
        const data = {
            value: list.tag || '',
            label: list.tag || ''
        }
        return data
    })
    const selectedTag = makeValueLabelList(tag, option)    

    useEffect(() => {
        if (selectedTag !== undefined) {
            setSelectedOptionOfTag(selectedTag)
        } else {
            setSelectedOptionOfTag([])
        }
    }, [setSelectedOptionOfTag])

    const handleOnFieldTagChange = (data: any) => {
        if (data !== null) {
            setSelectedOptionOfTag(data)
        }
    }

    const saveChanges = () => {
        const tagArray = selectedOptionOfTag?.map(item => item.value) || []
        
        let entity_name = 'Default'
        if (data.source) {
            entity_name = 'Client'
        } else if (data.client_name) {
            entity_name = 'Vision'
        } else if (data.name) {
            entity_name = 'Matter'
        }

        updateTag(entity_name, data._id, tagArray)
        setTag(tagArray)
        setOpenEditor(!openEditor)
    }

    const resetChange = () => {
        setSelectedOptionOfTag(selectedTag)
        setOpenEditor(!openEditor)
    }

    return (
        <>
            {openEditor ? 
                <div className='d-flex align-items-center'>
                    <ReactSelectComponent
                        options={option}
                        value={selectedOptionOfTag || []}
                        isMulti={true}
                        handleOnChangeReact={handleOnFieldTagChange}
                        isTag={true}
                    />
                    <div className='ml-auto'>
                        <button 
                            type='button' 
                            className='btn btn-outline-primary small-button' 
                            onClick={saveChanges}
                        >Save</button>

                        <button 
                            type='button' 
                            className='btn btn-outline-danger small-button ml-2' 
                            onClick={resetChange}
                        >Cancel</button>
                    </div>
                </div> : 
                <div className='d-flex'>
                    <span key={uuid()} className='mr-2'>{_.join(tag, ', ')}</span>
                    <button 
                        type='button' 
                        className='btn btn-outline-primary small-button ml-auto' 
                        onClick={() => setOpenEditor(!openEditor)}
                    >
                       Edit
                    </button>
                </div>
            }
        </>
    )
}