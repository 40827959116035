import React, { useEffect } from 'react'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import { Props } from '../../store/types.d'
import { Link, useHistory } from 'react-router-dom'
import { ShowDetails } from '../commonComponent/commonFuction'
import { Cell } from '../table/TableCellText.d'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { initialClientDetailsForMatters } from '../../store/InitialValues'

const ClientMattersPage: React.FC<Props> = ({ rootStore }) => {
    const { clientStore, setTitle } = rootStore
    const { setClientMatters, isLoading, isApiError, apiErrorMessage, clientMatters, setMatters } = clientStore
    const history = useHistory()

    const pathArrayWithTemplateId = history.location.pathname.split('/')
    const clientId = pathArrayWithTemplateId[2]

    useEffect(() => {
        setClientMatters(clientId)
    }, [clientId, setClientMatters])

    const TableCellDocuments: React.FC<Cell> = (props) => {
        let value = props.value || ''
        // Convert boolean values to string so that they are visible in table
        if (value === true || value === false) {
            value = value.toString()
        }
        return (
            <>
                <Link to={`/clients/${clientId}/matters/${value}`}>View Documents</Link>
            </>
        )
    }

    const handleOnBack = () => {
        setMatters({
            client: initialClientDetailsForMatters,
            matters: [],
            relateds: []
        })
    }

    useEffect(() => {
        let title: string = clientMatters.client.full_name
        if(title === ''){
            title = clientMatters.client.last_name
        }
        if(title !== undefined && clientId === clientMatters.client._id){
            setTitle(`${title} | Matters`)
        }
    },[clientMatters])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div>
            <Breadcrumb links={[
                { name: 'Clients', to: '/clients/' },
                { name: 'Matters', isActive: true }
            ]} />
            <div className="card">
                <div className="card-header">
                    Matters of Client
                    <div className='float-right'>
                        <Link to='/clients/' onClick={handleOnBack} className='btn btn-sm btn-outline-primary'>Back</Link>
                    </div>
                </div>

                <div className="card-body">
                    <div className='row'>
                        <div className='col'>
                            {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}
                            <div>
                                <b>Client Details:</b>
                                <hr />
                                <ShowDetails title='First Name' value={clientMatters.client.first_name} />
                                <ShowDetails title='Middle Name' value={clientMatters.client.middle_name} />
                                <ShowDetails title='Last Name' value={clientMatters.client.last_name} />
                                <ShowDetails title='Gender' value={clientMatters.client.gender} />
                                <ShowDetails title='Mobile Number' value={clientMatters.client.phone_numbers?.mobile || ''} />
                                <ShowDetails title='Home Contact Number' value={clientMatters.client.phone_numbers?.home || ''} />
                                <ShowDetails title='Work Contact Number' value={clientMatters.client.phone_numbers?.work || ''} />
                                <hr />
                                <b>Address:</b>
                                <hr />
                                <ShowDetails title='Street' value={clientMatters.client.street} />
                                <ShowDetails title='City' value={clientMatters.client.city} />
                                <ShowDetails title='State' value={clientMatters.client.state.name} />
                                <ShowDetails title='Country' value={clientMatters.client.country} />
                                <ShowDetails title='County' value={clientMatters.client.county} />
                                <ShowDetails title='County Reference' value={clientMatters.client.county_ref} />
                                <hr />
                            </div>
                            <b>Related Contacts:</b>

                            <div className='table-responsive mt-3'>
                                <Table
                                    isLoading={isLoading}
                                    unique_key='_id'
                                    columns={[
                                        { name: 'name', title: 'Name' },
                                        { name: 'gender', title: 'Gender' },
                                        { name: 'createdAt', title: 'Created At' },
                                    ]}
                                    data={clientMatters.relateds}
                                ></Table>
                                <hr />
                            </div>
                            <b>Matters:</b>
                            <div className='table-responsive mt-3'>
                                <Table
                                    isLoading={isLoading}
                                    unique_key='_id'
                                    columns={[
                                        { name: 'name', title: 'Matter Name' },
                                        { name: 'modifier_name', title: 'Modifier Name' },
                                        { name: 'state_name', title: 'State' },
                                        { name: 'county', title: 'County' },
                                        { name: 'createdAt', title: 'Created At' },
                                        { name: 'updatedAt', title: 'Updated At' },
                                        { name: '_id', title: 'View', component: TableCellDocuments }
                                    ]}
                                    data={clientMatters.matters}
                                ></Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(ClientMattersPage)