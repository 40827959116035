import React from 'react'
import FormModal from '../commonComponent/FormModal'
import { Controller, useForm } from 'react-hook-form'
import classNames from 'classnames'
import SingleFieldStore from '../../store/SingleField'
import Select from 'react-select'
import { ValueLabel } from '../../store/types.d'

interface Props {
	targetData?: any
	showModel: boolean
	modalTitle: string
	fieldStore: SingleFieldStore
    options: Array<ValueLabel>
	openCloseForm: () => void
}

const LabelDecorationModel: React.FC<Props> = (props) => {

    const makeValueLabelList = (data: any, allOptions: Array<ValueLabel>) => {
        for (let j = 0; j < allOptions.length; j++) {
            if (data === allOptions[j].value) {
                return allOptions[j]
            }
        }
    }
    const selectedScope = makeValueLabelList(props.targetData.scope, props.options)

    const scrollableBodyStyle = 'modal-body resource'
    const { register, handleSubmit, errors, control } = useForm()

    const options = props.options

    const onSaveModal = (data: any) => {
        if (props.modalTitle.includes('Add')) {
            props.fieldStore.addNewLabelDecoration(data.scope.value, data.find, data.replace)
        } else {
            props.targetData.scope = data.scope.value
            props.targetData.find = data.find
            props.targetData.replace = data.replace
        }
        props.openCloseForm()
    }

    return <>
        <FormModal show={props.showModel} modalTitle={props.modalTitle} openCloseModal={props.openCloseForm}>

            <form onSubmit={handleSubmit(onSaveModal)} >
                <fieldset>
                    <div className={scrollableBodyStyle} >
                        <div className='ml-2'>
                            <div className='form-group'>
                                <label>Select Scope *</label>
                                <Controller
                                    control={control}
                                    name="scope"
                                    defaultValue={selectedScope}
                                    render={({ onChange, ref, value }) => (
                                        <Select
                                            inputRef={ref}
                                            value={value}
                                            options={options}
                                            className={(errors.scope) ? 'is-invalid' : ''}
                                            classNamePrefix="select"
                                            onChange={val => {
                                                onChange(val)
                                            }}
                                        />)}
                                    rules={{
                                        validate: (value) => (value === undefined) ? 'Please Select Scope' : true
                                    }}
                                />
                                {(errors.scope) && (<div className='invalid-feedback'>{errors.scope.message}</div>)}
                            </div>
                            <div className='form-group'>
                                <label>Find *</label>
                                <input
                                    ref={register({
                                        required: 'This field is required',
                                        validate: (value: string) => (value.trim()) ? true : 'This field is required'
                                    })}
                                    name='find'
                                    type='text'
                                    defaultValue={props.targetData.find}
                                    className={classNames('form-control', { 'is-invalid': errors.find })}
                                    placeholder='Enter Find word'
                                />
                                {errors.find && (<div className='invalid-feedback'>{errors.find.message}</div>)}
                            </div>

                            <div className='form-group'>
                                <label>Replace *</label>
                                <input
                                    ref={register({
                                        required: 'This field is required',
                                        validate: (value: string) => (value.trim()) ? true : 'This field is required'
                                    })}
                                    name='replace'
                                    type='text'
                                    defaultValue={props.targetData.replace}
                                    className={classNames('form-control', { 'is-invalid': errors.replace })}
                                    placeholder='Enter Replace with'
                                />
                                {errors.replace && (<div className='invalid-feedback'>{errors.replace.message}</div>)}
                            </div>
                            
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={props.openCloseForm}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary">{props.modalTitle.includes('Add') ? 'Add' : 'Update'}</button>
                    </div>
                </fieldset>
            </form>
        </FormModal>
    </>
}

export default LabelDecorationModel