import React, { useEffect } from 'react'
import RootStore from '../../store/Root'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import InputField from '../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'


interface Props {
  rootStore: RootStore,
}

interface Dictionary<T> {
  // eslint-disable-next-line
  [Key: string]: T | any
}

const UpdateStatePage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors } = useForm()

    const { stateStore, setTitle } = rootStore
    const { isApiError, isLoading, apiErrorMessage, stateDetails, setStateDetails, updateOrDeleteStateDetails } = stateStore

    const history = useHistory()
    const pathArrayWithStateId = history.location.pathname.split('/')
    const stateId = pathArrayWithStateId[3]

    useEffect(() => {
        setStateDetails(stateId)
    }, [setStateDetails, stateId])

    const handleClick = () => {
        history.push('/states/')
    }

    const onSubmit = async (data: Dictionary<string>) => {

        const requestData = {
            state_id: stateId,
            state_name: data.state_name,
            state_code: data.state_code
        }

        const response = await updateOrDeleteStateDetails(requestData)
        if (response === 'true') {
            history.push('/states/')
        }
    }

    useEffect(() => {
        if(stateId === stateDetails._id){
            setTitle(`${stateDetails.state_name} | State`)
        }
    }, [stateDetails])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'States', to: '/states/' },
            { name: 'Update', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
        Update State
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        {(stateId === stateDetails._id)
                            ? <form onSubmit={handleSubmit(onSubmit)} >
                                <fieldset>

                                    <InputField
                                        label='State Name *'
                                        fieldName='state_name'
                                        register={register}
                                        errors={errors.state_name}
                                        placeholder='Enter State Name'
                                        defaultValue={stateDetails.state_name}
                                        required={true}
                                    />

                                    <InputField
                                        label='State Code *'
                                        fieldName='state_code'
                                        register={register}
                                        errors={errors.state_code}
                                        placeholder='Enter State Code'
                                        defaultValue={stateDetails.state_code}
                                        required={true}
                                    />

                                    <div className='form-group'>
                                        <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                            {isLoading ? 'Loading...' : 'Submit'}
                                        </button>&nbsp;&nbsp;
                                        <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                    </div>
                                </fieldset>
                            </form>
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(UpdateStatePage)