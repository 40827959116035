import React, { useState } from 'react'
import FormModal from '../commonComponent/FormModal'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import SingleFieldStore from '../../store/SingleField'
import PackagesSelector from '../commonComponent/PackagesSelector'
import { PackageSelectable, ValueLabel } from '../../store/types.d'

interface Props {
	targetData?: any
	showModel: boolean
	modalTitle: string
	fieldStore: SingleFieldStore
    packagesWithValueLabel: Array<ValueLabel>
	openCloseForm: () => void
}
export interface PackageSelectorButtonProps {
    targetStore: PackageSelectable
    onClick: (store: PackageSelectable) => void
    isDisable?: boolean
}

export const PackageSelectorButton: React.FC<PackageSelectorButtonProps> = ({ targetStore, onClick, isDisable }) => {
    const is_all_packages = targetStore.is_all_packages == undefined ? true : targetStore.is_all_packages
    const packages = targetStore.packages == undefined ? [] : targetStore.packages
    const disable = isDisable || false
    return <button
        className='btn btn-sm btn-link'
        disabled={disable}
        type='button'
        onClick={() => {
            onClick(targetStore)
        }} >Packages ({(is_all_packages) ? 'All' : packages.length})
    </button>
}

const ButtonInFieldModel: React.FC<Props> = (props) => {

    const scrollableBodyStyle = 'modal-body resource'
    const { register, handleSubmit, errors } = useForm()
    // const { packagesWithValueLabel } = PackageStore
    const [targetStore, setTargetStore] = useState<PackageSelectable>()
    const [showPackageModal, setShowPackageModal] = useState(false)

    const onSaveModal = (data: any) => {
        if (props.modalTitle.includes('Add')) {
            props.fieldStore.addNewButton(data.label, data.action_string, props.targetData.is_all_packages, props.targetData.packages)
        } else {
            props.targetData.label = data.label
        }
        props.openCloseForm()
    }

    const openPackageModel = (store: PackageSelectable) => {
        setTargetStore(store)
        setShowPackageModal(true)
    }

    const openClosePackageModal = () => {
        const show = (showPackageModal === false) ? true : false
        setShowPackageModal(show)
    }
    

    return <>
        <FormModal show={props.showModel} modalTitle={props.modalTitle} openCloseModal={props.openCloseForm}>

            <form onSubmit={handleSubmit(onSaveModal)} >
                <fieldset>
                    <div className={scrollableBodyStyle} >
                        <div className='ml-2'>

                            <div className='form-group'>
                                <label>Label of Button for Interview *</label>
                                <input
                                    ref={register({
                                        required: 'This field is required',
                                        validate: (value: string) => (value.trim()) ? true : 'This field is required'
                                    })}
                                    name='label'
                                    type='text'
                                    defaultValue={props.targetData.label}
                                    className={classNames('form-control', { 'is-invalid': errors.label })}
                                    placeholder='Enter Default Value'
                                />
                                {errors.label && (<div className='invalid-feedback'>{errors.label.message}</div>)}
                            </div>

                            <div className='form-group'>
                                <label>Action String of Button for Interview *</label>
                                <input
                                    ref={register({
                                        required: 'This field is required',
                                        validate: (value: string) => (value.trim()) ? true : 'This field is required'
                                    })}
                                    name='action_string'
                                    type='text'
                                    disabled={props.modalTitle.includes('Edit')}
                                    defaultValue={props.targetData.action_string}
                                    className={classNames('form-control', { 'is-invalid': errors.action_string })}
                                    placeholder='Enter Default Value'
                                />
                                {errors.action_string ? <div className='invalid-feedback'>{errors.action_string.message}</div> : <small className='form-text text-muted'>Note: This string will be added once. You can not edit it.</small>}
                            </div>
                            <PackageSelectorButton targetStore={props.targetData} onClick={openPackageModel} />
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={props.openCloseForm}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary">{props.modalTitle.includes('Add') ? 'Add' : 'Update'}</button>
                    </div>
                </fieldset>
            </form>
            {showPackageModal ? <PackagesSelector selectorShow={showPackageModal} targetStore={targetStore} openClosePackageModal={openClosePackageModal} options={props.packagesWithValueLabel} /> : null}
        </FormModal>
    </>
}

export default ButtonInFieldModel