import RootStore from './Root'
import { observable, runInAction, computed, action } from 'mobx'
import { getAllStates } from '../api/StateActions'

interface StateInformation {
    _id?: string,
    state_name: string,
    state_code: string
}

class AllStatesStore {

    public rootStore: RootStore
    @observable public allStates: Array<StateInformation> = []
    @observable public isLoaded = false
    @observable public isLoading = false
    
    constructor(rootStore: RootStore){
        this.rootStore = rootStore
    }

    @computed get statesWithValueLabel () {
        return this.allStates.map((list) => {
            const data = {
                value: list._id || '',
                label: list.state_name
            }
            return data
        })
    }

    @computed get statesWithNameLabel () {
        return this.allStates.map((list) => {
            const data = {
                name: list._id || '',
                label: list.state_name
            }
            return data
        })
    }


    @computed get stateNamesAndCodesWithValueLabel () {
        return this.allStates.map((list) => {
            const data = {
                value: list._id || '',
                label: list.state_name + ' (' + list.state_code + ')'
            }
            return data
        })
    }

    @computed get stateNamesAndCodesWithAllAsOption () {
        const options = this.allStates.map((list) => {
            const data = {
                value: list._id || '',
                label: list.state_name + ' (' + list.state_code + ')'
            }
            return data
        })

        options.unshift({
            value: 'all',
            label: 'All'
        })

        return options
    }

    @action
    public getAllStates () {
        if (this.isLoaded === false ) {
            this.reload()
        }
    }

    @action
    public forceReload() {
        this.reload()
    }

    @action
    public reload () {
        this.isLoading = true
        getAllStates().then((response) => {
            runInAction(() => {
                this.isLoading = false
                this.allStates = response.data.data
                this.isLoaded = true
            })
        }).catch(() => {
            runInAction(() => {
                this.isLoading = false
            })
        })
    }

}

export default AllStatesStore
