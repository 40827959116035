import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const addUpdatePlural = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/plurals/add-update`
    return axios.post(url, requestData)
}

export const getPlurals = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/plurals/`
    return axios.post(url, requestData)
}

export const getSinglePluralDetails = (pluralId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/plurals/plural/${pluralId}`
    return axios.post(url)
}