import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const login = (email: string, password: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/web/login`
    return axios.post(url, { email, password })
}

export const changePassword = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/password/edit`
    return axios.post(url, requestData)
}

export const viewProfile = (email: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/profile/view`
    return axios.post(url, { email })
}