import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { Cell } from '../table/TableCellText.d'
import { Link } from 'react-router-dom'
import Modal from '../commonComponent/Modal'
import _ from 'lodash'
import { UpworkJobLoader } from '../commonComponent/DotLoader'
import uuid from 'react-uuid'
import { TableCellMatters } from './TableCellMatters'
import { ShowDetails, ClientInfo } from '../commonComponent/commonFuction'
import { FormateDateOnly, TableCellStateName } from '../commonComponent/TableCell'
import { SortConfig, ValueLabel } from '../../store/types.d'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import { optionSource } from '../field/TypeList'
import { defaultAllObj } from '../../store/InitialValues'
import { debounceSearch } from '../../utils/debounce'
import { TableCellTags } from '../tag/TableCellTag'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  rootStore: RootStore,
}

let optionSourceForFilter = [
    defaultAllObj
]
optionSourceForFilter = optionSourceForFilter.concat(optionSource)

const TableCellSource: React.FC<Cell> = (props) => {
    const value = props.value || ''

    return (
        <>
            {(value === 'OATHZEPHYR') ? 'Oath Zephyr' : _.capitalize(value)}
        </>
    )
}

const ClientsPage: React.FC<Props> = ({ rootStore }) => {
    const { clientStore, allStatesStore, authStore, tagStore, allTagStore, setTitle } = rootStore
    const { isAdmin, userOrg } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, clientData, fetchClient, setClientDetail, clientDetail, sortClientData, isClient, isDataUpdated, filterWithSource, searchByState, isPageOpened, excludeTestClients } = clientStore
    const { statesWithValueLabel } = allStatesStore
    const all = { value: 'All', label: 'All' }

    const statesWithAll = statesWithValueLabel.map((state) => {
        return { value: state.value, label: state.label }
    })
    statesWithAll.splice(0, 0, all)
    const searchedState = statesWithAll.find((state: ValueLabel) => state.value === searchByState)
    const [searchItemField, setSearchItemField] = useState(searchItem)

    const [showModal, setShow] = useState(false)
    const [selectedRow, setSelectedRow] = useState({ _id: '', action: '' })
    
    const [searchState, setSearchState] = useState((searchedState === undefined) ? all : searchedState)
    const [isClientFilter, setIsClient] = useState(isClient)
    const [excludeTestClientsField, setExcludeTestClientsField] = useState(excludeTestClients)

    const initialSource = optionSourceForFilter.find((item) => item.value === filterWithSource) || defaultAllObj

    const [filterSource, setFilterSource] = useState(initialSource)

    const loadPage = (page: number) => {
        fetchClient(page, searchItem, 'change_page', searchState.value, isClient, isDataUpdated, filterSource.value, excludeTestClientsField)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchClient(1, e.target.value.trim(), 'search_item', searchState.value, isClient, isDataUpdated, filterSource.value, excludeTestClientsField))
    }

    const openCloseModal = () => {
        setShow(!showModal)
    }

    const onTableAction = (action: string, value: any, data: any) => {
        setClientDetail(data._id)
        setSelectedRow({ _id: data._id, action: action })
        openCloseModal()
    }

    const isValueEquivalent = (a: any, b: any) => {
        if (!_.isEqual(a, b)) {
            return false
        }
        return true
    }

    const ConvertTitleCase = (string: string) => {

        if (string !== undefined) {
            return string.charAt(0).toLocaleUpperCase() + string.slice(1).toLocaleLowerCase()
        }
        return string
    }

    const ShowConflictDetails: React.FC<ClientInfo> = ({ title, value, diffValue }) => {

        const className = (value !== diffValue && diffValue !== '' && diffValue !== null && value !== '') ? 'bg-warning' : ''

        return <tr>

            <td><b>{title}</b></td>
            <td>{value}</td>
            <td className={className}>{diffValue}</td>
        </tr>
    }

    const TableCellViewDetails: React.FC<Cell> = (props) => {
        const value = props.data._id || ''
        const data = props.data || {}

        return (
            <>
                <Link to='#' data-target='#myModal' key={uuid()} className='mr-2' onClick={() => {
                    if (onTableAction != null) {
                        onTableAction('viewDetails', value, data)
                    }
                }}>View Details</Link>

                {showModal && isValueEquivalent({ _id: data._id, action: 'viewDetails' }, selectedRow) ? (
                    <Modal show={showModal}
                        modalTitle={data.full_name}
                        openCloseModal={openCloseModal}
                        modalDialogClass='modal-lg'
                        cancelButtonName='Close'
                        isScrollable={true}
                        cancelButton={true}
                        isSaveButton={false}
                    >

                        {isLoading || (data._id !== clientDetail._id) ? <UpworkJobLoader /> : <>
                            <div className='row'>
                                <div className='col'><b>General Information</b></div>
                            </div><hr />

                            <ShowDetails title='First Name' value={clientDetail.first_name} />
                            <ShowDetails title='Middle Name' value={clientDetail.middle_name} />
                            <ShowDetails title='Last Name' value={clientDetail.last_name} />
                            <ShowDetails title='Is Client' value={clientDetail.is_client === 1 ? 'Yes' : 'No'} />
                            <ShowDetails title='From Daylite' value={clientDetail.is_imported === 1 ? 'Yes' : 'No'} />
                            {clientDetail.is_imported !== 1 ? <ShowDetails title='Modifier Name' value={clientDetail.employee} /> : null}
                            <ShowDetails title='Added By' value={clientDetail.added_by} />
                            <ShowDetails title='Type' value={ConvertTitleCase(clientDetail.type)} />
                            <ShowDetails title='Gender' value={ConvertTitleCase(clientDetail.gender)} />
                            <ShowDetails title='Birth Date' value={clientDetail.birthdate} />
                            <ShowDetails title='Mobile Number' value={clientDetail.phone_numbers?.mobile || ''} />
                            <ShowDetails title='Home Contact Number' value={clientDetail.phone_numbers?.home || ''} />
                            <ShowDetails title='Work Contact Number' value={clientDetail.phone_numbers?.work || ''} />
                            <ShowDetails title='Email Address' value={clientDetail.email} />
                            <hr />
                            <div className='row'><div className='col'><b>Address Information</b></div></div>
                            <hr />

                            <ShowDetails title='Street' value={clientDetail.street} />
                            <ShowDetails title='City' value={clientDetail.city} />
                            <ShowDetails title='State' value={clientDetail.state} />
                            <ShowDetails title='Country' value={ConvertTitleCase(clientDetail.country)} />

                            <hr />
                            <div className='row'><div className='col'><b>Other Information</b></div></div>
                            <hr />

                            <ShowDetails title='County' value={clientDetail.county} />
                            <ShowDetails title='County Reference' value={ConvertTitleCase(clientDetail.county_ref)} />
                            <ShowDetails title='Marital Status' value={ConvertTitleCase(clientDetail.marital_status)} />

                            {clientDetail.marital_status === 'MARRIED' ?

                                <>
                                    <ShowDetails title='Date of married' value={clientDetail.date_of_married} />
                                    <ShowDetails title='Life Partner' value={clientDetail.life_partner} />

                                </>
                                : null
                            }

                            <ShowDetails title='Children' value={clientDetail.children} />
                            {isAdmin ? 
                                <>
                                    <hr />
                                    <div className='row'><div className='col'><b>Lawmatics Information</b></div></div>
                                    <hr />
        
                                    <ShowDetails title='Matter Id' value={clientDetail.lawmatics_matter_id} setLink={
                                        <a 
                                            href={`${process.env.REACT_APP_LAWMATICS_MATTER_URL}/${clientDetail.lawmatics_matter_id}/activities`} 
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            className='ml-auto display-6' 
                                            title='Lawmatics Matter Id'
                                        ><FontAwesomeIcon icon={faExternalLinkAlt} />
                                        </a>
                                    }/>
                                    <ShowDetails title='Contact Id' value={clientDetail.lawmatics_contact_id} setLink={
                                        <a 
                                            href={`${process.env.REACT_APP_LAWMATICS_CONTACT_URL}/${clientDetail.lawmatics_contact_id}/activities`} 
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            className='ml-auto display-6' 
                                            title='Lawmatics Contact Id'
                                        ><FontAwesomeIcon icon={faExternalLinkAlt} />
                                        </a>}/>
                                    <ShowDetails title='Probable Uid' value={clientDetail.zephyr_probable_uid} />
                                </> : null}
                        </>
                        }
                    </Modal>) : null
                }

                {
                    data.is_updated === true ? <>

                        <Link to='#' data-target='#myModal' onClick={() => {
                            if (onTableAction != null) {
                                onTableAction('viewDifference', value, data)
                            }
                        }}>View Difference</Link>

                        {showModal && isValueEquivalent({ _id: data._id, action: 'viewDifference' }, selectedRow) ? (

                            <Modal show={showModal}
                                modalTitle={data.full_name}
                                openCloseModal={openCloseModal}
                                modalDialogClass='modal-lg'
                                cancelButtonName='Close'
                                isScrollable={true}
                                cancelButton={true}
                                isSaveButton={false}
                            >

                                {isLoading || (clientDetail.is_updated !== true) ? <UpworkJobLoader /> : <>
                                    <table className="table">
                                        <tr>
                                            <th></th>
                                            <th>Application Data</th>
                                            <th>Daylite Data</th>
                                        </tr>
                                        <tr>
                                            <th colSpan={3}>
                                                <u>General Information</u>
                                            </th>
                                        </tr>
                                        <ShowConflictDetails title='First Name' value={clientDetail.first_name} diffValue={clientDetail.updated_data.first_name} />
                                        <ShowConflictDetails title='Last Name' value={clientDetail.last_name} diffValue={clientDetail.updated_data.last_name} />
                                        <ShowConflictDetails title='Is Client' value={clientDetail.is_client === 1 ? 'Yes' : 'No'} diffValue={clientDetail.updated_data.is_client === 1 ? 'Yes' : 'No'} />

                                        <tr>
                                            <th colSpan={3}>
                                                <u>Address Information</u>
                                            </th>
                                        </tr>
                                        <ShowConflictDetails title='Street' value={clientDetail.street} diffValue={clientDetail.updated_data.street} />
                                        <ShowConflictDetails title='City' value={clientDetail.city} diffValue={clientDetail.updated_data.city} />
                                        <ShowConflictDetails title='State' value={clientDetail.state} diffValue={clientDetail.updated_data.state} />
                                        <ShowConflictDetails title='Country' value={ConvertTitleCase(clientDetail.country)} diffValue={ConvertTitleCase(clientDetail.updated_data.country)} />
                                    </table>
                                </>
                                }
                            </Modal>)
                            : null}
                    </> : null
                }
            </>
        )
    }

    const shouldSort = (sortConfig: SortConfig) => {
        sortClientData(sortConfig)
    }

    const handleOnChangeState = (data: any) => {
        if (data !== null) {
            setSearchState(data)
            fetchClient(1, searchItem, 'change_filter', data.value, isClient, isDataUpdated, filterSource.value, excludeTestClientsField)
        }
    }
    const handleIsClient = (e: any) => {
        const value = e.target.checked
        setIsClient(value)
        fetchClient(1, searchItem, 'change_filter', searchState.value, value, isDataUpdated, filterSource.value, excludeTestClientsField)
    }

    const handleExcludeTestClients = (e: any) => {
        const value = e.target.checked
        setExcludeTestClientsField(value)
        fetchClient(1, searchItem, 'change_filter', searchState.value, isClient, isDataUpdated, filterSource.value, value)
    }

    const handleSource = (data: any) => {
        if (data !== null) {
            setFilterSource(data)
            fetchClient(1, searchItem, 'change_filter', searchState.value, isClient, isDataUpdated, data.value, excludeTestClientsField)
        }
    }

    useEffect(() => {
        setTitle('Clients | OathZephyr')
        allStatesStore.getAllStates()
        allTagStore.getAllTags()
        if(!isPageOpened){
            fetchClient(1, '', '', 'All', false, false, 'ALL', true)
        }
    }, [userOrg])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Clients
                </div>
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <label className='col-auto mt-2 pr-0'>State:</label>
                            <div className='col-sm-3'>
                                <ReactSelectComponent
                                    isSearchable={true}
                                    options={statesWithAll}
                                    value={searchState}
                                    handleOnChangeReact={handleOnChangeState}
                                />
                            </div>
                            <label className='col-auto mt-2 pr-0'>Source:</label>
                            <div className='col-sm-3'>
                                <ReactSelectComponent
                                    options={optionSourceForFilter}
                                    value={filterSource}
                                    handleOnChangeReact={handleSource}
                                />
                            </div>
                            <div className='col-auto mt-2 ml-auto pr-0 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='is_client' id="isClient" onClick={handleIsClient} defaultChecked={isClientFilter} />
                                <label className="custom-control-label pr-2" htmlFor='isClient'>Is Client</label>
                            </div>
                            <div className='col-md-auto col-auto my-2 mx-3 ml-md-auto pr-0 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='exclude_test_data' id="excludeTestClients" onClick={handleExcludeTestClients} defaultChecked={excludeTestClients} />
                                <label className="custom-control-label pr-2" htmlFor='excludeTestClients'>Exclude Test Clients</label>
                            </div>
                            <div className='col-md-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>
                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'full_name', title: 'Full Name', sort: true },
                                    { name: 'state_id', title: 'State', component: TableCellStateName },
                                    { name: 'source', title: 'Source', component: TableCellSource },
                                    { name: 'user_associated_tags', title: 'Tags', component: TableCellTags },
                                    { name: 'updatedAt', title: 'Updated At', component: FormateDateOnly, sort: true },
                                    { name: 'createdAt', title: 'Created At', component: FormateDateOnly },
                                    { name: 'gender', title: 'View', component: TableCellViewDetails },
                                    { name: 'type', title: 'Action', component: TableCellMatters }
                                ]}
                                data={clientData.page_items.map((item) => {
                                    return { ...item, tagStore, allTagStore, statesWithValueLabel }
                                })}
                                shouldSort={shouldSort}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={clientData.currentPage}
                            totalPages={clientData.totalPage}
                            totalItems={clientData.totalItem}
                            itemsPerPage={clientData.itemPerPage}
                            isFiltered={clientData.isFiltered}
                            totalAllItems={clientData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(ClientsPage)