import React from 'react'
import { Link } from 'react-router-dom'
import { Cell } from '../table/TableCellText.d'

export const EditLabelDecorationCell: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link to='#' onClick={() => { onActionClicked('editLabelDecoration', data, false) }} title='Edit Label Decoration'>Update</Link>
        </>
    )
}

export const DeleteLabelDecorationCell: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link to='#' onClick={() => { onActionClicked('deleteLabelDecoration', data, false) }} title='Delete Label Decoration'>Delete</Link>
        </>
    )
}

export const EditDescriptionInfo: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link to='#' onClick={() => { onActionClicked('editDescriptionInfo', data, false) }} title='Edit Description Info'>Update</Link>
        </>
    )
}

export const DeleteDescriptionInfo: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link to='#' onClick={() => { onActionClicked('deleteDescriptionInfo', data, false) }} title='Delete Description Info'>Delete</Link>
        </>
    )
}

export const EditOptionInfo: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link to='#' onClick={() => { onActionClicked('editOptionInfo', data, false) }} title='Edit Description Info'>Update</Link>
        </>
    )
}


export const DeleteOptionInfo: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link to='#' onClick={() => { onActionClicked('deleteOptionInfo', data, false) }} title='Delete Option Info'>Delete</Link>
        </>
    )
}

export const EditLeftTextInfo: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link to='#' onClick={() => { onActionClicked('editLeftTextInfo', data, false) }} title='Edit Left Text'>Update</Link>
        </>
    )
}

export const EditRightTextInfo: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link to='#' onClick={() => { onActionClicked('editRightTextInfo', data, false) }} title='Edit Right Text'>Update</Link>
        </>
    )
}