import React from 'react'
import FormModal from '../commonComponent/FormModal'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import { ClientDraftAdjustment } from '../../store/types.d'

interface Props {
	showCdaModal: boolean
	modalTitle: string
	note: string
    matterId: string
    targetData?: any
    addUpdateClientDraftAdjustment(clientDraftAdjustment: ClientDraftAdjustment): void
	openCloseCdaModel: () => void
    isActionAdd: boolean
}

const ClientDraftAdjustmentModel: React.FC<Props> = (props) => {
    const { register, handleSubmit, errors } = useForm()
    const { showCdaModal, modalTitle, openCloseCdaModel, addUpdateClientDraftAdjustment, matterId } = props
    const defaultNote = props.targetData.note || ''
    const onSaveModal = (data: any) => {

        if (props.isActionAdd) {
            const requestData = {
                _id: '',
                matter_id: matterId,
                note: data.client_draft_adjustment,
                title: modalTitle
            }
            addUpdateClientDraftAdjustment(requestData)
        } else {
            props.targetData.note = data.note
            const requestData = {
                _id: props.targetData._id,
                matter_id: matterId,
                note: data.client_draft_adjustment,
                title: modalTitle
            }
            addUpdateClientDraftAdjustment(requestData)
        }
        props.openCloseCdaModel()
    }

    return <>
        <FormModal show={showCdaModal} modalTitle={modalTitle} openCloseModal={openCloseCdaModel} modalDialogClass='modal-dialog-centered modal-lg' includeHeader={true}>
            <form onSubmit={handleSubmit(onSaveModal)} >
                <fieldset>
                    <div className='modal-body resource'>
                        <div className='form-group'>
                            <textarea
                                ref={register}
                                name='client_draft_adjustment'
                                defaultValue={defaultNote}
                                style={{height:'300px'}}
                                className={classNames('form-control', { 'is-invalid': errors.client_draft_adjustment })} />
                            {errors.client_draft_adjustment && (<div className='invalid-feedback'>{errors.client_draft_adjustment.message}</div>)}
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseCdaModel}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary">{props.isActionAdd ? 'Add' : 'Update'}</button>
                    </div>
                </fieldset>
            </form>
        </FormModal>
    </>
}

export default ClientDraftAdjustmentModel