import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const addTag = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/tags/tag/add`
    return axios.post(url, requestData)
}

export const getTags = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/tags/`
    return axios.post(url, requestData)
}

export const getTagList = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/tags/all`
    return axios.get(url)
}

export const updateEntityTag = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/update/tags`
    return axios.post(url, requestData)
}