export const handleKeyDownWithOutSpace = (e: any) => {
    if (e.key === ' ') {
        e.preventDefault()
    }
}

export const handleKeyDownWithOutSpaceAtBeginning = (e: any) => {
    if (e.key === ' ' && e.target.value === '') {
        e.preventDefault()
    }
}

export const onChangeTrimValue = (e: any) => {
    e.target.value = e.target.value.trim()
} 

export const onChangeEmail = (e: any) => {
    e.target.value = e.target.value.trim().toLowerCase()
} 