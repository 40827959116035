import React from 'react'
import { Cell } from '../table/TableCellText.d'
import { Link } from 'react-router-dom'

export const TableCellSettings: React.FC<Cell> = (props) => {
    let value = props.value || ''

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
    return (
        <>
            <Link to={`/organizations/settings/${value}`}>Settings</Link>
        </>
    )
}