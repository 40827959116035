import { observable } from 'mobx'
import { SingleFieldForm, StateSpecificDefault, SingleField, ValueLabel, ConditionalOption, ButtonsInField, LabelDecoration } from './types.d'
import StateSpecificDefaultStore from './StateSpecificDefault'
import { RuleGroupType } from 'react-querybuilder'
import ButtonsInFieldStore from './ButtonInField'
import ConditionalOptionStore from './ConditionalOption'
import LabelDecorationStore from './LabelDecoration'


class SingleFieldStore implements SingleFieldForm {
    @observable public _id: string
    @observable public name: string
    @observable public type: string
    @observable public need_grantor_as_option: boolean
    @observable public need_spouse_as_option: boolean
    @observable public contact_form_type: string
    @observable public variable_name: string
    @observable public placeholder: string
    @observable public default_value: string
    @observable public description: string
    @observable public is_dependable_default_answer: boolean
    @observable public state_specific_default: Array<StateSpecificDefaultStore>
    @observable public is_conditional: boolean
    @observable public conditions: RuleGroupType
    @observable public options_string: string
    @observable public options: Array<ValueLabel>
    @observable public is_multiple: boolean
    @observable public is_sub_field: boolean
    @observable public parent_field: string
    @observable public is_hide_from_group_preview: boolean
    @observable public is_hide_from_group_preview_if_unanswered: boolean
    @observable public is_global: boolean
    @observable public is_read_only: boolean
    @observable public is_hidden: boolean
    @observable public isLoading: boolean
    @observable public isApiError: boolean
    @observable public apiErrorMessage: any
    @observable public is_dependable_answer: boolean
    @observable public dependable_answer_condition_id: string
    @observable public default_answer_for_dependable: string
    @observable public default_answer_for_dependable_state_wise: Array<StateSpecificDefaultStore>
    @observable public is_conditional_options: boolean
    @observable public conditional_options: Array<ConditionalOptionStore>
    @observable public show_inline_description: boolean
    @observable public is_add_button: boolean
    @observable public text_transform: string
    @observable public buttons: Array<ButtonsInFieldStore>
    @observable public is_decorate_label: boolean
    @observable public label_decorations: Array<LabelDecorationStore>
    @observable public contact_list_type: string
    @observable public use_for_non_trustees: boolean
    @observable public should_reset: boolean
    @observable public should_reset_following_fields_answers: Array<string>
    @observable public is_answer_required: boolean

    constructor(field: SingleField) {
        this.set(field)
        this.apiErrorMessage = ''
        this.isLoading = false
        this.isApiError = false
    }

    set(field: SingleField): void {
        this._id = field._id || ''
        this.name = field.name
        this.type = field.type
        this.need_grantor_as_option = field.need_grantor_as_option
        this.need_spouse_as_option = field.need_spouse_as_option
        this.contact_form_type = field.contact_form_type
        this.contact_list_type = field.contact_list_type
        this.variable_name = field.variable_name
        this.placeholder = field.placeholder
        this.default_value = field.default_value
        this.is_conditional = field.is_conditional
        this.conditions = field.conditions
        this.is_sub_field = field.is_sub_field
        this.parent_field = field.parent_field
        this.is_hide_from_group_preview = field.is_hide_from_group_preview
        this.is_hide_from_group_preview_if_unanswered = field.is_hide_from_group_preview_if_unanswered
        this.is_global = field.is_global
        this.is_read_only = field.is_read_only
        this.is_hidden = field.is_hidden
        this.description = field.description
        this.show_inline_description = field.show_inline_description
        this.is_multiple = field.is_multiple
        this.options_string = field.options_string
        this.is_dependable_default_answer = field.is_dependable_default_answer
        this.use_for_non_trustees = field.use_for_non_trustees
        this.should_reset = field.should_reset
        this.should_reset_following_fields_answers = field.should_reset_following_fields_answers
        this.is_answer_required = field.is_answer_required
        
        const stateSpecificDefault: Array<StateSpecificDefaultStore> = []
        for (let index = 0; index < field.state_specific_default.length; index++) {
            const data = new StateSpecificDefaultStore(field.state_specific_default[index])
            stateSpecificDefault.push(data)
        }
        this.state_specific_default = stateSpecificDefault

        const stateSpecificDefaultForDependable: Array<StateSpecificDefaultStore> = []
        for (let index = 0; index < field.default_answer_for_dependable_state_wise.length; index++) {
            const data = new StateSpecificDefaultStore(field.default_answer_for_dependable_state_wise[index])
            stateSpecificDefaultForDependable.push(data)
        }
        this.default_answer_for_dependable_state_wise = stateSpecificDefaultForDependable
        
        this.is_dependable_answer = field.is_dependable_answer
        this.dependable_answer_condition_id = field.dependable_answer_condition_id
        this.default_answer_for_dependable = field.default_answer_for_dependable
        this.description = field.description

        this.is_conditional_options = field.is_conditional_options
        const conditionalOption: Array<ConditionalOptionStore> = []
        for (let index = 0; index < field.conditional_options.length; index++) {
            const data = new ConditionalOptionStore(field.conditional_options[index])
            conditionalOption.push(data)
        }
        this.conditional_options = conditionalOption

        this.is_add_button = field.is_add_button
        this.text_transform = field.text_transform
        const buttonsInField: Array<ButtonsInFieldStore> = []
        for (let index = 0; index < field.buttons.length; index++) {
            const data = new ButtonsInFieldStore(field.buttons[index])
            buttonsInField.push(data)
        }
        this.buttons = buttonsInField

        this.is_decorate_label = field.is_decorate_label
        const labelDecoration: Array<LabelDecorationStore> = []
        for (let index = 0; index < field.label_decorations.length; index++) {
            const data = new LabelDecorationStore(field.label_decorations[index])
            labelDecoration.push(data)
        }
        this.label_decorations = labelDecoration
    }

    get(): SingleField {
        const stateSpecificDefault: Array<StateSpecificDefault> = []
        for (let index = 0; index < this.state_specific_default.length; index++) {
            const data = this.state_specific_default[index]
            stateSpecificDefault.push(data.get())
        }

        const stateSpecificDefaultForDependable: Array<StateSpecificDefault> = []
        for (let index = 0; index < this.default_answer_for_dependable_state_wise.length; index++) {
            const data = this.default_answer_for_dependable_state_wise[index]
            stateSpecificDefaultForDependable.push(data.get())
        }

        const conditionalOption: Array<ConditionalOption> = []
        for (let index = 0; index < this.conditional_options.length; index++) {
            const data = this.conditional_options[index]
            conditionalOption.push(data.get())
        }

        const buttonsInField: Array<ButtonsInField> = []
        for (let index = 0; index < this.buttons.length; index++) {
            const data = this.buttons[index]
            buttonsInField.push(data.get())
        }

        const labelDecorations: Array<LabelDecoration> = []
        for (let index = 0; index < this.label_decorations.length; index++) {
            const data = this.label_decorations[index]
            labelDecorations.push(data.get())
        }

        return {
            _id: this._id,
            name: this.name,
            type: this.type,
            need_grantor_as_option: this.need_grantor_as_option,
            need_spouse_as_option: this.need_spouse_as_option,
            contact_form_type: this.contact_form_type,
            variable_name: this.variable_name,
            placeholder: this.placeholder,
            default_value: this.default_value,
            description: this.description,
            is_dependable_default_answer: this. is_dependable_default_answer,
            state_specific_default: stateSpecificDefault,
            options: this.options,
            is_conditional: this.is_conditional,
            conditions: this.conditions,
            is_multiple: this.is_multiple,
            options_string: this.options_string,
            is_sub_field: this.is_sub_field,
            parent_field: this.parent_field,
            is_hide_from_group_preview: this.is_hide_from_group_preview,
            is_hide_from_group_preview_if_unanswered: this.is_hide_from_group_preview_if_unanswered,
            is_global: this.is_global,
            is_read_only: this.is_read_only,
            is_hidden: this.is_hidden,
            is_dependable_answer: this.is_dependable_answer,
            default_answer_for_dependable: this.default_answer_for_dependable,
            default_answer_for_dependable_state_wise: stateSpecificDefaultForDependable,
            dependable_answer_condition_id: this.dependable_answer_condition_id,
            is_conditional_options: this.is_conditional_options,
            conditional_options: conditionalOption,
            show_inline_description: this.show_inline_description,
            is_add_button: this.is_add_button,
            text_transform: this.text_transform,
            buttons: buttonsInField,
            is_decorate_label: this.is_decorate_label,
            label_decorations: labelDecorations,
            contact_list_type: this.contact_list_type,
            use_for_non_trustees: this.use_for_non_trustees,
            should_reset: this.should_reset,
            should_reset_following_fields_answers: this.should_reset_following_fields_answers,
            is_answer_required: this.is_answer_required
        }
    }

    addNewStateSpecific(isDepend: boolean, defaultValue: string, state: string, label: string, condition_id?: string, condition_name?: string): void {
        const data = {
            default_value: defaultValue,
            state: state,
            label: label,
            condition_id: condition_id,
            condition_name: condition_name
        }
        const stateSpecificDefaultValue = new StateSpecificDefaultStore(data)
        if (!isDepend) {
            this.state_specific_default.push(stateSpecificDefaultValue)
        } else {
            delete data.condition_id
            delete data.condition_name
            this.default_answer_for_dependable_state_wise.push(stateSpecificDefaultValue)
        }
    }

    addNewConditionalOption(optionsString: string, conditionId: string, conditionName: string): void {
        const data = {
            condition_id: conditionId,
            condition_name: conditionName,
            options_string: optionsString,
        }
        const conditionalOptions = new ConditionalOptionStore(data)
        this.conditional_options.push(conditionalOptions)
    }

    addNewButton(label: string, actionString: string, isAllPackages: boolean, packages: Array<string>): void {
        const data = {
            label: label,
            action_string: actionString,
            is_all_packages: isAllPackages,
            packages: packages
        }
        const button = new ButtonsInFieldStore(data)
        this.buttons.push(button)
    }

    deleteButton(button: ButtonsInFieldStore): void {
        this.buttons = this.buttons.filter((item) => item !== button)
    }

    addNewLabelDecoration(scope: string, find: string, replace: string): void {
        const data = {
            scope: scope,
            find: find,
            replace: replace,
        }
        const labelDecoration = new LabelDecorationStore(data)
        this.label_decorations.push(labelDecoration)
    }

    deleteLabelDecoration(labelDecoration: LabelDecorationStore): void {
        this.label_decorations = this.label_decorations.filter((item) => item !== labelDecoration)
    }
}

export default SingleFieldStore