import { observable } from 'mobx'
import ParagraphStore from './Paragraph'
import SectionStore from './Section'
import { Article, Section, Paragraph } from './types.d'

class ArticleStore implements Article {
    @observable public name: string
    @observable public description: string
    @observable public keywords: Array<string>
    @observable public is_all_states: boolean
    @observable public type: string
    @observable public states: Array<string>
    @observable public content: Array<ParagraphStore | SectionStore>

    constructor(article: Article) {
        this.name = '',
        this.description = ''
        this.keywords = []
        this.is_all_states = false
        this.type = ''
        this.states = []
        this.content = []
        this.set(article)
    }

    set(article: Article): void {
        this.name = article.name
        this.description = article.description
        this.keywords = article.keywords || []
        this.is_all_states = article.is_all_states
        this.type = article.type
        this.states = article.states

        const content: Array<ParagraphStore | SectionStore> = []
        if (article.content) {
            for (let index = 0; index < article.content.length; index++) {
                if (article.content[index].type === 'paragraph') {
                    const paragraph = new ParagraphStore(article.content[index])
                    content.push(paragraph)
                } else if (article.content[index].type === 'section') {
                    const section = new SectionStore(article.content[index])
                    content.push(section)
                }
            }
        }
        this.content = content
    }

    get(): Article {
        const content: Array<Paragraph | Section> = []
        for (let index = 0; index < this.content.length; index++) {
            const data = this.content[index]
            content.push(data.get())
        }

        return {
            name: this.name,
            description: this.description,
            keywords: this.keywords,
            is_all_states: this.is_all_states,
            type: this.type,
            states: this.states,
            content: content
        }
    }

    addNewParagraph(title: string, description: string, body: string): void {
        const data = {
            name: title,
            description: description,
            is_all_states: true,
            states: [],
            body: body,
            type: 'paragraph'
        }
        const paragraph = new ParagraphStore(data)
        this.content.push(paragraph)
    }

    addNewSection(title: string, description: string): void {
        const data = {
            name: title,
            description: description,
            is_all_states: true,
            states: [],
            content: [],
            type: 'section'
        }
        const section = new SectionStore(data)
        this.content.push(section)
    }

    remove(removeItem: ParagraphStore | SectionStore) {
        const filteredData = this.content.filter(item => item !== removeItem)
        this.content = filteredData
    }
}

export default ArticleStore