import React from 'react'
import FormModal from '../../commonComponent/FormModal'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import EngagementTemplateStore from '../../../store/vision/EngagementTemplate'

interface Props {
	targetData?: any
	showModel: boolean
	modalTitle: string
	engagementTemplateStore: EngagementTemplateStore
	openCloseForm: () => void
}

const TextInfoModel: React.FC<Props> = (props) => {

    const scrollableBodyStyle = 'modal-body resource'
    const { register, handleSubmit, errors } = useForm()

    const onSaveModal = (data: any) => {
        if (props.modalTitle.includes('Add')) {
            props.engagementTemplateStore.addNewTextInfo(data.single_text.trim(), data.married_text.trim(), props.modalTitle)
        } else {
            props.targetData.single_text = data.single_text.trim()
            props.targetData.married_text = data.married_text.trim()
        }
        props.openCloseForm()
    }

    return <>
        <FormModal show={props.showModel} modalDialogClass='modal-lg' modalTitle={props.modalTitle} openCloseModal={props.openCloseForm}>

            <form onSubmit={handleSubmit(onSaveModal)} >
                <fieldset>
                    <div className={scrollableBodyStyle} >
                        <div className='ml-2'>

                            <div className='form-group'>
                                <label>Single Text</label>
                                <textarea
                                    ref={register}
                                    style={{height:'200px'}}
                                    name='single_text'
                                    defaultValue={props.targetData.single_text}
                                    className={classNames('form-control', { 'is-invalid': errors.single_text })}
                                    placeholder='Enter Single Text'
                                />
                                {errors.single_text && (<div className='invalid-feedback'>{errors.single_text.message}</div>)}
                            </div>
                            <div className='form-group'>
                                <label>Married Text</label>
                                <textarea
                                    ref={register}
                                    style={{height:'200px'}}
                                    name='married_text'
                                    defaultValue={props.targetData.married_text}
                                    className={classNames('form-control', { 'is-invalid': errors.married_text })}
                                    placeholder='Enter Married Text'
                                />
                                {errors.married_text && (<div className='invalid-feedback'>{errors.married_text.message}</div>)}
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={props.openCloseForm}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary">{props.modalTitle.includes('Add') ? 'Add' : 'Update'}</button>
                    </div>
                </fieldset>
            </form>
        </FormModal>
    </>
}

export default TextInfoModel