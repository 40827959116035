import axios, { AxiosResponse } from 'axios'

export const generateXeroToken = (token: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/xero/connect`
    return axios.post(url, { token: token })
}

export const setEmptyXeroToken = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/xero/disconnect`
    return axios.post(url)
}

export const getXeroToken = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/xero/tokens/`
    return axios.get(url)
}
