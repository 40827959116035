import React from 'react'
import { Cell } from '../table/TableCellText.d'
import { Link } from 'react-router-dom'
import uuid from 'react-uuid'

export const TableCellMatters: React.FC<Cell> = (props) => {
    let value = props.data._id || ''

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
    return (
        <>
            <Link key={uuid()} to={`/clients/${value}`}>Matters</Link>
        </>
    )
}
