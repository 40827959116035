import { observable } from 'mobx'
import { StateSpecificDefault } from './types.d'


class StateSpecificDefaultStore implements StateSpecificDefault {
    @observable public default_value: string
    @observable public state: string
    @observable public label: string
    @observable public condition_id: string | undefined
    @observable public condition_name: string | undefined

    constructor(stateSpecificDefault: StateSpecificDefault) {
        this.set(stateSpecificDefault)
    }

    set(stateSpecificDefault: StateSpecificDefault) {
        this.default_value = stateSpecificDefault.default_value
        this.state = stateSpecificDefault.state
        this.label = stateSpecificDefault.label
        this.condition_id = stateSpecificDefault.condition_id || undefined
        this.condition_name = stateSpecificDefault.condition_name || undefined
    }

    get(): StateSpecificDefault {
        return {
            default_value: this.default_value,
            state: this.state,
            label: this.label,
            condition_id: this.condition_id,
            condition_name: this.condition_name
        }
    }
}

export default StateSpecificDefaultStore