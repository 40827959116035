import React, { useEffect } from 'react'
import RootStore from '../store/Root'
import Container from './layout/Container'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import {TableLoader} from './commonComponent/Loader'

interface Props {
  rootStore: RootStore
}

const ViewProfilePage: React.FC<Props> = ({ rootStore }) => {
    const { superAdminStore, setTitle } = rootStore
    const { isApiError, isLoading, apiErrorMessage, superAdminData, fetchCurrentUserData } = superAdminStore

    useEffect(() => {
        setTitle('Profile | OathZephyr')
        fetchCurrentUserData()
    }, [fetchCurrentUserData, setTitle])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
        Profile
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col col-md-8'>
                        {isLoading && <TableLoader isLoading={isLoading} />}
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}
                        <form >
                            <fieldset>

                                <div className="form-group">
                                    <label>First Name</label>
                                    <input
                                        name='first_name'
                                        type='text'
                                        readOnly={true}
                                        value={superAdminData.first_name}
                                        className={classNames('form-control')}
                                        placeholder='First Name'
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input
                                        name='last_name'
                                        type='text'
                                        readOnly={true}
                                        value={superAdminData.last_name}
                                        className={classNames('form-control')}
                                        placeholder='Last Name'
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        name='email'
                                        type='text'
                                        readOnly={true}
                                        value={superAdminData.email}
                                        className={classNames('form-control')}
                                        placeholder='Email'
                                    />
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(ViewProfilePage)
