import React, { useEffect, useState } from 'react'
import RootStore from '../../../store/Root'
import Container from '../../layout/Container'
import { observer } from 'mobx-react'
import Table from '../../table/Table'
import Pagination from '../../table/Pagination'
import { Cell } from '../../table/TableCellText.d'
import { debounceSearch } from '../../../utils/debounce'
import { NumberToPriceFormatter } from '../vision/TableCellAmount'

interface Props {
    rootStore: RootStore,
}

const TableCellValue: React.FC<Cell> = (props) => {
    const newDate = props.value.split('T')
    const formatDate = newDate[0].split('-')
    const dateString = formatDate[1] + '/' + formatDate[2] + '/' + formatDate[0]

    return (
        <>
            {props.value ? dateString : '' }
        </>
    )
}

const TableCellShowError: React.FC<Cell> = (props) => {

    return (
        <>
            {props.value ? <div><strong>Message: </strong>  {props.value}<br/><strong>Probable: </strong>{props.data.client_name}</div> : '' }
        </>
    )
}

const InvoiceURL: React.FC<Cell> = (props) => {

    return (
        <>
            {props.value ? <a href={`https://go.xero.com/organisationlogin/default.aspx?shortcode=${props.data.xeroOrgCode}&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=${props.data.id}`} target="_blank" rel="noopener noreferrer">{props.value}</a> : '' }
        </>
    )
}

const InvoiceInformationPage: React.FC<Props> = ({ rootStore }) => {
    const { invoiceInfoStore, authStore, setTitle, allOrgStore } = rootStore
    const { isAdmin, userOrg } = authStore
    const { invoiceInformationData,  fetchInvoice, searchItem, isApiError, isLoading, apiErrorMessage, isPageOpened, excludeTestInvoices } = invoiceInfoStore
    const [searchItemField, setSearchItemField] = useState(searchItem)
    const [excludeTestInvoicesField, setExcludeTestInvoicesField] = useState(excludeTestInvoices)
    const [xeroOrgCode, setXeroOrgCode] = useState('')
    const loadPage = (page: number) => {
        fetchInvoice(page, searchItem, excludeTestInvoicesField)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchInvoice(1, e.target.value.trim(), excludeTestInvoicesField))
    }

    const handleExcludeTestInvoices = (e: any) => {
        const value = e.target.checked
        setExcludeTestInvoicesField(value)
        fetchInvoice(1, searchItem, value)
    }
    const getOrgCode = (id: string) => {
        const org = allOrgStore.orgList.find((org) => org._id === id)
        setXeroOrgCode(org?.xero_configuration.organization_code || '')
        console.log(org?.xero_configuration.organization_code, 'org ccode')
    }

    useEffect(() => {
        setTitle('Invoices | OathZephyr')
        if(isAdmin && !isPageOpened){
            fetchInvoice(1, '', true)
        }
        getOrgCode(userOrg.value)
    }, [userOrg])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <div className='col'><h3>Invoice</h3></div>
                            <div className='col-auto my-2 ml-auto pr-0 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='exclude_test_data' id="excludeTestInvoices" onClick={handleExcludeTestInvoices} defaultChecked={excludeTestInvoicesField} />
                                <label className="custom-control-label pr-2" htmlFor='excludeTestInvoices'>Exclude Test Invoice</label>
                            </div>
                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {((isApiError) && <div className='responsive alert-danger p-3 mb-4 rounded' >{apiErrorMessage}</div>)}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'number', title: 'Number', component: InvoiceURL },
                                    { name: 'contact_name', title: 'Contact' },
                                    { name: 'description', title: 'Description' },
                                    { name: 'attorney_code', title: 'Attorneys' },
                                    { name: 'amount', title: 'Amount', component: NumberToPriceFormatter },
                                    { name: 'issue_date', title: 'Issue Date', component: TableCellValue },
                                    { name: 'due_date', title: 'Due Date', component: TableCellValue },
                                    { name: 'error_message', title: 'Error Message', component: TableCellShowError }
                                ]}
                                data={invoiceInformationData.page_items.map((item) => {
                                    return { ...item, xeroOrgCode }
                                })}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={invoiceInformationData.currentPage}
                            totalPages={invoiceInformationData.totalPage}
                            totalItems={invoiceInformationData.totalItem}
                            itemsPerPage={invoiceInformationData.itemPerPage}
                            isFiltered={invoiceInformationData.isFiltered}
                            totalAllItems={invoiceInformationData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(InvoiceInformationPage)
