import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import { Props } from '../../store/types.d'
import Editor from '@monaco-editor/react'

const StylePDF: React.FC<Props> = ({ rootStore }) => {

    const { handleSubmit } = useForm()
    const history = useHistory()

    const { settingStore, authStore, setTitle } = rootStore
    const { isAdmin } = authStore
    const { updateStylePDF, isApiError, isLoading, apiErrorMessage, style_pdf, fetchSettings, isPageOpened } = settingStore
    const defaultEditorValue = style_pdf != undefined ? style_pdf : ''
    const [newStyle, setStyle] = useState(defaultEditorValue)

    const handleClick = () => {
        history.push('/dashboard')
    }

    useEffect(() => {
        setTitle('PDF Style | OathZephyr')
        if (style_pdf == undefined) {
            fetchSettings()
        }
    }, [fetchSettings, style_pdf, newStyle, setTitle])

    const onSubmit = async () => {
        const response = await updateStylePDF(newStyle)
        if (response == 'true') {
            history.push('/dashboard')
        }
    }

    const onStyleChange = (e: any) => {
        setStyle(e)
    }

    useEffect(() => {
        if(isAdmin && !isPageOpened){
            fetchSettings()
        }
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                PDF file CSS Style
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        <form onSubmit={handleSubmit(onSubmit)} >
                            <fieldset>
                                <label className='mb-0 mt-2'>PDF Style</label>
                                <div className='form-group'>
                                    <Editor
                                        height="50vh"
                                        defaultLanguage='css'
                                        value={style_pdf}
                                        onChange={onStyleChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                        {isLoading ? 'Loading...' : 'Save'}
                                    </button>&nbsp;&nbsp;
                                    {/* <button type='button' className='btn btn-outline-success' disabled={isLoading} onClick={onReset}>Reset</button>
                                &nbsp;&nbsp; */}
                                    <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(StylePDF)