import { observable } from 'mobx'
import { LabelDecoration } from './types.d'


class LabelDecorationStore implements LabelDecoration {
    @observable public scope: string
    @observable public find: string
    @observable public replace: string

    constructor(label_decorations: LabelDecoration) {
        this.scope = ''
        this.find = ''
        this.replace = ''
        this.set(label_decorations)
    }

    set(label_decorations: LabelDecoration): void {
        this.scope = label_decorations.scope
        this.find = label_decorations.find
        this.replace = label_decorations.replace
    }

    get(): LabelDecoration {
        return {
            scope: this.scope,
            find: this.find,
            replace: this.replace
        }
    }
}

export default LabelDecorationStore