import React from 'react'
import dompurify from 'dompurify'
import { Preview } from '../commonComponent/PreviewStyle'

interface RenderHTMLProps {
    // children?: any
    className?: string
}

const RenderRawHtml: React.FC<RenderHTMLProps> = ({children, className = ''}): any => {
    const sanitizer = dompurify.sanitize
    const html = sanitizer(children)
    return <>
        <Preview>
            <div className={className} dangerouslySetInnerHTML={{ __html: html}} />
        </Preview>
    </>
}

export default RenderRawHtml