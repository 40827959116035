import { action, observable } from 'mobx'
import RootStore from './Root'
import { getOrganizationList } from '../api/OrganizationAction'
import { OrganizationInformation, ValueLabel } from './types.d'

class AllOrgStore {
    public rootStore: RootStore
    @observable public isLoading = false
    @observable public isLoaded = false
    @observable public orgList: Array<OrganizationInformation> = []
    @observable public orgListValueLabel: Array<ValueLabel> = []

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        console.log('con1')
        this.orgList = []
        this.orgListValueLabel = []
        console.log('con2')
        if (rootStore.authStore.isUserLoggedIn) {
            this.getAllOrgs()
        }
    }

    @action
    public getAllOrgs() {
        if (this.isLoaded === false) {
            this.reload()
        }
    }

    @action
    public forceReload() {
        this.reload()
    }

    public findOrg(orgId) {
        console.log('con3')
        const list = this.orgList.find((org) => org._id === orgId)
        console.log(list)
        return list
    }

    @action
    public reload() {
        this.isLoading = true
        getOrganizationList().then((response) => {
            this.orgList = response.data.data
            this.orgListValueLabel = response.data.data.map((item) => {
                return {
                    value: item._id,
                    label: item.name
                }
            })
            this.isLoaded = true
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
        }).finally(() => {
            this.isLoading = false
        })
    }
}

export default AllOrgStore
