import React from 'react'
import NavBar from './NavBar'
import SideBar from './SideBar'
import UpdateTempSideBar from '../template/UpdateTempSidebar'
import RootStore from '../../store/Root'
import { Redirect, useHistory } from 'react-router-dom'
import UpdateTempateNavbar from '../template/UpdateTempNavbar'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface Props {
    rootStore: RootStore,
    redirectIfNotLoggedIn?: boolean
    hideTopNavBar?: boolean
}

const Container: React.FC<Props> = (props) => {
    const rootStore = props.rootStore
    const history = useHistory()
    const url = history.location.pathname
    const isUpdateTemplate = url.includes('/templates/update/') || url.includes('document')

    const hideTopNavBar = props.hideTopNavBar ?? false

    if (props.redirectIfNotLoggedIn === true) {
        if (rootStore.authStore.isUserLoggedIn === false) {
            return <Redirect to="/login" />
        }
    }

    return <>
        {!hideTopNavBar && <NavBar rootStore={rootStore} />}
        <div className='container-fluid'>
            <div className='row'>
                {(isUpdateTemplate) ? <>
                    <UpdateTempSideBar rootStore={rootStore} />
                    <UpdateTempateNavbar rootStore={rootStore} />
                </>
                    :
                    <SideBar rootStore={rootStore} />
                }

                <main className='col-md-9 ml-sm-auto col-lg-10 px-md-4 mt-3 '>
                    {(isUpdateTemplate) ?
                        <div className='main-content-box'>
                            {props.children}
                        </div> : <> {props.children} </>}
                </main>
            </div>
        </div>
        <ToastContainer
            position='top-center'
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover
        />
    </>
}

export default Container