import React, { useEffect } from 'react'
import RootStore from '../store/Root'
import Container from './layout/Container'
import { observer } from 'mobx-react'

interface Props {
  rootStore: RootStore
}

const DashboardPage: React.FC<Props> = ({ rootStore }) => {

    const { setTitle } = rootStore

    useEffect(() => {
        setTitle('Dashboard | OathZephyr')
    }, [setTitle])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
        Dashboard
            </div>
            <div className="card-body">
        
            </div>
        </div>
    </Container >
}

export default observer(DashboardPage)