import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const addPackage = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/packages/add`
    return axios.post(url, requestData)
}

export const getPackages = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/packages/`
    return axios.post(url, requestData)
}

export const getPackageDetails = (packageId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/packages/package/${packageId}`
    return axios.post(url)
}

export const getPackagesNameId = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/packages/all/name-id`
    return axios.post(url)
}

export const updatePackage = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/packages/update`
    return axios.post(url, requestData)
}