import { action, observable } from 'mobx'
import RootStore from './Root'

class DebugStore {
    public rootStore: RootStore
    @observable public matterId: string
    @observable public templateId: string

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.matterId = ''
        this.templateId = ''
    }

    @action
    setMatterId(matterId: string) {
        console.log({matterId})
        if (matterId != undefined && matterId != '' && matterId.length == 24) {
            console.log({matterId})
            this.matterId = matterId
        }
    }
    
    @action
    setTemplateId(templateId: string) {
        this.templateId = templateId
    }
}

export default DebugStore

