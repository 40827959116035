import React, { useEffect, useState } from 'react'
import RootStore from '../store/Root'
import { observer } from 'mobx-react'
import Container from './layout/Container'
import { generateLawmaticsToken, getLawmaticsToken, setEmptyLawmaticsToken } from '../api/ConnectToLamaticsActions'
import { UpworkJobLoader } from './commonComponent/DotLoader'
import { useHistory } from 'react-router-dom'


interface Props {
  rootStore: RootStore,
}

const ConnectToLawmaticsPage: React.FC<Props> = ({ rootStore }) => {

    const [apiErrorMessage, setApiErrorMessage] = useState('')
    const [isApiError, setIsApiError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [newLawmaticsToken, setNewLawmaticsToken] = useState(false)

    const [tokenData, setTokenData] = useState({
        access_token: ''
    })

    const [clientId, setClientId] = useState('')

    const history = useHistory()
    const params = new URLSearchParams(window.location.search)

    const code = params.get('code')

    const { setTitle } = rootStore

    useEffect(() => {
        setTitle('Connect To Lawmatics | OathZephyr')
        try {
            const tokens = async () => {

                setIsLoading(true)
                setIsApiError(false)
                setApiErrorMessage('')
                const detail = await getLawmaticsToken()

                if (detail.data.success === 0) {
                    setIsApiError(true)
                    setApiErrorMessage(detail.data.message)
                    setIsLoading(false)
                } else {
                    const tokenInfo = detail.data.data
                    setTokenData({
                        access_token: tokenInfo.access_token
                    })
                    setClientId(tokenInfo.client_id)
          
                    if (tokenInfo.access_token === '' && code !== null) {
                        try {
                            const generatedToken = await generateLawmaticsToken(code)
                            setIsLoading(false)
                            if (generatedToken.data.success === 0) {
                                setIsApiError(true)
                                setApiErrorMessage(generatedToken.data.message)
                                
                            } else {
                                setNewLawmaticsToken(true)
                            }
                        } catch (error) {
                            console.log(error, 'error')
                            setIsApiError(true)
                            setApiErrorMessage('Error in xero connection')
                            setIsLoading(false)
                            console.log(error)
                        }
                    }
                    setIsLoading(false)
                }
            }
            tokens()
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }

    }, [code, setTitle])

    const handleOnClick = async () => {
        setIsLoading(true)
        setIsApiError(false)
        setApiErrorMessage('')

        try {
            if (code !== null) {
                params.delete('code')
                history.replace({
                    search: params.toString(),
                })
            }

            const response = await setEmptyLawmaticsToken()
            setTokenData({
                access_token: ''
            })

            setNewLawmaticsToken(false)
            setIsLoading(false)

            if (response.data.success === 0) {
                setIsApiError(true)
                setApiErrorMessage(response.data.message)
            }

        } catch (e) {
            setIsLoading(false)
            setIsApiError(true)
            setApiErrorMessage(e.message)
        }
    }
    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                LawMatics Integration
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}
                        {!isLoading ? <>
                            {tokenData.access_token !== null && (tokenData.access_token === '' && newLawmaticsToken === false) ? <a className='btn btn-outline-primary mr-2' href={`https://app.lawmatics.com/oauth/authorize?client_id=${clientId}&redirect_uri=${process.env.REACT_APP_LAWMATICS_READIRECT_URL}&response_type=code`}>Connect</a> : <button className='btn btn-outline-primary' onClick={handleOnClick}>Disconnect</button>
                            }</> : <UpworkJobLoader />}

                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(ConnectToLawmaticsPage)