import { observable } from 'mobx'
import { SingleMarriedText } from '../types.d'


class TextInfoStore implements SingleMarriedText {
    @observable public single_text: string
    @observable public married_text: string

    constructor(text_info: SingleMarriedText) {
        this.single_text = ''
        this.married_text = ''
        this.set(text_info)
    }

    set(text_info: SingleMarriedText): void {
        this.single_text = text_info.single_text
        this.married_text = text_info.married_text
    }

    get(): SingleMarriedText {
        return {
            single_text: this.single_text,
            married_text: this.married_text
        }
    }
}

export default TextInfoStore