import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../../store/types.d'

export const getVisions = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/visions/`
    return axios.post(url, requestData)
}

export const getSingleVisionDetails = (visionId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/visions/vision/${visionId}`
    return axios.post(url)
}