import React from 'react'
import { Cell } from './TableCellText.d'
import { Link, useHistory } from 'react-router-dom'

export const TableCellAction: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const history = useHistory()

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
    const path = history.location.pathname.split('/')
    return (
        <>
            <Link to={`/${path[1]}/update/${value}`}>Update</Link>
        </>
    )
}

export const TableCellActionVision: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const history = useHistory()

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
    const path = history.location.pathname.split('/')
    return (
        <>
            <Link to={`/${path[1]}/${path[2]}/update/${value}`}>Update</Link>
        </>
    )
}

export const TableCellActionViewVision: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const history = useHistory()

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
    const path = history.location.pathname.split('/')
    return (
        <>
            <Link to={`/visions/vision${path[2]}/${value}`}>View Details</Link>
        </>
    )
}

export const TemplatePreviewButton: React.FC<Cell> = (props) => {
    let value = props.value || ''
  
    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
    return (
        <>
            <Link to={`/templates/update/preview/${value}`} className='mr-2' >Update</Link>
            <Link to={`/templates/manage/fields/${value}`} >Manage Fields</Link>
        </>
    )
}