import React, { useEffect, useState } from 'react'
import FormModal from '../../commonComponent/FormModal'
import { Controller, useForm } from 'react-hook-form'
import classNames from 'classnames'
import Select from 'react-select'
import PlanStore from '../../../store/vision/Plan'
import { ValueLabel } from '../../../store/types.d'

interface Props {
	targetData?: any
	showModel: boolean
	modalTitle: string
	planStore: PlanStore
    options: Array<ValueLabel>
    imageTypeOption: Array<ValueLabel>
	openCloseForm: () => void
}

const initialValueLabel = {
    value: 'all_control_diagram',
    label: 'All control Diagram'
}

const DescriptionInfoModel: React.FC<Props> = (props) => {
    const [ type, setType ] = useState({ value: 'TEXT', label: 'Text'})
    const [imageType, setImageType] = useState(initialValueLabel)

    useEffect(() => {
        const selectedType = props.options.find((item: ValueLabel) => props.targetData.type === item.value)
        if (props.modalTitle.includes('Edit') && selectedType != undefined) {
            setType(selectedType)
        }

        const selectedImageText = props.imageTypeOption.find((item: ValueLabel) => props.targetData.image === item.value)
        if (props.modalTitle.includes('Edit') && selectedImageText != undefined) {
            setImageType(selectedImageText || initialValueLabel)
        }
    }, [ props.options, props.imageTypeOption, props.targetData, props.modalTitle ])

    const scrollableBodyStyle = 'modal-body resource'
    const { register, handleSubmit, errors, control } = useForm()
    
    // const options = props.options

    const onSaveModal = (data: any) => {
        let imageTypeText = imageType.value
        if (type.value === 'TEXT') {
            imageTypeText = ''
        } 
        if (props.modalTitle.includes('Add')) {
            props.planStore.addNewDescriptionInfo(type.value, data.text.trim(), imageTypeText)
        } else {
            props.targetData.type = type.value
            props.targetData.text = data.text.trim()
            props.targetData.image = imageTypeText
        }
        props.openCloseForm()
    }

    const handleOnChange = (val: ValueLabel) => {
        setType(val)
    }

    const handleOnChangeImageType = (val: ValueLabel) => {
        setImageType(val)
    }

    return <>
        <FormModal show={props.showModel} modalTitle={props.modalTitle} openCloseModal={props.openCloseForm}>

            <form onSubmit={handleSubmit(onSaveModal)} >
                <fieldset>
                    <div className={scrollableBodyStyle} >
                        <div className='ml-2'>
                            <div className='form-group'>
                                <label>Select Type</label>
                                <Controller
                                    control={control}
                                    name="type"
                                    defaultValue={type}
                                    render={({ ref }) => (
                                        <Select
                                            inputRef={ref}
                                            value={type}
                                            options={props.options}
                                            className={(errors.type) ? 'is-invalid' : ''}
                                            classNamePrefix="type"
                                            onChange={(val: any) => {
                                                handleOnChange(val)
                                            }}
                                        />)}
                                    rules={{
                                        validate: (value) => (value === undefined) ? 'Please select type' : true
                                    }}
                                />
                                {(errors.type) && (<div className='invalid-feedback'>{errors.type.message}</div>)}
                            </div>
                            <div className={classNames('form-group', {
                                'd-none': type.value == 'IMAGE'
                            })}>
                                <label>Text</label>
                                <textarea
                                    ref={register}
                                    name='text'
                                    defaultValue={props.targetData.text}
                                    className={classNames('form-control', { 'is-invalid': errors.text })}
                                    placeholder='Enter Text'
                                />
                                {errors.text && (<div className='invalid-feedback'>{errors.text.message}</div>)}
                            </div>

                            <div className={classNames('form-group', {
                                'd-none': type.value == 'TEXT'
                            })}>
                                <label>Image</label>
                                <Controller
                                    control={control}
                                    name="image"
                                    defaultValue={imageType}
                                    render={({ ref }) => (
                                        <Select
                                            inputRef={ref}
                                            value={imageType}
                                            options={props.imageTypeOption}
                                            className={(errors.image) ? 'is-invalid' : ''}
                                            classNamePrefix="image"
                                            onChange={(val: any) => {
                                                handleOnChangeImageType(val)
                                            }}
                                        />)}
                                    rules={{
                                        validate: (value) => (value === undefined) ? 'Please select image type' : true
                                    }}
                                />
                                {(errors.image) && (<div className='invalid-feedback'>{errors.image.message}</div>)}
                            </div>
                            
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={props.openCloseForm}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary">{props.modalTitle.includes('Add') ? 'Add' : 'Update'}</button>
                    </div>
                </fieldset>
            </form>
        </FormModal>
    </>
}

export default DescriptionInfoModel