import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import { Props } from '../../store/types.d'
import { handleKeyDownWithOutSpaceAtBeginning } from '../commonComponent/WithoutAcceptSpace'
import classNames from 'classnames'
import { toast } from 'react-toastify'

const AppSetting: React.FC<Props> = ({ rootStore }) => {

    const { handleSubmit, register, errors } = useForm()
    const history = useHistory()

    const { settingStore, authStore, setTitle } = rootStore
    const { isAdmin } = authStore
    const { updateAppDetails, isApiError, isLoading, apiErrorMessage, app_version, app_build, testflight_app_version, testflight_app_build, fetchSettings, isPageOpened } = settingStore

    const handleClick = () => {
        history.push('/dashboard')
    }

    useEffect(() => {
        setTitle('iPad Settings | OathZephyr')
        if (app_version == undefined) {
            fetchSettings()
        }
    }, [fetchSettings, app_version, setTitle])

    const onSubmit = async (data: any) => {
        const response = await updateAppDetails({ app_build: data.app_build, app_version: data.app_version, testflight_app_build: data.testflight_app_build, testflight_app_version: data.testflight_app_version })
        if (response == 'true') {
            toast.success('Data updated successfully')
        }
    }

    useEffect(() => {
        if(isAdmin && !isPageOpened){
            fetchSettings()
        }
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                iPad App Details
            </div>

            <div className="card-body">
                {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                    {apiErrorMessage}
                </div>}
                <form onSubmit={handleSubmit(onSubmit)} >
                    <fieldset>
                        <div className='row'>
                            <div className='card col-auto ml-3' style={{ width: '25rem' }}>
                                <h5 className='card-header'>Live</h5>
                                <div className='card-body'>
                                    <label>App Version *</label>
                                    <input
                                        ref={register({
                                            required: 'This field is required',
                                            validate: (value: string) => (value.trim()) ? true : 'This field is required'
                                        })}
                                        name='app_version'
                                        type='number'
                                        step='0.1'
                                        min='0.1'
                                        onKeyDown={handleKeyDownWithOutSpaceAtBeginning}
                                        className={classNames('form-control', { 'is-invalid': errors.app_version })}
                                        defaultValue={app_version}
                                    />
                                    {errors.app_version && (<div className='invalid-feedback'>{errors.app_version.message}</div>)}
                                    <label className='mt-3'>App Build *</label>
                                    <input
                                        ref={register({
                                            required: 'This field is required',
                                            validate: (value: string) => (value.trim()) ? true : 'This field is required'
                                        })}
                                        name='app_build'
                                        type='number'
                                        step='any'
                                        min='0.1'
                                        onKeyDown={handleKeyDownWithOutSpaceAtBeginning}
                                        className={classNames('form-control', { 'is-invalid': errors.app_build })}
                                        defaultValue={app_build}
                                    />
                                    {errors.app_build && (<div className='invalid-feedback'>{errors.app_build.message}</div>)}
                                </div>
                            </div>
                            <div className='card col-auto ml-3' style={{ width: '25rem' }}>
                                <h5 className='card-header'>Test-Flight</h5>
                                <div className='card-body'>
                                    <label>App Version *</label>
                                    <input
                                        ref={register({
                                            required: 'This field is required',
                                            validate: (value: string) => (value.trim()) ? true : 'This field is required'
                                        })}
                                        name='testflight_app_version'
                                        type='number'
                                        step='0.1'
                                        min='0.1'
                                        onKeyDown={handleKeyDownWithOutSpaceAtBeginning}
                                        className={classNames('form-control', { 'is-invalid': errors.testflight_app_version })}
                                        defaultValue={testflight_app_version}
                                    />
                                    {errors.testflight_app_version && (<div className='invalid-feedback'>{errors.testflight_app_version.message}</div>)}
                                    <label className='mt-3'>App Build *</label>
                                    <input
                                        ref={register({
                                            required: 'This field is required',
                                            validate: (value: string) => (value.trim()) ? true : 'This field is required'
                                        })}
                                        name='testflight_app_build'
                                        type='number'
                                        step='any'
                                        min='0.1'
                                        onKeyDown={handleKeyDownWithOutSpaceAtBeginning}
                                        className={classNames('form-control', { 'is-invalid': errors.testflight_app_build })}
                                        defaultValue={testflight_app_build}
                                    />
                                    {errors.testflight_app_build && (<div className='invalid-feedback'>{errors.testflight_app_build.message}</div>)}
                                </div>
                            </div>
                        </div>
                        <div className='form-group mt-3'>
                            <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                {isLoading ? 'Loading...' : 'Save'}
                            </button>&nbsp;&nbsp;
                            <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </Container>
}

export default observer(AppSetting)