import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory, useParams } from 'react-router-dom'
import InputField from '../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Props, Dictionary } from '../../store/types.d'
import Select from 'react-select'
import { toast } from 'react-toastify'

const AddUpdateAddressPage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors, control, setValue } = useForm()

    const { addressStore, allStatesStore, setTitle } = rootStore
    const { isApiError, isLoading, apiErrorMessage, addressDetails, setAddressDetails, addUpdateSingleAddress, resetAddressDetails } = addressStore
    const { statesWithValueLabel } = allStatesStore
    let isUpdateMode = false

    const history = useHistory()
    const { addressId } = useParams<{ addressId: string}>()
    if (addressId) {
        isUpdateMode = true
    }

    useEffect(() => {
        if (addressId) {
            setAddressDetails(addressId)
            if (addressDetails._id !== '') {
                setValue('state', { value: addressDetails.state_id, label: addressDetails.state })
            }
        } else if(addressId === undefined) {
            resetAddressDetails()
        }
        allStatesStore.getAllStates()
    }, [setAddressDetails, addressId, resetAddressDetails, allStatesStore, addressDetails._id, addressDetails.state_id, addressDetails.state, setValue])

    useEffect(() => {
        if (isUpdateMode) {
            setTitle(`${addressDetails.title} | Manage Office Address`)
        } else {
            setTitle('Office Address | Add')
        }
    }, [isUpdateMode, addressId, setTitle, addressDetails.title])

    const handleClick = () => {
        resetAddressDetails()
        history.push('/addresses/')
    }

    const onSubmit = async (data: Dictionary<string>) => {
        const requestData = {
            _id: isUpdateMode ? addressId : '',
            title: data.title,
            street: data.street,
            city: data.city,
            county: data.county,
            state: data.state.label,
            state_id: data.state.value,
            zip_code: data.zip_code,
            phone_number: data.phone_number
        }

        const response = await addUpdateSingleAddress(requestData)
        if (response.success === 1) {
            console.log('in id', response.success)
            history.push('/addresses/')
            setTimeout(() => {
                toast.success(response.message)
            }, 500)
        }
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Office Address', to: '/addresses/' },
            { name: (isUpdateMode) ? 'Update' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {isUpdateMode ? 'Update' : 'Add'} Office Address
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        <form onSubmit={handleSubmit(onSubmit)} >
                            <fieldset>

                                <InputField
                                    label='Title *'
                                    fieldName='title'
                                    register={register}
                                    errors={errors.title}
                                    placeholder='Enter Title'
                                    defaultValue={addressDetails.title}
                                    required={true}
                                />

                                <InputField
                                    label='Street *'
                                    fieldName='street'
                                    register={register}
                                    errors={errors.street}
                                    placeholder='Enter Street'
                                    defaultValue={addressDetails.street}
                                    required={true}
                                />

                                <InputField
                                    label='City *'
                                    fieldName='city'
                                    register={register}
                                    errors={errors.city}
                                    placeholder='Enter City'
                                    defaultValue={addressDetails.city}
                                    required={true}
                                />

                                <InputField
                                    label='County *'
                                    fieldName='county'
                                    register={register}
                                    errors={errors.county}
                                    placeholder='Enter County'
                                    defaultValue={addressDetails.county}
                                    required={true}
                                />

                                <div className='form-group'>
                                    <label>State *</label>
                                    <Controller
                                        control={control}
                                        name="state"
                                        render={({ onChange, ref, value }) => (
                                            <Select
                                                isSearchable
                                                inputRef={ref}
                                                value={value}
                                                options={statesWithValueLabel}
                                                className={(errors.state) ? 'is-invalid' : ''}
                                                classNamePrefix="select"
                                                menuPortalTarget={document.body}
                                                onChange={val => {
                                                    onChange(val)
                                                }}
                                            />)}
                                        rules={{
                                            validate: (value) => (value === undefined) ? 'Please select State' : true
                                        }}
                                    />
                                    {(errors.state) && (<div className='invalid-feedback'>{errors.state.message}</div>)}
                                </div>
                                <InputField
                                    label='Zip Code *'
                                    fieldName='zip_code'
                                    register={register}
                                    errors={errors.zip_code}
                                    placeholder='Enter Zip Code'
                                    defaultValue={addressDetails.zip_code}
                                    required={true}
                                />

                                <InputField
                                    label='Phone Number *'
                                    fieldName='phone_number'
                                    register={register}
                                    errors={errors.phone_number}
                                    placeholder='Enter Phone Number'
                                    defaultValue={addressDetails.phone_number}
                                    required={true}
                                />

                                <div className='form-group'>
                                    <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                        {isLoading ? 'Loading...' : 'Submit'}
                                    </button>&nbsp;&nbsp;
                                    <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddUpdateAddressPage)