import { observable } from 'mobx'
import RootStore from './Root'

class NavStore {
    public rootStore: RootStore
  @observable public collapse = false
  @observable public searchTerm: string

  constructor(rootStore: RootStore) {
      this.rootStore = rootStore
      this.searchTerm = ''
  }

  setSearchTerm(newSearchTerm: string) {
      this.searchTerm = newSearchTerm
  }
}

export default NavStore 