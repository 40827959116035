import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const addTemplate = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/templates/add`
    return axios.post(url, requestData)
}

export const getTemplates = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/templates/`
    return axios.post(url, requestData)
}

export const getTemplateDetails = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/templates/template`
    return axios.post(url, requestData)
}

export const updateTemplate = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/templates/update`
    return axios.post(url, requestData)
}

export const getAllTemplates = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/templates/name/all/`
    return axios.post(url)
}

export const getFieldsOfTemplate = (templateId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/templates/fields/${templateId}`
    return axios.get(url)
}

export const addFieldsOfTemplate = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/templates/fields/add`
    return axios.post(url, requestData)
}

export const updateFieldsDisplayOrder = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/templates/fields/update`
    return axios.post(url, requestData)
}

export const deleteFieldFromTemplate = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/templates/fields/delete`
    return axios.post(url, requestData)
}

export const addUpdateFooterTextInTemplate = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/templates/template/edit-footer`
    return axios.post(url, requestData)
}

export const updateHTMLDocument = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/document/edit-HTML`
    return axios.post(url, requestData)
}

export const deleteHTMLDocument = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/document/delete-HTML`
    return axios.post(url, requestData)
}

export const getHTMLDocumentDetail = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/document/edit-HTML/get`
    return axios.post(url, requestData)
}