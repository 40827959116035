import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const getErrorLogs = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/error-logs/`
    return axios.post(url, requestData)
}

export const getErrorLogsRequestData = (id: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/error-logs/request-data/${id}`
    return axios.post(url)
}

export const getErrorLogsResponseData = (id: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/error-logs/response-data/${id}`
    return axios.post(url)
}