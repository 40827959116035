import React from 'react'
import { Cell } from '../table/TableCellText.d'
import { Link } from 'react-router-dom'
import uuid from 'react-uuid'

export const TableCellMatterName: React.FC<Cell> = (props) => {
    
    return (
        <>
            <Link key={uuid()} to={`/clients/${props.data.client_id}/matters/${props.data._id}`} >{props.data.name}</Link>
        </>
    )
}

export const TableCellNote: React.FC<Cell> = (props) => {
    const note = props.data.note.split('\n')
    const noteWithNewString: Array<JSX.Element> = []
    
    for (let i = 0; i < note.length; i++) {
        noteWithNewString.push(<div key={uuid()}>
            {note[i] || ''}
        </div>)
    }
    return (
        <>
            {noteWithNewString}
        </>
    )
}

export const TableCellNoteAddedBy: React.FC<Cell> = (props) => {
    const value = props.value || []
    const names: Array<JSX.Element> = []
    for (let i = 0; i < value.length; i++) {
        names.push(<div key={uuid()}>
            {value[i].name || ''}
        </div>)
    }

    return (<>{names}</>)
}